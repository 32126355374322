import React from "react";
import { SainsburysBank } from "@jsluna/images";
import {
  Container,
  PageHeader,
  ProgressIndicator,
  ProgressBar,
  Button
} from "@jsluna/react";
import { ArrowLeft } from "@jsluna/icons";

import "./Header.scss";
import { useLocation, useHistory } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const progress = () => {
    if (pathname === "/login") return 30;
    if (pathname === "/authenticate") return 73;
    return 1;
  };

  return (
    <PageHeader>
      <Container className="sb-auth-container">
        {pathname === "/authenticate" ? (
          <Button
            circle={false}
            disabled={false}
            hard={false}
            element="button"
            variant="text"
            color="dark"
            onClick={history.goBack}
          >
            <ArrowLeft />
            Back
          </Button>
        ) : (
          <div />
        )}
        <SainsburysBank label="Sainsbury's Bank" width="251px" height="33px" />
        <h1 aria-labelledby="SBHeader__header-description">
          <span
            className="ln-u-visually-hidden"
            id="SBHeader__header-description"
          >
            Sainsbury&apos;s Bank: Confirm Identity
          </span>
        </h1>
      </Container>
      <ProgressIndicator loading preventFocus aria-hidden>
        <ProgressBar value={progress()} />
      </ProgressIndicator>
    </PageHeader>
  );
};

export default Header;
