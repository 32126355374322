import React from "react";
import PropTypes from "prop-types";
import "./ClickableLink.scss";

const ClickableLink = props => (
  <a {...props} className={`ClickableLink ${props.className}`}>
    {props.children}
  </a>
);

ClickableLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ClickableLink.defaultProps = {
  className: ""
};

export default ClickableLink;
