import {
  FilledButton,
  ProgressIndicator,
  ProgressMessage,
  ProgressSpinner
} from "@jsluna/react";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "../styles/buttons.scss";

const SubmitButton = ({
  disabled,
  onClick,
  text,
  progressMessage,
  loading,
  submitRef
}) => (
  <div className="sb-btn-wrapper ln-u-text-align-center">
    <ProgressIndicator
      loading={loading}
      indicator={<ProgressSpinner size="small" light />}
      message={<ProgressMessage>{progressMessage}</ProgressMessage>}
    >
      {({ progressProps, indicator, message }) => (
        <FilledButton
          id="submit-button"
          disabled={disabled}
          onClick={onClick}
          {...progressProps}
          className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center ln-c-button--full 
          button-text"
          type="submit"
          innerRef={submitRef}
        >
          {loading ? (
            <Fragment>
              {indicator}
              {message}
            </Fragment>
          ) : (
            text
          )}
        </FilledButton>
      )}
    </ProgressIndicator>
  </div>
);

export default SubmitButton;

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  progressMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  submitRef: PropTypes.func
};

SubmitButton.defaultProps = {
  disabled: false,
  onClick: null,
  text: "Continue",
  progressMessage: "Progressing",
  submitRef: null
};
