export const CANCELLATION_PAGE_DEFAULT_INFO = {
  title:
    "Sorry, we’re unable to complete your request because you’ve cancelled this process.",
  description: "Thank you, your session with Sainsbury's Bank has ended."
};

export const CONFIRMATION_PAGE_DEFAULT_INFO = {
  title: "Access confirmed",
  description: "Thank you, your session with Sainsbury's Bank has ended."
};

export const ERROR_PAGE_DEFAULT_INFO = {
  title: "Sorry, we're unable to complete your request.",
  description: "Your session with Sainsbury's Bank has ended."
};

export const ERROR_PAGE_NO_ACCOUNTS_INFO = {
  title:
    "Sorry, we're unable to complete your request because you don't have an eligible account.",
  description: "Your session with Sainsbury's Bank has ended."
};

export const SUSPENDED_PAGE_DEFAULT_INFO = {
  title:
    "Sorry, we’re unable to complete your request. Your online account has been suspended. To protect your accounts, we automatically suspend your online access when your login details have been entered incorrectly several times. Please go to your online banking to reset your details.",
  description: "Your session with Sainsbury's Bank has ended."
};

export const BLOCKED_PAGE_DEFAULT_INFO = {
  title: "Sorry, there’s been a problem",
  description: "We need to speak to you about your online access."
};

export const FRAUD_BLOCKED_PAGE_DEFAULT_INFO = {
  title: "Sorry, there’s been a problem",
  description:
    "We need to speak to you about your online access. Please contact us quoting reference SB400."
};

export const TIMEOUTS = {
  CONFIRMATION_TIMEOUT: 4000,
  ERROR_TIMEOUT: 10000,
  SUSPENDED_TIMEOUT: 20000,
  CANCELLATION_TIMEOUT: 10000
};

export const BUTTON_VARIANTS = {
  FILLED: "filled",
  OUTLINED: "outlined"
};
