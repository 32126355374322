import React from "react";
import { PropTypes } from "prop-types";
import { FilledButton } from "@jsluna/react";

import InfoCircle from "../../../shared/components/InfoCircle";
import OBModal from "../../ob-design/OBModal";
import FAQButton from "../../ob-design/FAQButton";

const MobileLandlineModal = ({
  isOpen,
  handleClose,
  clickButton,
  title,
  body,
  buttonText
}) => {
  return (
    <OBModal
      a11yDescription={title}
      id="OBnoMobileModal"
      isOpen={isOpen}
      handleClose={() => handleClose()}
    >
      <OBModal.Icon icon={<InfoCircle label="!" />} />
      <OBModal.Title center>{title}</OBModal.Title>
      <OBModal.Body>
        <div className="OBModal__body">{body}</div>
        <FilledButton
          id="mobileHomeButton"
          className="BlockedButtons--button"
          aria-describedby={body}
          onClick={() => clickButton()}
        >
          {buttonText}
        </FilledButton>
        <FAQButton />
      </OBModal.Body>
    </OBModal>
  );
};

MobileLandlineModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  clickButton: PropTypes.func,
  isOpen: PropTypes.bool,
  buttonText: PropTypes.string.isRequired
};

MobileLandlineModal.defaultProps = {
  handleClose: null,
  clickButton: null,
  isOpen: false
};

export default MobileLandlineModal;
