import React, { useRef } from "react";
import { PropTypes } from "prop-types";
import { Modal } from "@jsluna/react";

import useModalFocusHook from "../../shared/utils/customHooks";

import "./OBModal.scss";

const OBModal = ({
  children,
  a11yDescription,
  handleClose,
  id,
  isOpen,
  ...rest
}) => {
  let focusedElBeforeOpen = useRef();
  focusedElBeforeOpen = document.activeElement;

  useModalFocusHook(id, isOpen);

  const handleCloseWrapper = () => {
    focusedElBeforeOpen.focus();
    handleClose();
  };

  return (
    <Modal
      aria-describedby="OBModal__descriptor"
      className="OBModal"
      id={id}
      small
      alert
      handleClose={handleCloseWrapper}
      open={isOpen}
      {...rest}
    >
      <span className="ln-u-visually-hidden" id="OBModal__descriptor">
        {a11yDescription}
      </span>
      {children}
    </Modal>
  );
};

const OBModalIcon = ({ icon }) => {
  return <div className="OBModal__icon-wrapper ln-c-flex">{icon}</div>;
};

OBModalIcon.propTypes = {
  icon: PropTypes.node.isRequired
};

const OBModalTitle = ({ center, children }) => {
  return (
    <div className="OBModal__title--wrapper">
      <h1 className={`OBModal__title--h1 ${center ? "text-center" : ""}`}>
        {children}
      </h1>
    </div>
  );
};

OBModalTitle.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired
};

OBModalTitle.defaultProps = {
  center: false
};

const OBModalBody = ({ children }) => {
  return <div className="OBModal__body">{children}</div>;
};

OBModalBody.propTypes = {
  children: PropTypes.node.isRequired
};

const OBModalFooter = ({ children }) => {
  return <div className="OBModal__footer">{children}</div>;
};

OBModalFooter.propTypes = {
  children: PropTypes.node.isRequired
};

OBModal.propTypes = {
  a11yDescription: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool
};

OBModal.defaultProps = {
  handleClose: null,
  isOpen: false
};

OBModal.Icon = OBModalIcon;
OBModal.Body = OBModalBody;
OBModal.Title = OBModalTitle;
OBModal.Footer = OBModalFooter;

export default OBModal;
