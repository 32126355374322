import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useScript } from "@uidotdev/usehooks";
import {
  Container,
  Page,
  PageBody,
  PageFooter,
  ProgressIndicator,
  ProgressBar
} from "@jsluna/react";
import Footer from "./shared/components/Footer";
import SBHeader from "./sainsburysBank/Header";
import OBHeader from "./openBanking/Header";
import ErrorBoundary from "./openBanking/ErrorBoundary";
import OBValidatePage from "./openBanking/validate/ValidatePage";
import SBValidatePage from "./sainsburysBank/validate/ValidatePage";
import OBAuthenticatePage from "./openBanking/securityCheck/AuthenticatePage";
import SBAuthenticatePage from "./sainsburysBank/securityCheck/AuthenticatePage";
import SBMaxOTPAttemptsPage from "./sainsburysBank/securityCheck/MaxOTPAttemptsPage";
import AccountPage from "./openBanking/accountSelection/AccountPage";
import ConfirmationPage from "./openBanking/confirmation/ConfirmationPage";
import CancellationPage from "./openBanking/cancellation/CancellationPage";
import OBErrorPage from "./openBanking/error/ErrorPage";
import SBErrorPage from "./sainsburysBank/error/ErrorPage";
import withPageTransitionWrapper from "./shared/utils/PageTransitionWrapper";
import { startWebSDK } from "./shared/utils/callsign";

// Context
import { TimeoutProvider } from "./context/TimeoutContext";

export default function App() {
  const isOpenBanking = !process.env.REACT_APP_SB_LOGIN;
  const header = isOpenBanking ? <OBHeader /> : <SBHeader />;
  const [loading, setLoading] = useState(true);

  const { pathname } = useLocation();
  const status = useScript(
    "https://app.s01.callsign.com/in/web-sdk/v1/static/web-sdk.js",
    { removeOnUnmount: true }
  );

  useEffect(() => {
    // only initialise Callsign SDK if on login page
    if (pathname === "/login" && status === "ready") {
      if (document.readyState === "complete") {
        setLoading(false);
        startWebSDK();
        return;
      }
      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          setLoading(false);
          startWebSDK();
        }
      };
    }
    if (pathname !== "/login") {
      setLoading(false);
    }
  }, [pathname, status]);

  const routes = isOpenBanking ? (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route
        path="/login"
        component={withPageTransitionWrapper(
          OBValidatePage,
          "Navigated to login page"
        )}
      />
      <Route
        path="/authenticate"
        component={withPageTransitionWrapper(
          OBAuthenticatePage,
          "Navigated to Security Check Page"
        )}
      />
      <Route
        path="/accounts"
        component={withPageTransitionWrapper(
          AccountPage,
          "Navigated to account summary page"
        )}
      />
      <Route
        path="/confirm"
        component={withPageTransitionWrapper(
          ConfirmationPage,
          "Navigated to confirmation page"
        )}
      />
      <Route path="/cancel" component={CancellationPage} />
      <Route
        path="/error"
        component={withPageTransitionWrapper(
          OBErrorPage,
          "Navigated to error page"
        )}
      />
      <Redirect to="/error" />
    </Switch>
  ) : (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route
        path="/login"
        component={withPageTransitionWrapper(
          SBValidatePage,
          "Navigated to login page"
        )}
      />
      <Route
        path="/authenticate"
        component={withPageTransitionWrapper(
          SBAuthenticatePage,
          "Navigated to Security Check Page"
        )}
      />
      <Route
        path="/max-otp-attempts"
        component={withPageTransitionWrapper(
          SBMaxOTPAttemptsPage,
          "Navigated to Max OTP attempts page"
        )}
      />
      <Route
        path="/cancel"
        component={withPageTransitionWrapper(
          CancellationPage,
          "Navigated to error page"
        )}
      />
      <Route
        path="/error"
        component={withPageTransitionWrapper(
          SBErrorPage,
          "Navigated to error page"
        )}
      />
      <Redirect to="/error" />
    </Switch>
  );

  if (loading)
    return (
      <ProgressIndicator
        page
        style={{ position: "absolute", zIndex: 3 }}
        loading
        preventFocus
      >
        <ProgressBar color="light" />
        Loading...
      </ProgressIndicator>
    );

  return (
    <TimeoutProvider isOpenBanking={isOpenBanking}>
      <Page className="ln-c-card">
        {header}
        <PageBody class="ln-u-text-align-center">
          <Container className="container">
            <ErrorBoundary>{routes}</ErrorBoundary>
          </Container>
        </PageBody>
        <PageFooter>
          <Footer />
        </PageFooter>
      </Page>
    </TimeoutProvider>
  );
}
