import moment from "moment";

// Descriptions taken from
// https://openbanking.atlassian.net/wiki/spaces/DZ/pages/127009546/Account+and+Transaction+API+Specification+-+v2.0.0

export const PERMISSION_DISPLAY_NAMES = {
  ReadAccountsBasic: {
    display: "Basic account information",
    category: "account"
  },
  ReadAccountsDetail: {
    display: "Account identification details",
    category: "account"
  },
  ReadBalances: {
    display: "Account balances",
    category: "account"
  },
  ReadBeneficiariesBasic: {
    display: "Basic beneficiary details",
    category: "account"
  },
  ReadBeneficiariesDetail: {
    display: "Detailed beneficiary details",
    category: "account"
  },
  ReadDirectDebits: {
    display: "Direct debits",
    category: "account"
  },
  ReadStandingOrdersBasic: {
    display: "Basic standing order information",
    category: "account"
  },
  ReadStandingOrdersDetail: {
    display: "Detailed standing order information",
    category: "account"
  },

  ReadTransactionsBasic: {
    display: "Basic transaction information",
    category: "transaction"
  },
  ReadTransactionsDetail: {
    display: "Detailed transaction information",
    category: "transaction"
  },
  ReadTransactionsCredits: {
    display: "Credit transactions",
    category: "transaction"
  },
  ReadTransactionsDebits: {
    display: "Debit transactions",
    category: "transaction"
  },

  ReadStatementsBasic: {
    display: "Basic statement details",
    category: "statement"
  },
  ReadStatementsDetail: {
    display: "Detailed statement details",
    category: "statement"
  },

  ReadProducts: {
    display: "Products",
    category: "account"
  },
  ReadOffers: {
    display: "Offers",
    category: "account"
  },
  ReadParty: {
    display: "Account party information",
    category: "account"
  },
  ReadPartyPSU: {
    display: "Party information",
    category: "account"
  },
  ReadScheduledPaymentsBasic: {
    display: "Basic scheduled payment details",
    category: "account"
  },
  ReadScheduledPaymentsDetail: {
    display: "Detailed scheduled payment details",
    category: "account"
  },
  ReadPAN: {
    display: "Full account number",
    category: "account"
  },
  ReadFundsAvailability: {
    display: "Confirm fund availability",
    category: "confirmation-of-funds"
  }
};

export const DEFAULT_CATEGORY = {
  display: "Miscellaneous",
  name: "misc"
};

export const PERMISSION_CATEGORIES = [
  {
    display: "Account details",
    name: "account"
  },
  {
    display: "Account transactions",
    name: "transaction"
  },
  {
    display: "Account statements",
    name: "statement"
  },
  {
    display: "Confirmation of Funds",
    name: "confirmation-of-funds"
  },
  DEFAULT_CATEGORY
];

export function groupPermissionsByCategory(permissionNames) {
  // Supplement permission with category name
  const permissionInfo = permissionNames.map(p => {
    const displayInfo = PERMISSION_DISPLAY_NAMES[p];
    if (displayInfo) {
      return {
        ...displayInfo,
        name: p
      };
    }
    return {
      name: p,
      display: p,
      category: DEFAULT_CATEGORY.name
    };
  });

  // Group permissions by category
  const categoryPermissions = PERMISSION_CATEGORIES.map(category => {
    return {
      display: category.display,
      name: category.name,
      permissions: permissionInfo
        .filter(p => p.category === category.name)
        .map(p => p.name)
    };
  });

  // Remove empty categories
  return categoryPermissions.filter(c => c.permissions.length > 0);
}

// Check if transaction permissions are present
export function hasTransactionPermissions(permissionNames) {
  return permissionNames.some(p => {
    return PERMISSION_DISPLAY_NAMES[p].category === "transaction";
  });
}

// Check if Confirmation of Funds permissions are present
export function hasConfirmationOfFundsPermissions(permissionNames) {
  return permissionNames.some(p => {
    return PERMISSION_DISPLAY_NAMES[p].category === "confirmation-of-funds";
  });
}

// Return transaction message based on permissions and transaction dates available in JWT
export function getTransactionsDateMessage(
  _hasTransactionPermissions,
  fromDate = null,
  toDate = null
) {
  if (!_hasTransactionPermissions) {
    return null;
  }
  // Start and End specified
  if (fromDate && toDate) {
    return `Incoming and outgoing transactions from ${moment(fromDate).format(
      "Do MMMM YYYY"
    )} to ${moment(toDate).format("Do MMMM YYYY")}`;
  }
  if (fromDate && !toDate) {
    // Start but no End
    return `Incoming and outgoing transactions from ${moment(fromDate).format(
      "Do MMMM YYYY"
    )}`;
  }
  if (!fromDate && toDate) {
    // End but no start
    return `Incoming and outgoing transactions to ${moment(toDate).format(
      "Do MMMM YYYY"
    )}`;
  }
  if (!fromDate && !toDate) {
    // No start or end (but transaction permissions requested)
    return "Incoming and outgoing transactions for the last 6 months.";
  }
  // No transaction permissions requested at all
  return null;
}
