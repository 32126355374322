import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./OBTooltipIndicator.scss";

const getIndicatorForType = type => {
  switch (type) {
    case "info":
      return "i";
    case "question":
      return "?";
    default:
      return "!";
  }
};

const TooltipIcon = ({
  children,
  className,
  onClick,
  tooltipId,
  label,
  type,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classnames("OBTooltipIndicator", className)}
      onClick={onClick}
      aria-label={label}
      aria-controls={tooltipId}
      type="button"
    >
      {children || getIndicatorForType(type)}
    </button>
  );
};

TooltipIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tooltipId: PropTypes.string,
  type: PropTypes.string
};

TooltipIcon.defaultProps = {
  children: undefined,
  className: undefined,
  onClick: e => e.preventDefault(),
  tooltipId: undefined,
  type: "question",
  label: ""
};

TooltipIcon.displayName = "TooltipIcon";

export default TooltipIcon;
