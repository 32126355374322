import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilledLinkButton from "./FilledLinkButton";
import { EXTERNAL_RESOURCE_URLS } from "../../shared/constants";

const BASE_ARIA_LABEL = "See our contact details. (Opens in new window)";
const BASE_BUTTON_TEXT = "See our contact details";
const BASE_CLASS_NAME = "OBContactUsButton";

const ContactUsButton = ({ ariaLabel, className, id }) => {
  return (
    <FilledLinkButton
      id={id}
      className={classnames(BASE_CLASS_NAME, className)}
      ariaLabel={ariaLabel}
      href={EXTERNAL_RESOURCE_URLS.CONTACT_US}
      isExternal
    >
      {BASE_BUTTON_TEXT}
    </FilledLinkButton>
  );
};

ContactUsButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

ContactUsButton.defaultProps = {
  ariaLabel: BASE_ARIA_LABEL,
  className: BASE_CLASS_NAME,
  id: undefined
};

export default ContactUsButton;
