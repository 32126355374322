import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormGroup } from "@jsluna/react";
import { v4 as uuidv4 } from "uuid";
import ToolTip from "../../sb-design/tooltip/Tooltip";

import "./RememberMe.scss";

const RememberMe = ({ onChange, checked }) => {
  const id = `tooltip-${uuidv4()}`;

  return (
    <FormGroup name="remember-me" id="sb-remember-me">
      <Checkbox
        name="remember-me-checkbox"
        label="Remember me"
        inline
        checked={checked}
        onChange={onChange}
        aria-describedby={id}
      />
      <ToolTip
        message="We don't recommend using this feature on a shared computer."
        id={id}
        inline
      />
    </FormGroup>
  );
};

RememberMe.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default RememberMe;
