import React from "react";
import { ProgressSpinner } from "@jsluna/react";
import PropTypes from "prop-types";
import "./FinalPage.scss";
import { PAGE_CONSTS } from "../shared/constants";

export const FinalPage = ({
  title,
  description,
  loading,
  children,
  isError = false
}) => {
  localStorage.removeItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);

  return (
    <div className="FinalPage ln-u-text-align-center confirmation">
      {loading && <ProgressSpinner size="large" />}
      <h2
        aria-atomic
        role="status"
        aria-live="polite"
        className={`FinalPage__header ${
          isError ? "FinalPage__header--error" : ""
        }`}
      >
        {title}
      </h2>
      <p aria-atomic role="status" aria-live="polite">
        {description}
      </p>
      {children}
    </div>
  );
};

FinalPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  isError: PropTypes.bool
};

FinalPage.defaultProps = {
  children: null,
  isError: false
};
