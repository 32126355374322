import React, { Component } from "react";
import PropTypes from "prop-types";
import { PasswordField, ProgressSpinner, TextInputField } from "@jsluna/react";

import SubmitButton from "../SubmitButton";
import OBForm from "../ob-design/OBForm";
import Tooltip from "../ob-design/Tooltip";
import TooltipMessage from "../ob-design/TooltipMessage";
import TooltipIcon from "../ob-design/TooltipIcon";
import { REQUESTED_PIN_DIGITS_SCHEMA } from "./PinState";
import { getOrdinalNumber } from "../DropDownSelector";
import { CancelButton } from "../CancelButton";
import ContactUsButton from "../ob-design/ContactUsButton";
import InputLabel from "../ob-design/InputLabel";
import TextInput from "../ob-design/TextInput";
import OBModal from "../ob-design/OBModal";
import InlineError from "../ob-design/InlineError";
import InfoCircle from "../../shared/components/InfoCircle";

import { OTP_REGEX, OTP_MAX_LENGTH } from "../../services/validationHelpers";
import { PAGE_CONSTS, FORM_VALIDATION_MESSAGES } from "../../shared/constants";
import { errors as errorConsts } from "../../services/constants";

import "./SecurityCheck.scss";

export class SecurityCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOTPRequestModal: false,
      pin: [null, null, null],
      errors: {},
      errorMessage: null,
      formLoading: false,
      password: "",
      otp: "",
      showMaxAttemptsOTP: false,
      otpRequestCount: 0,
      showOTPSentMessage: false
    };
    this.submitRef = React.createRef();
    this.onRequestOTPHandler = this.onRequestOTPHandler.bind(this);
  }

  pinValid = pin => pin.every(val => val != null && val !== "");

  pinDigitBlur = async index => {
    await this.setState(state => {
      const newPin = [...state.pin];
      const pinErrors = state.errors.pin || [null, null, null];
      if (!newPin[index]) {
        pinErrors[index] = "Empty";
      }
      return {
        errors: {
          ...state.errors,
          pin: pinErrors
        },
        pin: newPin
      };
    });
  };

  pinDigitStateHandler = async (value, index, error = null) => {
    await this.setState(state => {
      const newPin = [...state.pin];
      const pinErrors = state.errors.pin || [null, null, null];
      pinErrors[index] = error;
      if (value || value === "") {
        newPin[index] = value;
      }
      return {
        errors: {
          ...state.errors,
          pin: pinErrors
        },
        pin: newPin
      };
    });
  };

  pinDigitChanged = async (value, index) => {
    let newValue = value;

    // Check if for entered value or empty string
    // Can be empty string when user deletes current valuye
    if (newValue || newValue === "") {
      newValue = newValue.charAt(0);
      // Invalidate form field when entered value is not a number
      if (isNaN(parseInt(newValue, 10))) {
        const newPin = [...this.state.pin];

        await this.pinDigitStateHandler(
          newValue === "" ? "" : newPin[index],
          index,
          "Please enter a numeric value"
        );
      } else {
        await this.pinDigitStateHandler(newValue, index);
        // Jump to next input
        if (
          index < this.state.pin.length - 1 &&
          document.getElementById(`pin-digit-${index}`) &&
          newValue
        ) {
          document.getElementById(`pin-digit-${index}`).blur();
          document.getElementById(`pin-digit-${index + 1}`).focus();
        }
      }
    }
  };

  onOTPBlur = ({ target }) => {
    const isOTPValid = new RegExp(`^${OTP_REGEX}{${OTP_MAX_LENGTH}}$`).test(
      target.value
    );
    let otpError = null;
    if (!target.value) {
      otpError = FORM_VALIDATION_MESSAGES.OTP_FIELD_EMPTY;
    } else if (!isOTPValid) {
      otpError = FORM_VALIDATION_MESSAGES.OTP_INCORRECT_LENGTH;
    }

    this.setState(state => {
      const newErrors = { ...state.errors };
      newErrors.otp = otpError;

      return { errors: newErrors };
    });
  };

  onOTPChange = ({ target }) => {
    if (!target.value) {
      this.setState(() => ({ otp: target.value }));
      return;
    }
    const isInputValid = new RegExp(
      `^${OTP_REGEX}{${target.value.length}}$`
    ).test(target.value);
    if (!isInputValid) return;
    this.setState(() => ({ otp: target.value }));
  };

  onPasswordBlur = ({ target }) => {
    this.setState(state => {
      const newErrors = { ...state.errors };

      newErrors.password = this.validatePasswordAndGetError(target.value);

      return { errors: newErrors };
    });
  };

  onPasswordChange = ({ target }) => {
    this.setState(state => {
      const newErrors = {
        ...state.errors,
        password: this.validatePasswordAndGetError(target.value)
      };

      return {
        password: target.value,
        errors: newErrors
      };
    });
  };

  otpValid = otp => {
    if (otp && otp.toString().length < 6) return false;

    return !!otp;
  };

  otpTooManyAttempts = () => {
    this.setState(prevState => {
      return {
        showMaxAttemptsOTP: !prevState.showMaxAttemptsOTP
      };
    });
  };

  submitForm = async event => {
    event.preventDefault();
    const { otp, password, pin } = this.state;

    try {
      this.setState({ formLoading: true });
      const [complete, error] = await this.props.onSubmit(pin, password, otp);
      if (!complete) {
        this.setState({
          formLoading: false,
          errorMessage: error,
          pin: [null, null, null],
          password: "",
          otp: ""
        });
      }
    } catch (e) {
      console.log("Couldn't check pin");
      console.error(e);

      this.setState({
        formLoading: false,
        errorMessage: errorConsts.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE,
        pin: [null, null, null],
        password: "",
        otp: ""
      });
    }
  };

  validateSecurityCheck = (password, pin, otp = null) => {
    const validateMandatoryFields = () =>
      this.validatePassword(password) && this.pinValid(pin);

    return this.props.includeOTPCheck
      ? this.otpValid(otp) && validateMandatoryFields()
      : validateMandatoryFields();
  };

  validatePasswordAndGetError = password => {
    return !this.validatePassword(password)
      ? "Please enter your password."
      : undefined;
  };

  validatePassword = password => {
    return !!password;
  };

  onRequestOTPHandler = e => {
    const { otpRequestCount } = this.state;

    if (e.type === "click" || e.keyCode === 13) {
      e.preventDefault();
      otpRequestCount >= PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS
        ? this.setState({
            showMaxAttemptsOTP: true,
            showOTPSentMessage: false
          })
        : this.requestOTP();
    }
  };

  requestOTP = async () => {
    const { otpRequestCount } = this.state;

    if (otpRequestCount === PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS) {
      this.setState({
        showMaxAttemptsOTP: true
      });
    } else {
      try {
        let requestCount = otpRequestCount;
        this.setState({
          showOTPSentMessage: false,
          showOTPRequestModal: true,
          otpRequestCount:
            requestCount >= PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS
              ? requestCount
              : (requestCount += 1)
        });
        const [complete, error] = await this.props.requestOTP();
        if (complete) {
          this.setState({
            showOTPRequestModal: false,
            pin: [null, null, null],
            password: "",
            otp: "",
            errors: {},
            errorMessage: error,
            showOTPSentMessage: true
          });
        }
      } catch (e) {
        console.log("Couldn't request OTP");
        console.error(e);

        this.setState({
          showOTPRequestModal: false,
          errorMessage: e.message,
          pin: [null, null, null],
          password: "",
          otp: ""
        });
      }
    }
  };

  render() {
    const {
      digitsRequired,
      includeOTPCheck,
      phoneDigits,
      onCancel
    } = this.props;
    const {
      errorMessage,
      errors,
      pin,
      formLoading,
      otp,
      otpRequestCount,
      password,
      showMaxAttemptsOTP,
      showOTPRequestModal,
      showOTPSentMessage
    } = this.state;

    const digitNames = digitsRequired.map(getOrdinalNumber);

    const remainingOtpWarning = () => {
      const remainingOtps =
        PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS - otpRequestCount;

      if (remainingOtps === 1 || remainingOtps === 2) {
        const otpMessage = `We can only send you ${remainingOtps} more code${
          remainingOtps > 1 ? "s" : ""
        }.`;

        return (
          <InlineError
            id="request-otp-message"
            message={otpMessage}
            dataTestId="request-otp-message"
          />
        );
      }

      return null;
    };

    const pinInputFields = digitsRequired.map((digit, index) => {
      const id = `pin-digit-${index}`;
      const accessibleLabel = `Please enter the ${
        digitNames[index]
      } digit of your online PIN - ${index + 1} of ${digitsRequired.length}`;
      return (
        <div key={id}>
          <InputLabel
            id={`OBPinEntry__pin-digit-label-${id}`}
            htmlFor={id}
            aria-label={accessibleLabel}
            aria-atomic="true"
            className="OBPinEntry__pin-digit-label"
          >
            {digitNames[index]}
            <span className="ln-u-visually-hidden">
              {" "}
              digit of your online PIN
            </span>
          </InputLabel>
          <TextInput
            aria-describedby={`OBPinEntry__pin-digit-label-${id}`}
            aria-label={accessibleLabel}
            className="OBPinEntry__pin-digit-input"
            onBlur={e => this.pinDigitBlur(index)}
            onChange={e => this.pinDigitChanged(e.target.value, index)}
            id={id}
            name={id}
            data-testid={id}
            value={pin[index] || ""}
            required
            type="pin"
            hideErrorMessage
            error={errors.pin && errors.pin[index]}
          />
        </div>
      );
    });

    const submitFormEnabled =
      this.validateSecurityCheck(password, pin, otp) && !formLoading;
    const submitButton = (
      <SubmitButton
        submitRef={el => {
          this.submitRef = el;
        }}
        onClick={this.submitForm}
        disabled={!submitFormEnabled}
        progressMessage="Verifying..."
        loading={formLoading}
        text="Continue"
      />
    );

    return (
      <div className="OBSecurityCheck">
        <div className="formHeading">
          <h2>Security Check</h2>
        </div>
        <OBModal
          a11yDescription="Sending another code. This will take a few moments."
          id="OBSecurityCheck__otp-request-modal"
          isOpen={showOTPRequestModal}
          handleClose={() => this.setState({ showOTPRequestModal: false })}
        >
          <OBModal.Title center>Sending another code...</OBModal.Title>
          <OBModal.Body>
            <div className="OBModal__body">
              <p>This will take a few moments</p>
              <ProgressSpinner />
            </div>
          </OBModal.Body>
        </OBModal>
        <OBModal
          a11yDescription="Sorry, we can't send you any more codes just now"
          id="OBSecurityCheck__otp-max-attempts-modal"
          isOpen={showMaxAttemptsOTP}
          handleClose={() => this.setState({ showMaxAttemptsOTP: false })}
        >
          <OBModal.Icon icon={<InfoCircle label="!" />} />
          <OBModal.Title>
            Sorry, we can&#39;t send you any more codes just now
          </OBModal.Title>
          <OBModal.Body>
            <div className="OBModal__body--list">
              <p>Please try again later and remember to:</p>
              <ul>
                <li>
                  Wait a minute to allow enough time for the code to get sent to
                  you
                </li>
                <li>
                  Check that the code you are entering is the most recent one
                  that has been sent to you
                </li>
              </ul>
            </div>
          </OBModal.Body>
          <OBModal.Footer>
            <p className="ln-u-text-align-left">
              If you are still not able to progress, please give us a call.
            </p>
            <ContactUsButton />
          </OBModal.Footer>
        </OBModal>
        <OBForm
          className="inline-select-form"
          cancelButton={<CancelButton onClick={() => onCancel()} />}
          submitButton={submitButton}
          error={errorMessage}
          onSubmit={this.submitForm}
        >
          <div>
            {includeOTPCheck && (
              <>
                <h6>One time passcode</h6>
                <InputLabel
                  className="OBSecurityCheck__passcode--label"
                  id="OBSecurityCheck__passcode--descriptor"
                  htmlFor="otp"
                  aria-label={`Please enter the one time passcode we've sent to the number ending in ${phoneDigits.join(
                    " "
                  )}`}
                >
                  Please enter the one time passcode we&#39;ve sent to ********
                  {phoneDigits.join("")}
                </InputLabel>
                <TextInputField
                  aria-invalid={this.otpValid(otp)}
                  className="input-width-small"
                  onChange={this.onOTPChange}
                  onBlur={this.onOTPBlur}
                  aria-labelledby="OBSecurityCheck__passcode--descriptor"
                  id="otp"
                  name="otp"
                  required
                  value={otp || ""}
                  maxLength={6}
                  error={errors.otp}
                  inputMode="decimal"
                />
                <p className="OBSecurityCheck__passcode-help">
                  {showOTPSentMessage &&
                    otpRequestCount > 0 &&
                    !errors.otp &&
                    !errorMessage && (
                      <>
                        <span>Code sent. </span>
                        <span
                          aria-live="assertive"
                          role="alert"
                          className="ln-u-visually-hidden"
                          id="OBSecurityCheck__passcode-help--descriptor"
                          aria-label="Code sent. Not received your code after 1 minute? Please click the 'Request another one-time passcode' button again."
                        />
                      </>
                    )}
                  <span aria-label="Not received your code after 1 minute? Please click the 'Request another one-time passcode' button again.">
                    Not received your code after 1 minute?
                  </span>
                </p>
                <div
                  className="OBSecurityCheck__request-otp"
                  aria-label="Request another one-time passcode"
                  role="button"
                  tabIndex="0"
                  onKeyDown={this.onRequestOTPHandler}
                  onClick={this.onRequestOTPHandler}
                >
                  Send another code
                </div>
                {remainingOtpWarning()}
              </>
            )}
            <PasswordField
              aria-describedby="password"
              aria-label="Please enter your password"
              name="password"
              id="password"
              label="Password"
              hasToggle
              onChange={this.onPasswordChange}
              onBlur={this.onPasswordBlur}
              onKeyPress={this.keyPressed}
              error={errors.password}
              required
              value={password || ""}
            />
            <h6>Online PIN</h6>
            <fieldset>
              <legend>
                {`Please enter the ${digitNames[0]}, ${digitNames[1]} and ${digitNames[2]} digits of your online PIN.`}
              </legend>
              <div className="OBPinEntry__pin-input-wrapper">
                <div className="OBPinEntry__pin-input-fields">
                  {pinInputFields}
                </div>
              </div>
            </fieldset>
            <div className="OBSecurityCheck__forgotten-details">
              <strong className="ln-c-label">Forgotten your details?</strong>
              <Tooltip
                tooltipId="forgottenTooltip"
                label="Find out what to do when you have forgotten your details (opens in a tooltip)"
              >
                <TooltipIcon
                  tooltipId="forgottenTooltip"
                  type="question"
                  className="tooltip-margin-top tooltip-margin-left"
                />
                <TooltipMessage>
                  If you have forgotten any of your details then please go to
                  your online banking and follow the instructions.
                </TooltipMessage>
              </Tooltip>
            </div>
          </div>
        </OBForm>
      </div>
    );
  }
}

// eslint-disable-next-line consistent-return
function requestedPinDigitsValidator(props, propName) {
  try {
    REQUESTED_PIN_DIGITS_SCHEMA.validateSync(props[propName]);
  } catch (e) {
    console.log(e);
    const message = JSON.stringify(e.errors);
    return new Error(message);
  }
}

SecurityCheck.propTypes = {
  includeOTPCheck: PropTypes.bool.isRequired,
  phoneDigits: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  digitsRequired: requestedPinDigitsValidator,
  requestOTP: PropTypes.func
};

SecurityCheck.defaultProps = {
  requestOTP: undefined
};
