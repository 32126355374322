import { date, string, object, array } from "yup";

export const CONSENT_LIST_RESPONSE_SCHEMA = object().shape({
  Permissions: array()
    .of(string())
    .min(1)
    .required(),
  TransactionFromDateTime: date().required(),
  TransactionToDateTime: date().required(),
  Accounts: array().of(
    object({
      AccountId: string().required(),
      ProductAccountId: string().required()
    })
  )
});
