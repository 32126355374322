export const errors = {
  UNEXPECTED_ERROR_MESSAGE: "An unexpected error has occurred",
  FATAL_ERROR: "The page could not be loaded due to an unexpected error",
  GET_ACCOUNTS_FAILED: "Could not retrieve accounts",
  INVALID_CREDENTIALS_MESSAGE:
    "One or more of your login details were incorrect.",
  /**
   * PS-524 DECEMBER ONLY
   * Use INVALID_CREDENTIALS_OR_OTP_FAILURE_MESSAGE in favour of INVALID_CREDENTIALS_MESSAGE
   * https://sb97digital.atlassian.net/browse/PS-524
   * Once IAM returns more granular messages, we can re-use INVALID_CREDENTIALS_MESSAGE
   * and any new message constants that come from IAM
   */
  INVALID_CREDENTIALS_OR_OTP_FAILURE_MESSAGE:
    "An error occurred when checking your details. Please try again.",
  INVALID_PIN_MESSAGE:
    "The details you entered are incorrect. Please try again.",
  ACCOUNT_ERROR_MESSAGE: "Sorry, we're unable to complete your request.",
  CREDENTIALS_UNEXPECTED_ERROR_MESSAGE:
    "An unexpected error occurred when checking your details. Please try again. If the problem continues, call us.",
  SESSION_TIMEOUT_MESSAGE: "Your session has timed out",
  PERMISSIONS_UNEXPECTED_ERROR_MESSAGE: "Could not retrieve permissions",
  PERMISSIONS_DISABLE_CONFIRM_ACCESS: "Cannot continue due an unexpected error",
  CONFIRM_ACCESS_FAILED:
    "An unexpected error occurred. Please try again. If the problem continues, please cancel the process and try again later.",
  OB_SUSPENDED_MESSAGE:
    "Sorry, we’re unable to complete your request. Your online account has been suspended. To protect your accounts, we automatically suspend your online access when your login details have been entered incorrectly several times. Please go to your online banking to reset your details",
  NO_BLACKBOX_MESSAGE:
    "An error occurred when submitting your details. Please try again."
};

export const ACCOUNT_STATUS_ENUM = Object.freeze({
  BLOCKED: 0,
  SUSPENDED: 1,
  FRAUD_BLOCKED: 3
});

export const SB_CUSTOMER_ACCOUNT_STATUSES = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  BLOCKED: "BLOCKED",
  DELETED: "DELETED",
  NEW: "NEW",
  FRAUD_BLOCKED: "FRAUD_BLOCKED"
};

export const INETUSERSTATUSES = [1, 2, 3];

export const MAX_FAILURE_COUNT = 3;

// Open API Standard headers
// https://sb97digital.atlassian.net/wiki/spaces/DD/pages/776110139/DP-INT-SRVC-SBB+Appendix+-+OpenAPI+Swagger+standards
export const OPENAPI_STANDARD_HEADERS = {
  X_SB_CHANNEL_ID_HEADER_VALUE_WEB: "WEB",
  X_SB_CHANNEL_ID_HEADER_VALUE_PSD2: "PSD2",
  X_SB_CLIENT_ID_HEADER_VALUE: "sb",
  X_SB_CHANNEL_VERSION_HEADER_VALUE: "V2"
};

export const ACTIVITY_CODES = {
  TPP_ONLINE_LOGIN: "OBCustAuth",
  WEB_AUTH_ONLINE_LOGIN: "SBWebCustAuth"
};

// Ref: https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
export const OIDC_PROMPT = {
  CONSENT: "consent",
  EMPTY: ""
};
