import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label } from "@jsluna/react";
import ToolTip from "../../sb-design/tooltip/Tooltip";
import MultiDigitInput, {
  multiDigitInputPartType
} from "../../sb-design/multi-digit-input/MultiDigitInput";
import LinkList, { linkListType } from "../../sb-design/link-list/LinkList";

import "./PhoneNumber.scss";

const PhoneNumber = ({
  digits,
  links,
  onChange,
  onBlur,
  onEnterKey,
  type,
  error
}) => {
  const tooltip =
    "From time to time we may send a one time passcode to your phone to make sure that it’s you. If you receive a onetime passcode you will need to enter it on the screen to continue.";

  return (
    <>
      <FormGroup
        name="phone-number"
        className="sb-phone-number"
        aria-label={`${type} number`}
      >
        {inputProps => (
          <>
            <ToolTip message={tooltip}>
              <Label htmlFor={inputProps.name}>{type} number</Label>
            </ToolTip>
            <div>
              Please enter the last 3 digits of your {type.toLowerCase()} number
              as we may need to send you a one time passcode.
            </div>

            <MultiDigitInput
              parts={digits}
              onChange={onChange}
              onBlur={onBlur}
              onEnterKey={onEnterKey}
            />
            {error && (
              <div
                id="phone-numberValidation"
                className="ln-c-field-info ln-c-field-info--error"
                role="alert"
                aria-live="assertive"
              >
                {error}
              </div>
            )}
            <LinkList links={links} />
          </>
        )}
      </FormGroup>
    </>
  );
};

PhoneNumber.propTypes = {
  digits: multiDigitInputPartType.isRequired,
  links: linkListType.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnterKey: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(["Home", "Mobile"]).isRequired,
  error: PropTypes.string
};

PhoneNumber.defaultProps = {
  error: null,
  onEnterKey: () => {},
  onBlur: () => {}
};

export default PhoneNumber;
