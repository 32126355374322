import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";

import Timeout from "../shared/components/Timeout";

import { redirectToLoginPage } from "../locationManagement";

import { PAGE_CONSTS } from "../shared/constants";

const FIVE_MINUTES_TIMEOUT_IN_SECONDS = 300;
const TimeoutContext = React.createContext();

export function TimeoutProvider({ children, timeoutError, isOpenBanking }) {
  const [redirectError, setRedirectErrorState] = useState(timeoutError);

  const setErrorState = useCallback(
    (history, gotoParam) => {
      setRedirectErrorState(true);
      redirectToLoginPage(history, gotoParam);
    },
    [setRedirectErrorState]
  );

  const context = useMemo(
    () => ({ redirectError, setErrorState, isOpenBanking }),
    [redirectError, setErrorState, isOpenBanking]
  );

  return (
    <TimeoutContext.Provider value={context}>
      {children}
    </TimeoutContext.Provider>
  );
}

TimeoutProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  timeoutError: PropTypes.bool,
  isOpenBanking: PropTypes.bool
};

TimeoutProvider.defaultProps = {
  timeoutError: false,
  isOpenBanking: false
};

const timeoutHandler = (handleTimeout, context, history, gotoParam) => {
  localStorage.removeItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);

  // we need to check to see if the handleTimeout function has been passed in from the consumer. Default timeouts will be handled in the Timeout Component.
  if (handleTimeout) {
    return handleTimeout();
  }

  // This handles the web authentication journey.
  if (!handleTimeout && !context.isOpenBanking) {
    return context.setErrorState(history, gotoParam);
  }

  return null;
};

export function TimeoutConsumer({
  children,
  history,
  gotoParam,
  hideTimeout,
  tokenExpiryTimestamp,
  handleTimeout
}) {
  return (
    <TimeoutContext.Consumer>
      {context => {
        return (
          <>
            {!hideTimeout ? (
              <Timeout
                handleTimeout={() =>
                  timeoutHandler(handleTimeout, context, history, gotoParam)
                }
                tokenExpiryTimestamp={tokenExpiryTimestamp}
                isOpenBanking={context.isOpenBanking}
              />
            ) : null}

            {typeof children === "function" ? children(context) : children}
          </>
        );
      }}
    </TimeoutContext.Consumer>
  );
}

TimeoutConsumer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }),
  gotoParam: PropTypes.string,
  hideTimeout: PropTypes.bool,
  tokenExpiryTimestamp: PropTypes.number,
  handleTimeout: PropTypes.func
};

TimeoutConsumer.defaultProps = {
  history: {},
  gotoParam: null,
  hideTimeout: false,
  tokenExpiryTimestamp: Date.now() + FIVE_MINUTES_TIMEOUT_IN_SECONDS,
  handleTimeout: undefined
};
