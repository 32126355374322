import queryString from "query-string";
import { parseGotoJwtString } from "./gotoJWT";
import { OIDC_PROMPT } from "../constants";

/**
 * Function to update the prompt query string
 * Prompt is an optional OIDC param that forces/prompts a user to action
 * before returning to client
 ******* OB SPA ******
 * Uses `prompt=consent` to prompt user to consent
 ****** Web Auth SPA ******
 * Uses `prompt=` - IAM interally sets an empty prompt param to 'login'
 * E.g. Step 9b on Ref 1
 * Ref 1: https://sb97digital.atlassian.net/wiki/spaces/IAM/pages/704119347/SB+Web+Authentication+SPA+Integration+-+Online+Banking+Login+flow
 * Ref 2: https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
 * @param {String}    url    IAM redirect URI extracted from goto param
 */
export const setPromptStatus = url => {
  const parsedUrl = queryString.parseUrl(url);
  const prompt = process.env.REACT_APP_SB_LOGIN
    ? OIDC_PROMPT.EMPTY
    : OIDC_PROMPT.CONSENT;

  const newQueryString = queryString.stringify({
    ...parsedUrl.query,
    prompt
  });

  return `${parsedUrl.url}?${newQueryString}`;
};

export const extractGoto = search => {
  const { goto } = queryString.parse(search);
  if (!goto) {
    throw new Error("no goto query string parameter found in the url");
  }
  const gotoParam = goto;
  // Can't return goto, so set to gotoParam variable
  return gotoParam;
};

export const getRedirectURIs = search => {
  const goto = extractGoto(search);

  const requestParam = queryString.parseUrl(goto).query.request;
  if (!requestParam) {
    throw new Error("no request query string parameter found on goto URL");
  }

  const parsedRequestToken = parseGotoJwtString(requestParam);

  const tppUrl = queryString.stringify({
    error_description: "Resource owner did not authorize the request",
    state: parsedRequestToken.state,
    error: "access_denied"
  });

  const tpp = `${parsedRequestToken.redirect_uri}#${tppUrl}`;
  const tokenExpiryTimestamp = parsedRequestToken.exp;

  return {
    iam: goto,
    tpp,
    tokenExpiryTimestamp
  };
};

export const getRedirectUriOnAccountsPage = consentApprovalRedirectUri => {
  // Retrieve redirect_uri JWT from original token
  const redirectUri = queryString.parseUrl(consentApprovalRedirectUri).query
    .redirect_uri;
  // Parse JWT and retrieve redirect_uri from this
  if (!redirectUri) {
    throw new Error("No redirect_uri found in original JWT string");
  }
  return redirectUri;
};
