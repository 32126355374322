import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
  componentDidCatch() {
    this.props.history.push("/error", {
      accountConfirmed: false
    });
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ErrorBoundary);
