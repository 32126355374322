import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import OutlinedLinkButton from "./OutlinedLinkButton";
import { EXTERNAL_RESOURCE_URLS } from "../../shared/constants";

const BASE_ARIA_LABEL = "See our FAQs. (Opens in new window)";
const BASE_BUTTON_TEXT = "See our FAQs";
const BASE_CLASS_NAME = "OBFAQButton";

const FAQButton = ({ ariaLabel, className, id }) => {
  return (
    <OutlinedLinkButton
      id={id}
      className={classnames(BASE_CLASS_NAME, className)}
      ariaLabel={ariaLabel}
      href={EXTERNAL_RESOURCE_URLS.FAQS}
      isExternal
    >
      {BASE_BUTTON_TEXT}
    </OutlinedLinkButton>
  );
};

FAQButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

FAQButton.defaultProps = {
  ariaLabel: BASE_ARIA_LABEL,
  className: BASE_CLASS_NAME,
  id: undefined
};

export default FAQButton;
