import React from "react";
import { PropTypes } from "prop-types";

import "./InfoCircle.scss";

const InfoCircle = ({ label }) => {
  const circleClass = "circle info-circle";

  return (
    <div className={circleClass}>
      <div style={{ textAlign: "center" }}>
        <span className="info-label">{label}</span>
      </div>
    </div>
  );
};

InfoCircle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default InfoCircle;
