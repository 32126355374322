import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Renders and immediately submits a hidden form, containing the specified token.
 * This has the effect of redirecting the browser to the specified URL,
 * in the same fashion as a traditional HTML form POST submit.
 *
 * This method is used to complete the consent process.
 * IAM responds to the post with a HTTP 302, which cannot be intercepted via AJAX.
 */
export class CompleteConsentAndRedirect extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    const { delayInMilliseconds } = this.props;
    // Submit form 2 seconds after a user lands on confirmation page
    this.submissionTimeout = setTimeout(() => {
      this.form.current.submit();
    }, delayInMilliseconds);
  }

  componentWillUnmount() {
    this.clearTimeout(this.submissionTimeout);
  }

  render() {
    const { url, token } = this.props;

    return (
      <form ref={this.form} action={url} method="POST">
        <input type="hidden" name="consent_response" value={token} />
      </form>
    );
  }
}

CompleteConsentAndRedirect.propTypes = {
  delayInMilliseconds: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired
};
