import React from "react";
import PropTypes from "prop-types";

import "./InputLabel.scss";

const InputLabel = ({ children, ...rest }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    {...rest}
    className={
      rest.className ? `OBinputLabel ${rest.className}` : "OBinputLabel"
    }
  >
    {children}
  </label>
);

InputLabel.propTypes = {
  children: PropTypes.node.isRequired
};

export default InputLabel;
