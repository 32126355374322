import uuidv4 from "uuid/v4";

import { CONSENT_LIST_RESPONSE_SCHEMA } from "./ConsentAPISchema";
import { convertClientTokenToConsentResponseToken, signToken } from "./Token";
import { getConsentApprovalRedirectUri, getConsentId } from "./TokenSchema";
import { validateResponseStatus } from "../apiUtilities";
import { errors, OPENAPI_STANDARD_HEADERS } from "../constants";

const API_URL_PREFIX = process.env.REACT_APP_SB_API_HOST;

// Destructure standard headers for re-use
const {
  X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
  X_SB_CLIENT_ID_HEADER_VALUE
} = OPENAPI_STANDARD_HEADERS;

const getIntentFetchOptions = () => ({
  method: "GET",
  credentials: "include",
  headers: {
    "x-sb-channel-id": X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
    "x-sb-client-id": X_SB_CLIENT_ID_HEADER_VALUE,
    "x-fapi-interaction-id": uuidv4()
  }
});

/**
 * Calls IAM to get information about the TPP's intent
 * (a list of permissions the TPP requires)
 *
 * @param token the JWT
 */
export async function getIntent(token) {
  const consentId = getConsentId(token);

  let response;

  try {
    response = await fetch(
      `${API_URL_PREFIX}/account-access-permissions/${consentId}`,
      getIntentFetchOptions()
    );
    // Validate response status - proceed if 2xx
    validateResponseStatus(response);
  } catch (e) {
    return {
      success: false,
      error: errors.PERMISSIONS_UNEXPECTED_ERROR_MESSAGE
    };
  }

  const json = await response.json();

  try {
    // Validate response as per schema
    CONSENT_LIST_RESPONSE_SCHEMA.validate(json);
  } catch (e) {
    return {
      success: false,
      error: errors.PERMISSIONS_UNEXPECTED_ERROR_MESSAGE
    };
  }

  return {
    success: true,
    ...json
  };
}

/**
 * Encapsulates re-usable logic for signing token and generating
 * redirect URI
 */
async function signTokenAndGenerateConsentRedirectUri(
  originalToken,
  consentResponseToken
) {
  // IAM requires a signed token
  // The signed token is serialized to a string
  const signedToken = await signToken(consentResponseToken);

  // Redirect URL comes from the token
  const redirectUrl = getConsentApprovalRedirectUri(originalToken);

  // We can't send this data to IAM directly because it requires a POST form submission
  // So pass back the relevant info instead
  return {
    token: signedToken,
    url: redirectUrl
  };
}

/**
 * Generates the consent token with decision = false when a user has cancelled /
 * rejected to give consent
 * Generates consentRedirectURI
 * @param {token} the JWT
 */
export async function getConsentResponseInfoForDeclinedConsent(token) {
  try {
    // Create the consent response token based on the existing JWT
    // Pass in decision as false, as user does not want to consent accounts
    const consentResponseToken = convertClientTokenToConsentResponseToken(
      token,
      false
    );

    return signTokenAndGenerateConsentRedirectUri(token, consentResponseToken);
  } catch (e) {
    console.log("Could not sign token and cancel consent journey");
    console.log(e);
    throw e;
  }
}

/**
 * Generates the consent token with decision = true when a user already has accounts consented /
 * Generates consentRedirectURI
 * @param {token} the JWT
 */
export async function getConsentResponseInfoForConsented(token) {
  try {
    // Create the consent response token based on the existing JWT
    // Pass in decision as true, as user is already consented
    const consentResponseToken = convertClientTokenToConsentResponseToken(
      token,
      true
    );

    return signTokenAndGenerateConsentRedirectUri(token, consentResponseToken);
  } catch (e) {
    console.log("Could not sign token and consent the journey");
    console.log(e);
    throw e;
  }
}

/**
 * Binds the specified account to the consent request
 * and gets the information required for the consent response form POST.
 *
 * @param token the JWT
 * @param selectedAccountIds the Ids for all accounts to consent
 */
export async function bindAccountsAndGetConsentResponseInfo(
  token,
  selectedAccountIds
) {
  const consentId = getConsentId(token);

  try {
    // Confirm which account the user is consenting to access
    await bindAccounts(selectedAccountIds, consentId);
  } catch (e) {
    console.log("Could not bind the account");
    throw e;
  }

  try {
    // Create the consent response token based on the existing JWT
    // Manipulated as IAM expects to reflect confirmation of consent
    const consentResponseToken = convertClientTokenToConsentResponseToken(
      token,
      true
    );

    return signTokenAndGenerateConsentRedirectUri(token, consentResponseToken);
  } catch (e) {
    console.log("Could not sign token and get consent response info");
    console.log(e);
    throw e;
  }
}

const getConsentBindingFetchOptions = requestBody => ({
  method: "PUT",
  mode: "cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Accept-API-Version": "resource=2.0, protocol=1.0",
    "Content-Type": "application/json",
    "x-sb-channel-id": X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
    "x-sb-client-id": X_SB_CLIENT_ID_HEADER_VALUE,
    "x-fapi-interaction-id": uuidv4()
  },
  body: JSON.stringify(requestBody)
});

/**
 * Calls IAM to binds the consent request to the specified account ID.
 * Must be called after login but before authorising consent.
 *
 * Note - authentication for this request is implicit via a session cookie
 *
 * @param selectedAccountIds the accounts to consent access to
 * @param consentId the consentId from the JWT
 * @returns {Promise<void>} a promise to bind consent
 */
async function bindAccounts(selectedAccountIds, consentId) {
  const requestBody = {
    Accounts: prepareAccountListForConsent(selectedAccountIds)
  };

  const response = await fetch(
    `${API_URL_PREFIX}/account-access-accounts/${consentId}`,
    getConsentBindingFetchOptions(requestBody)
  );

  if (response.status < 200 || response.status >= 300) {
    throw Error("Could not bind account");
  }
}

export function prepareAccountListForConsent(selectedAccountIds) {
  if (!selectedAccountIds || selectedAccountIds.length === 0) {
    throw new Error("No account(s) selected");
  }
  return selectedAccountIds.map(accountId => {
    return {
      ProductAccountId: accountId
    };
  });
}
