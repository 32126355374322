import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./PermissionsList.scss";
import {
  getTransactionsDateMessage,
  hasTransactionPermissions,
  PERMISSION_CATEGORIES,
  PERMISSION_DISPLAY_NAMES
} from "../../services/Consent/permissions";

// Check if transaction permissions are present
const isTransactionListGroup = title => {
  return !!PERMISSION_CATEGORIES.find(
    category => category.name === "transaction" && category.display === title
  );
};

const PermissionsList = props => {
  let includesTransactionPermissions;
  let dateRangeNote;
  const { fromDate, toDate, permissions, title } = props;

  if (permissions.length === 0) {
    return null;
  }

  const list = permissions.map(permission => {
    const display = PERMISSION_DISPLAY_NAMES[permission]
      ? PERMISSION_DISPLAY_NAMES[permission].display
      : permission;
    return (
      <li className="permission-item" key={permission}>
        {display}
      </li>
    );
  });

  // Add transaction range statement if account transaction permissions are present
  if (isTransactionListGroup(title)) {
    includesTransactionPermissions = hasTransactionPermissions(permissions);
    if (includesTransactionPermissions) {
      list.push(
        <li className="permission-item" key="transaction-range">
          {getTransactionsDateMessage(
            includesTransactionPermissions,
            fromDate,
            toDate
          )}
        </li>
      );

      dateRangeNote = (
        <div className="date-range-note">
          <p>
            Note - Sainsbury&apos;s Bank does not provide transaction data older
            than 6 months.
          </p>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <h3>{title}</h3>
      <ul>{list}</ul>
      {includesTransactionPermissions && dateRangeNote}
    </Fragment>
  );
};

PermissionsList.propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};

PermissionsList.defaultProps = {
  fromDate: null,
  toDate: null
};

export default PermissionsList;
