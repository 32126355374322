import jwt from "jsonwebtoken";
import { GOTO_JWT_SCHEMA } from "./gotoJWTSchema";

export function parseGotoJwtString(jwtString) {
  const token = jwt.decode(jwtString);

  if (token === null) {
    throw Error("Could not decode token");
  }

  GOTO_JWT_SCHEMA.validateSync(token);

  return token;
}

export function decodeAuthIDFromJWT(responseTemplate) {
  if (!responseTemplate)
    throw new Error("No response template found in iam response");

  const token = jwt.decode(responseTemplate.authId);

  const tokenExpiryTimestamp = token.exp;

  if (!tokenExpiryTimestamp) {
    throw new Error("Expiry is not present in the JWT token");
  }

  return tokenExpiryTimestamp;
}
