import React from "react";
import { withRouter } from "react-router-dom";

import { ValidateUserPage } from "./AuthenticationPage";
import { TimeoutConsumer } from "../../context/TimeoutContext";

// Services
import {
  getIamRedirectUrl,
  handleStatusRedirection,
  redirectToSBAuthenticatePage,
  redirectToSBSuspendedPage,
  redirectToCancelPage,
  validateGotoParam
} from "../../locationManagement";
import { extractGoto } from "../../services/Authentication/iamUtils";
import { checkUsernameAndDigits } from "../../services/Authentication/AuthenticationAPI";
import { ACCOUNT_STATUS_ENUM } from "../../services/constants";
import { BLOCKED_PAGE_DEFAULT_INFO } from "../constants";
import { getBlackbox, removeBlackBox } from "../../shared/utils/callsign";

const ValidatePage = withRouter(({ history, location }) => {
  const iamRedirectUri = getIamRedirectUrl(location);
  const gotoParam = extractGoto(location.search);
  validateGotoParam(history, gotoParam);

  const onSubmit = async (username, phoneDigits, isLandline) => {
    const result = await checkUsernameAndDigits(
      username,
      phoneDigits,
      isLandline,
      getBlackbox()
    );
    removeBlackBox();
    let complete = false;

    if (
      result &&
      Object.values(ACCOUNT_STATUS_ENUM).indexOf(result.accountStatus) > -1
    ) {
      handleStatusRedirection(
        result.accountStatus,
        history,
        gotoParam,
        BLOCKED_PAGE_DEFAULT_INFO.title
      );
      complete = true;
    } else if (result && result.valid) {
      const { requestedPinDigits, responseTemplate } = result.data;
      redirectToSBAuthenticatePage(
        history,
        iamRedirectUri,
        phoneDigits,
        requestedPinDigits,
        responseTemplate,
        gotoParam,
        username
      );
      complete = true;
    } else if (result && !result.retry) {
      redirectToSBSuspendedPage(history, gotoParam);
      complete = true;
    }
    return [complete, result.error];
  };

  return (
    <TimeoutConsumer hideTimeout>
      {context => (
        <ValidateUserPage
          gotoParam={gotoParam}
          onSubmit={onSubmit}
          onCancel={() => redirectToCancelPage(history, iamRedirectUri)}
          showRedirectError={context.redirectError}
        />
      )}
    </TimeoutConsumer>
  );
});
export default ValidatePage;
