import React, { Component } from "react";
import PropTypes from "prop-types";

export class DropDownSelector extends Component {
  createOptionMap = options => {
    return options.map(val => {
      return { label: val, value: val };
    });
  };

  onChange = e => {
    let { value } = e.target;
    if (value === "") {
      value = null;
    }

    const { onChange, index } = this.props;
    onChange(value, index);
  };

  renderOptions = () => {
    const defaultOption = { value: "", label: "" };
    const optionMap = this.createOptionMap(this.props.options);
    const options = [defaultOption].concat(optionMap);

    return options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ));
  };

  render() {
    const { index, error, label, pinValue, showOrdinal } = this.props;
    const id = `pin-select-${index}`;

    const hasError = error && !pinValue;

    const formGroupClass = hasError
      ? "ln-c-form-group has-error"
      : "ln-c-form-group";

    return (
      <div className={formGroupClass} data-reactroot="">
        <label htmlFor={id} className="ln-c-label">
          {showOrdinal ? getOrdinalNumber(label) : label}
        </label>
        <select
          id={id}
          name={id}
          className="ln-c-select"
          aria-invalid={hasError}
          onChange={this.onChange}
          value={pinValue}
          data-testid={id}
        >
          {this.renderOptions()}
        </select>
      </div>
    );
  }
}

DropDownSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  pinValue: PropTypes.string.isRequired,
  showOrdinal: PropTypes.bool
};

DropDownSelector.defaultProps = {
  error: false,
  showOrdinal: false
};

/**
 * Adds ordinal suffix to the specified number.
 * e.g. "3" becomes "3rd"
 */
export function getOrdinalNumber(digit) {
  let ordinal = digit;

  switch (digit) {
    case "1":
      ordinal += "st";
      break;

    case "2":
      ordinal += "nd";
      break;

    case "3":
      ordinal += "rd";
      break;

    default:
      ordinal += "th";
      break;
  }

  return ordinal;
}
