import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  InputGroup,
  InputControl,
  InputButton,
  TextInput,
  Label,
  Button
} from "@jsluna/react";
import ToolTip from "../../sb-design/tooltip/Tooltip";
import LinkList, { linkListType } from "../../sb-design/link-list/LinkList";

import "./Password.scss";

const Password = ({ value, error, onChange, onBlur, links }) => {
  const [showPassword, setShowPassword] = useState(false);

  const tooltip =
    "This password was created by you as part or the registration process. If you don’t remember what your password is you can follow the forgotten password process to reset it.";

  return (
    <FormGroup
      name="password"
      className={`sb-password ${error && "has-error"}`}
      aria-label="Password"
      required
    >
      {inputProps => (
        <>
          <ToolTip message={tooltip}>
            <Label htmlFor={inputProps.name}>Password</Label>
          </ToolTip>
          <InputGroup>
            <InputControl>
              <TextInput
                {...inputProps}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={showPassword ? "text" : "password"}
              />
            </InputControl>
            <InputButton>
              <Button onClick={() => setShowPassword(!showPassword)}>
                <span aria-label={`${showPassword ? "Hide" : "Show"} password`}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </Button>
            </InputButton>
          </InputGroup>

          {error && (
            <div
              id="phone-numberValidation"
              className="ln-c-field-info ln-c-field-info--error"
              role="alert"
              aria-live="assertive"
            >
              {error}
            </div>
          )}
          <LinkList links={links} />
        </>
      )}
    </FormGroup>
  );
};

Password.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  links: linkListType.isRequired
};

Password.defaultProps = {
  error: null
};

export default Password;
