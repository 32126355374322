import React from "react";
import { PropTypes } from "prop-types";

import ContactUsButton from "../../ob-design/ContactUsButton";
import FAQButton from "../../ob-design/FAQButton";
import InfoCircle from "../../../shared/components/InfoCircle";
import OBModal from "../../ob-design/OBModal";

import "./registerModal.scss";

const RegisterModal = ({ isOpen, handleClose }) => {
  return (
    <OBModal
      a11yDescription="Need to register your phone number with us?"
      id="registerPhoneModal"
      isOpen={isOpen}
      handleClose={() => handleClose()}
    >
      <OBModal.Icon icon={<InfoCircle label="!" />} />
      <OBModal.Title center>
        Need to register your phone number with us?
      </OBModal.Title>
      <OBModal.Body>
        <div className="registerPhoneModal__body-description">
          The easiest way to register or update your mobile or home number is to
          call us.
        </div>
        <ContactUsButton
          id="mobileHomeButton"
          className="registerPhoneModal__button"
        />
        <FAQButton />
      </OBModal.Body>
    </OBModal>
  );
};

RegisterModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool
};

RegisterModal.defaultProps = {
  handleClose: null,
  isOpen: false
};

export default RegisterModal;
