import React from "react";
import { withRouter } from "react-router-dom";

import { AccountSelection } from "./AccountSelection";
import { getAccounts } from "../../services/Accounts/AccountsAPI";
import { TimeoutConsumer } from "../../context/TimeoutContext";
import {
  getConsentResponseInfoForDeclinedConsent,
  getConsentResponseInfoForConsented,
  bindAccountsAndGetConsentResponseInfo,
  getIntent
} from "../../services/Consent/ConsentAPI";
import { getTppName } from "../../services/Consent/TokenSchema";
import {
  redirectToErrorPage,
  redirectToCancelPageWithRedirectInfo,
  redirectToConfirmPage,
  getConfirmationUrlParams
} from "../../locationManagement";
import { convertClientTokenToConsentResponseToken } from "../../services/Consent/Token";
import { getRedirectUriOnAccountsPage } from "../../services/Authentication/iamUtils";
import { TIMEOUT_ERROR_PAGE_DEFAULT_INFO } from "../../shared/constants";

function getWithdrawalMessage(token) {
  const tppName =
    getTppName(token) || "the provider of the service you’re using";

  return `You can withdraw your consent at any time by contacting ${tppName}.`;
}

const AccountPage = withRouter(({ history, location }) => {
  const { token } = getConfirmationUrlParams(location);
  let tokenExpiryTimestamp;
  try {
    const responseToken = convertClientTokenToConsentResponseToken(token);
    tokenExpiryTimestamp = responseToken.exp;
  } catch (e) {
    console.log("JWT is missing an expiry timestamp,");
  }

  const onSubmit = async (chosenAccountIds = null) => {
    let consentRedirectInfo;

    if (chosenAccountIds.length > 0) {
      consentRedirectInfo = await bindAccountsAndGetConsentResponseInfo(
        token,
        chosenAccountIds
      );
    } else {
      consentRedirectInfo = await getConsentResponseInfoForConsented(token);
    }

    redirectToConfirmPage(
      history,
      consentRedirectInfo.url,
      consentRedirectInfo.token
    );
    return true;
  };
  const onCancel = async () => {
    const consentRedirectInfo = await getConsentResponseInfoForDeclinedConsent(
      token
    );
    redirectToCancelPageWithRedirectInfo(
      history,
      consentRedirectInfo.url,
      consentRedirectInfo.token
    );
  };
  const onError = async (customError = undefined) => {
    /**
     * The consent_request token above contains a consentApprovalRedirectUri
     * This contains the JWT originally sent by the TPP
     * This JWT contains a redirect_uri which can be used to redirect back to the TPP
     * when there are no accounts listed - no accounts mean no decision can be recorded
     */
    const nonConsentTppRedirectUri = getRedirectUriOnAccountsPage(
      token.consentApprovalRedirectUri
    );
    redirectToErrorPage(
      history,
      nonConsentTppRedirectUri,
      undefined,
      2,
      customError
    );
  };

  return (
    <>
      <TimeoutConsumer
        handleTimeout={() => onError(TIMEOUT_ERROR_PAGE_DEFAULT_INFO)}
        tokenExpiryTimestamp={tokenExpiryTimestamp}
      >
        <AccountSelection
          getAccounts={getAccounts}
          getIntent={() => getIntent(token)}
          withdrawalMessage={getWithdrawalMessage(token)}
          tppName={getTppName(token)}
          onSubmit={onSubmit}
          onError={onError}
          onCancel={onCancel}
        />
      </TimeoutConsumer>
    </>
  );
});

export default AccountPage;
