import React from "react";
import PropTypes from "prop-types";
import { TextInput, FormGroup } from "@jsluna/react";
import LinkList, { linkListType } from "../../sb-design/link-list/LinkList";

import "./OneTimePasscode.scss";

const OneTimePasscode = ({
  phoneNumber,
  links,
  onChange,
  onBlur,
  value,
  error,
  warning
}) => {
  return (
    <FormGroup
      label="One time passcode"
      name="one-time-passcode"
      className={`sb-one-time-passcode ${error && "has-error"}`}
    >
      <div>
        Please enter the one time passcode we’ve sent to ********{phoneNumber}
      </div>
      <TextInput
        name="one-time-passcode"
        autoFocus
        onChange={onChange}
        onBlur={onBlur}
        maxLength={6}
        autocomplete="off"
        value={value}
        pattern="[0-9]*"
        inputMode="decimal"
        required
      />
      {error && (
        <div
          id="phone-numberValidation"
          className="ln-c-field-info ln-c-field-info--error"
          role="alert"
          aria-live="assertive"
        >
          {error}
        </div>
      )}
      <span>Not received your code after 1 minute?</span>
      <LinkList links={links} />
      {warning && (
        <div aria-live="assertive" className="sb-warning">
          {warning}
        </div>
      )}
    </FormGroup>
  );
};

OneTimePasscode.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  links: linkListType.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  warning: PropTypes.oneOf([PropTypes.string, PropTypes.node])
};

OneTimePasscode.defaultProps = {
  error: null,
  warning: null
};

export default OneTimePasscode;
