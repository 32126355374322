import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./OBTooltipMessage.scss";

const TooltipMessage = ({
  children,
  element,
  innerRef,
  className,
  arrow,
  ...rest
}) => {
  const Element = element;
  return (
    <Element
      {...rest}
      className={classnames("OBln-c-tooltip__message", className)}
      ref={innerRef}
    >
      <span className="ln-u-visually-hidden">Help icon expanded</span>
      {children}
      {arrow}
    </Element>
  );
};

TooltipMessage.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.objectOf
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** The arrow element with positioning applied */
  arrow: PropTypes.element,
  /** Ref callback for message element */
  innerRef: PropTypes.func
};

TooltipMessage.defaultProps = {
  element: "div",
  children: undefined,
  className: undefined,
  arrow: undefined,
  innerRef: undefined
};

export default TooltipMessage;
