import React from "react";
import { Alert, AlertIcon } from "@jsluna/alert";
import { ErrorCircle } from "@jsluna/icons";
import "./Psd2ErrorBanner.scss";
import "@jsluna/alert/css/main.css";

const Psd2ErrorBanner = () => (
  <aside
    aria-label="Information about why we've logged you out"
    aria-atomic
    aria-live="assertive"
    className="sb-psd2-error-banner"
  >
    <Alert variant="error">
      <AlertIcon>
        <ErrorCircle aria-label="Error" role="img" />
      </AlertIcon>
      Your session has expired for security reasons, so we’ve logged you out.
    </Alert>
  </aside>
);

export default Psd2ErrorBanner;
