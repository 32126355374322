import { useEffect } from "react";

/**
 * Hook to handle setting of focus to an interactive element in the currently open modal
 * @param {String} id           Modal id
 * @param {Bool} isOpen         Modal open state
 */
function useModalFocusHook(id, isOpen) {
  useEffect(() => {
    if (isOpen) {
      const modalEl = document.getElementById(id);
      if (modalEl) {
        const focusableEls = modalEl.querySelectorAll(
          "a[href], area[href], input:not([disabled]), input[type='button'], select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
        );
        if (focusableEls && focusableEls.length > 0) {
          focusableEls[0].focus();
        }
      }
    }
  }, [id, isOpen]);
}

export default useModalFocusHook;
