import React from "react";
import { Container, FlagComponent, FlagWrapper, Footer } from "@jsluna/react";
import PropTypes from "prop-types";
import { NewWindow } from "@jsluna/icons";

import { EXTERNAL_RESOURCE_URLS } from "../constants";

import "./Footer.scss";

export default () => (
  <Footer className="footer">
    <Container className="container">
      <FlagWrapper respondAt="sm" className="ln-u-caption">
        <FlagComponent alignment="top">
          <div className="ln-u-display-flex ln-u-flex-wrap">
            <FooterLink
              isExternalLink
              label="Contact us (opens in new window)"
              text="Contact us"
              link={EXTERNAL_RESOURCE_URLS.CONTACT_US}
            />
            <span className="ln-u-push-sides-sm">|</span>
            <FooterLink
              isExternalLink
              label="View our privacy policy (opens in new window)"
              text="Privacy"
              link={EXTERNAL_RESOURCE_URLS.PRIVACY_POLICY}
            />
          </div>
        </FlagComponent>
      </FlagWrapper>
      <p className="footer-text">
        {`Sainsbury's Bank plc, Registered Office, 33 Holborn, London EC1N 2HT
        (registered in England and Wales, no. 3279730) is authorised by the
        Prudential Regulation Authority and regulated by the Financial Conduct
        Authority and the Prudential Regulation Authority (Register no. 184514).
        Sainsbury's Supermarkets Ltd is an appointed representative of
        Sainsbury's Bank plc.`}
        <br />
        {`Sainsbury's Bank plc is covered by the Financial Services Compensation
        Scheme (FSCS), details of which can be found `}
        <FooterLink
          isExternalLink
          label="View information about the Financial Services Compensation Scheme (opens in new window)"
          text="here"
          link={EXTERNAL_RESOURCE_URLS.FSCS}
        />
        .
      </p>
    </Container>
  </Footer>
);

const FooterLink = ({ isExternalLink, label, text, link }) => (
  <span
    className="ln-u-display-inline-flex ln-u-align-items-center ln-u-text-align-center footer-link"
    key={text}
  >
    <a
      aria-label={label}
      href={link}
      className="ln-o-bare-link"
      target={isExternalLink ? "_blank" : "_self"}
      rel="noreferrer noopener"
    >
      {isExternalLink && (
        <span className="footer__new-window-icon">
          <NewWindow aria-label={label} />
        </span>
      )}
      {text}
    </a>
  </span>
);

FooterLink.propTypes = {
  isExternalLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

FooterLink.defaultProps = {
  isExternalLink: false
};
