import React from "react";
import PropTypes from "prop-types";

import { List, ListItem, Link, LinkButton } from "@jsluna/react";
import ToolTip from "../tooltip/Tooltip";
import "./LinkList.scss";

const LinkList = ({ links }) => {
  const createLink = (text, type, external, href = null, onClick = null) => {
    if (type === "link")
      return (
        <Link
          href={href}
          bare
          color="alpha"
          target={external ? "_blank" : "_self"}
        >
          {text}
        </Link>
      );

    if (type === "button")
      return (
        <LinkButton onClick={onClick} bare color="alpha">
          {text}
        </LinkButton>
      );

    return null;
  };

  return (
    <List type="bare" spaced className="sb-link-list">
      {links.map(({ text, type, href, external, onClick, tooltip }, index) => {
        const linkElement = createLink(text, type, external, href, onClick);
        return (
          <ListItem type="bare" key={text}>
            {tooltip && <ToolTip message={tooltip}>{linkElement}</ToolTip>}
            {!tooltip && linkElement}
          </ListItem>
        );
      })}
    </List>
  );
};

const link = PropTypes.shape({
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
  type: "link"
});

const linkWithTooltip = PropTypes.shape({
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
  type: "link",
  tooltip: PropTypes.string.isRequired
});

const button = PropTypes.shape({
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: "button"
});

const buttonWithTooltip = PropTypes.shape({
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: "button",
  tooltip: PropTypes.string.isRequired
});

export const linkListType = PropTypes.arrayOf(
  PropTypes.oneOfType([link, linkWithTooltip, button, buttonWithTooltip])
);

LinkList.propTypes = {
  links: linkListType.isRequired
};

export default LinkList;
