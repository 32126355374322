import React from "react";
import PropTypes from "prop-types";
import "./OBForm.scss";

const OBForm = ({
  cancelButton,
  submitButton,
  children,
  error,
  className,
  ...rest
}) => (
  <form
    {...rest}
    className={`${className} OBForm${error ? " OBForm--error" : ""}`}
    id="SB-auth-OB-form"
  >
    {error && (
      <div className="OBForm__error">
        <div
          aria-atomic="true"
          aria-live="assertive"
          role="alert"
          id="OBForm__error-message"
          className="ln-c-field-info ln-c-field-info--error"
        >
          {error}
        </div>
      </div>
    )}
    <div className="OBForm__fields">{children}</div>
    <div className="OBForm__submit">
      <div className="OBForm__buttons">
        {cancelButton && (
          <div className="OBForm__cancel-button" size={{ xs: "1/2" }}>
            {cancelButton}
          </div>
        )}
        <div
          className="OBForm__submit-button"
          size={{ xs: cancelButton ? "1/2" : "1/1" }}
        >
          {submitButton}
        </div>
      </div>
    </div>
  </form>
);

OBForm.propTypes = {
  cancelButton: PropTypes.node,
  children: PropTypes.node,
  error: PropTypes.string,
  submitButton: PropTypes.node.isRequired,
  className: PropTypes.string
};

OBForm.defaultProps = {
  cancelButton: null,
  error: undefined,
  className: null,
  children: null
};

export default OBForm;
