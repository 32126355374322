// TODO: Placeholder models awaiting designs
import React from "react";
import PropTypes from "prop-types";
import { ErrorCircle } from "@jsluna/icons";
import { ProgressSpinner, Modal, ModalHeading } from "@jsluna/react";

const OPTRequestModal = ({ open, handleClose }) => (
  <Modal
    small
    handleClose={handleClose}
    open={open}
    headingId="info-modal"
    restrictClose
  >
    <ModalHeading element="h3">
      <ErrorCircle className="ln-u-font-size-display-3 error-circle" />
      Sending another code, this will take a few moments
    </ModalHeading>
    <div className="ln-u-text-align-center">
      <ProgressSpinner />
    </div>
  </Modal>
);

OPTRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default OPTRequestModal;
