import React, { Component } from "react";
import PropTypes from "prop-types";

import "./AuthenticationPage.scss";

import Psd2ErrorBanner from "./Psd2ErrorBanner";
import SubmitButton from "../sb-design/SubmitButton";
import PageHeading from "../sb-design/PageHeading";
import SBForm from "../sb-design/SBForm";
import { addOrdinal } from "../../shared/utils/addOrdinal";

import SBMobileLandlineModal from "./modals/sbMobileLandlineModal";
import SBRegisterModal from "./modals/sbRegisterModal";
import {
  PAGE_CONSTS,
  LANDLINE_DETAILS,
  MOBILE_DETAILS,
  EXTERNAL_RESOURCE_URLS
} from "../../shared/constants";
import { generateForgottenUrlSuite } from "../../services/fisUtilities";
import PhoneNumber from "./mobile-number/PhoneNumber";
import Username from "./username/Username";
import FscsBanner from "./fscs-banner/FscsBanner";
import { startWebSDK } from "../../shared/utils/callsign";

const SURROUNDING_WHITESPACE_REGEXP = /^\s|\s$/;
const {
  LS_REMEMBER_ME_STATUS,
  LS_REMEMBER_ME_KEY,
  LS_USERNAME_KEY,
  LS_IS_LANDLINE_KEY
} = PAGE_CONSTS;

const { LOGIN_GUIDE } = EXTERNAL_RESOURCE_URLS;

function removeValuesFromLocalStorage() {
  [LS_REMEMBER_ME_KEY, LS_USERNAME_KEY].forEach(key =>
    localStorage.removeItem(key)
  );
}

function validateUsername(username) {
  // Check set
  if (!username) return false;

  // Check length
  if (username.length < 8) return false;

  // Check username does NOT contain whitespace at beginning or end
  return !SURROUNDING_WHITESPACE_REGEXP.test(username);
}

function validateDigits(digits) {
  return digits.every(val => val != null && val !== "");
}

const validateUsernameAndGetError = username => {
  if (!username) return "Please enter your username.";

  return !validateUsername(username)
    ? "Please enter a username in a valid format."
    : undefined;
};

const validateDigitsAndGetError = digits => {
  return !validateDigits(digits) ? "Please enter your number." : undefined;
};

const validCredentials = (username, digits) => {
  return validateUsername(username) && validateDigits(digits);
};

const mobileRequiredDigits = ["9", "10", "11"];
const landlineRequiredDigits = ["3rd from last", "2nd from last", "last"];

export class ValidateUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      usernameError: undefined,
      digits: [null, null, null],
      digitError: undefined,
      loginError: null,
      formLoading: false,
      rememberMe: false,
      openModal: false,
      showRegisterMobileModal: false,
      showLandline: false
    };
    this.mobileLandlineModal = this.mobileLandlineModal.bind(this);
    this.registerMobileModal = this.registerMobileModal.bind(this);
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    const rememberMe =
      localStorage.getItem(LS_REMEMBER_ME_KEY) === LS_REMEMBER_ME_STATUS;
    const username = rememberMe ? localStorage.getItem(LS_USERNAME_KEY) : "";
    this.setState({ username, rememberMe });
  }

  onUsernameChanged = ({ target }) => {
    this.setState(state => ({
      username: target.value,
      usernameError: state.usernameError
        ? validateUsernameAndGetError(target.value)
        : undefined
    }));
  };

  submitForm = async event => {
    event.preventDefault();
    const { username, digits, rememberMe, showLandline } = this.state;

    const usernameError = validateUsernameAndGetError(username);
    const digitError = validateDigitsAndGetError(digits);
    if (usernameError || digitError) {
      this.setState({ usernameError, digitError }, this.scrollToError);
      return;
    }

    try {
      this.setState({ formLoading: true });
      const [complete, error] = await this.props.onSubmit(
        username,
        digits,
        showLandline
      );
      if (!complete) {
        this.setState({
          formLoading: false,
          loginError: error
        });
      }
    } catch (e) {
      console.log("Could not submit form");
      console.error(e);

      this.setState({
        loginError: "An error occurred when submitting the form",
        formLoading: false
      });
    }
    this.updateLocalStorage(rememberMe, username, showLandline);
  };

  updateLocalStorage = (rememberMe, username, isLandline) => {
    if (rememberMe) {
      localStorage.setItem(LS_REMEMBER_ME_KEY, LS_REMEMBER_ME_STATUS);
      localStorage.setItem(LS_USERNAME_KEY, rememberMe ? username : "");
    } else {
      removeValuesFromLocalStorage();
    }

    localStorage.setItem(LS_IS_LANDLINE_KEY, isLandline);
  };

  scrollToError = () => {
    const firstError = document.querySelector(".ln-c-form-group.has-error");
    if (firstError) {
      window.scrollTo(0, firstError.offsetTop);
      firstError.querySelector("input").focus();
    }
  };

  digitChanged = async (value, index) => {
    await this.setState(state => {
      const newDigits = [...state.digits];
      newDigits[index] = value;
      return { digits: newDigits };
    });
  };

  digitBlurred = async () => {
    await this.setState(state => {
      return { digitError: validateDigitsAndGetError(state.digits) };
    });
  };

  onRememberMeChange = () => {
    this.setState(state => ({ rememberMe: !state.rememberMe }));
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  onClickLandlineMobile = () => {
    this.setState(prevState => {
      return {
        openModal: false,
        showLandline: !prevState.showLandline,
        digits: [null, null, null],
        numberFilled: false,
        digitError: undefined,
        inputNumberClicked: false
      };
    });
  };

  closeRegisterModal = () => {
    this.setState({ showRegisterMobileModal: false });
  };

  mobileOrLandlineModal = ({ title, body, buttonText }) => {
    return (
      <SBMobileLandlineModal
        title={title}
        body={body}
        buttonText={buttonText}
        isOpen={this.state.openModal}
        handleClose={() => this.closeModal()}
        clickButton={() => this.onClickLandlineMobile()}
      />
    );
  };

  registerNumberModal = () => {
    const { showRegisterMobileModal } = this.state;
    return (
      <SBRegisterModal
        isOpen={showRegisterMobileModal}
        handleClose={() => this.closeRegisterModal()}
      />
    );
  };

  generateDigitInputs = () => {
    const { showLandline, digits } = this.state;

    const digitsRequired = showLandline
      ? landlineRequiredDigits
      : mobileRequiredDigits;

    return digitsRequired.map((digit, index) => {
      const ordinal = showLandline ? digit : addOrdinal(digit);
      return {
        label: `${ordinal} digit of your ${
          showLandline ? "home" : "mobile"
        } number`,
        value: digits[index] || "",
        name: `phone-${index}`,
        password: false,
        classname: `sb-multipart-input cs-phone-${index}`
      };
    });
  };

  registerMobileModal() {
    this.setState(prevState => {
      return {
        showRegisterMobileModal: !prevState.showRegisterMobileModal
      };
    });
  }

  mobileLandlineModal() {
    this.setState(prevState => {
      return {
        openModal: !prevState.openModal
      };
    });
  }

  render() {
    const { gotoParam } = this.props;
    const {
      usernameError,
      digitError,
      loginError,
      digits,
      username,
      formLoading,
      rememberMe,
      showLandline
    } = this.state;

    const landlineObj = showLandline ? LANDLINE_DETAILS : MOBILE_DETAILS;

    const submitFormEnabled =
      validCredentials(username, digits) && !formLoading;

    const submitButton = (
      <SubmitButton
        submitRef={el => {
          this.submitRef = el;
        }}
        disabled={!submitFormEnabled}
        text="Continue"
        progressMessage="Logging in..."
        loading={formLoading}
      />
    );

    const {
      FORGOTTEN_ALL_CREDENTIALS,
      FORGOTTEN_USERNAME
    } = generateForgottenUrlSuite(gotoParam);

    const mobileLinks = [
      {
        text: "Don’t have a mobile or having signal problems?",
        type: "button",
        onClick: this.mobileLandlineModal
      },
      {
        text: "Need to register your number?",
        type: "button",
        onClick: this.registerMobileModal
      }
    ];

    const usernameLinks = [
      {
        href: FORGOTTEN_USERNAME,
        type: "link",
        text: "Forgotten your username?",
        external: true
      },
      {
        href: FORGOTTEN_ALL_CREDENTIALS,
        type: "link",
        text: "Forgotten all your login details?",
        external: true
      },
      {
        href: LOGIN_GUIDE,
        type: "link",
        text: "Need help? visit our login guide",
        external: true,
        tooltip:
          "Some login issues may be fixed by updating your browser or clearing your cache and cookies. If you don’t know how to do that you can find out more in our guides."
      }
    ];

    if (!formLoading && loginError) {
      // reinitialise Callsign SDK if login error
      startWebSDK();
    }

    return (
      <div className="SBAuthenticationPage">
        {this.registerNumberModal()}
        {this.mobileOrLandlineModal(landlineObj)}
        {this.props.showRedirectError ? <Psd2ErrorBanner /> : null}
        <SBForm
          error={!formLoading && loginError}
          submitButton={submitButton}
          onSubmit={this.submitForm}
        >
          <PageHeading>Your login details</PageHeading>
          <Username
            links={usernameLinks}
            error={usernameError}
            value={username || ""}
            onChange={this.onUsernameChanged}
            onBlur={({ target }) => {
              this.setState(state => ({
                usernameError: validateUsernameAndGetError(target.value)
              }));
            }}
            onRememberMeChange={this.onRememberMeChange}
            rememberMe={rememberMe}
          />
          <PhoneNumber
            digits={this.generateDigitInputs()}
            links={mobileLinks}
            onChange={this.digitChanged}
            onBlur={this.digitBlurred}
            onEnterKey={event => {
              if (submitFormEnabled) this.submitForm(event);
            }}
            error={digitError}
            type={showLandline ? "Home" : "Mobile"}
          />
        </SBForm>
        <FscsBanner />
      </div>
    );
  }
}

ValidateUserPage.propTypes = {
  gotoParam: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showRedirectError: PropTypes.bool
};

ValidateUserPage.defaultProps = {
  showRedirectError: false
};
