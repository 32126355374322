import React from "react";

import logo from "./fscs-logo.png";
import ClickableLink from "../ob-design/ClickableLink";
import { EXTERNAL_RESOURCE_URLS } from "../../shared/constants";

import "./FscsBanner.scss";

const FscsBanner = () => (
  <aside
    aria-label="Find out more information about the Financial Services
      Compensation Scheme"
    className="OBFscsBanner"
  >
    <img src={logo} alt="FSCS logo" className="OBFscsBanner__image" />
    <div className="OBFscsBanner__text">
      All of our savings products are covered by the Financial Services
      Compensation Scheme.
      <ClickableLink
        aria-label="Find out more about the Financial Services Compensation Scheme here (opens in new window)"
        target="_blank"
        href={EXTERNAL_RESOURCE_URLS.FSCS}
      >
        Find out more
      </ClickableLink>
    </div>
  </aside>
);

export default FscsBanner;
