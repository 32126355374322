export function isMobile() {
  const agent = navigator.userAgent;
  return (
    agent.match(/Android/i) ||
    agent.match(/webOS/i) ||
    agent.match(/iPhone/i) ||
    agent.match(/iPad/i) ||
    agent.match(/Windows Phone/i)
  );
}
