import React from "react";
import PropTypes from "prop-types";
import { ErrorCircle } from "@jsluna/icons";
import {
  FilledButton,
  Modal,
  ModalHeading,
  GridWrapper,
  GridItem
} from "@jsluna/react";

const button = (isOpenBanking, handleCloseWrapper) => {
  if (isOpenBanking) {
    return (
      <FilledButton
        aria-label="Close timeout modal"
        className="ln-c-button ln-c-button--filled ln-c-button--full"
        onClick={handleCloseWrapper}
      >
        <div>Close</div>
      </FilledButton>
    );
  }
  return (
    <FilledButton
      fullWidth
      label="Close timeout modal"
      onClick={handleCloseWrapper}
      text="Close"
    >
      Close
    </FilledButton>
  );
};

const SbInactivityTImeoutModal = ({
  openModal,
  handleCloseWrapper,
  seconds,
  isOpenBanking
}) => {
  return (
    <Modal
      small
      open={openModal}
      handleClose={handleCloseWrapper}
      id="timeout-modal"
    >
      <ModalHeading element="h3">
        <ErrorCircle className="error-circle" />
        For security reasons, this page will timeout in:
      </ModalHeading>
      <GridWrapper>
        <div className="scroll-area">
          <GridItem className="grid-item" size={{ xs: "1/1" }}>
            <div className="countdown-timer" aria-atomic aria-live="polite">
              {seconds}
            </div>
            <div className="body-seconds">Seconds</div>
          </GridItem>
          <GridItem className="grid-item" size={{ xs: "1/1" }}>
            {button(isOpenBanking, handleCloseWrapper)}
          </GridItem>
        </div>
      </GridWrapper>
    </Modal>
  );
};

SbInactivityTImeoutModal.propTypes = {
  handleCloseWrapper: PropTypes.func,
  openModal: PropTypes.bool,
  seconds: PropTypes.number.isRequired,
  isOpenBanking: PropTypes.bool
};
SbInactivityTImeoutModal.defaultProps = {
  handleCloseWrapper: null,
  openModal: false,
  isOpenBanking: false
};

export default SbInactivityTImeoutModal;
