import { ProgressSpinner, FilledButton } from "@jsluna/react";
import React from "react";
import PropTypes from "prop-types";
import "./SubmitButton.scss";

const SubmitButton = ({
  disabled,
  text,
  progressMessage,
  loading,
  submitRef
}) => (
  <div className="sb-submit">
    <FilledButton
      type="submit"
      disabled={disabled}
      innerRef={submitRef}
      fullWidth
    >
      {loading ? (
        <>
          <ProgressSpinner
            role="progressbar"
            size="small"
            className="SubmitButton__spinner"
          />
          <span>{progressMessage}</span>
        </>
      ) : (
        text
      )}
    </FilledButton>
  </div>
);

export default SubmitButton;

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  progressMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  submitRef: PropTypes.func
};

SubmitButton.defaultProps = {
  submitRef: null
};
