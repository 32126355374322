import queryString from "query-string";

export const SERVICING_PATH = "/servicing";
export const FIS_URLS = {
  FORGOTTEN_USERNAME: "/forgotten-username",
  FORGOTTEN_CREDENTIALS: "/forgotten-credentials",
  FORGOTTEN_ALL_CREDENTIALS: "/forgottenallcredentials"
};

/**
 * Function to generate a suite of forgotten credentials URLs.
 * The goto parameter is appended by IAM based on the URL which
 * is requested to IAM which usually is the URL where SPA will
 * continue the journey on. Redirect_URI is populated by FIS; IAM
 * doesn’t modify or amend the redirect_uri; this URL is the URL
 * where FIS wants the IAM to post the token to after successful authentication.
 * @param {String} extractedGoto    Goto param returned from IAM
 */
export const generateForgottenUrlSuite = extractedGoto => {
  try {
    const {
      query: { redirect_uri: redirectUri, servicing_domain: servicingDomain }
    } = queryString.parseUrl(extractedGoto);
    const baseUrl = new URL(redirectUri);
    const { origin } = baseUrl;
    const base =
      servicingDomain === "default"
        ? process.env.REACT_APP_DEFAULT_SERVICING_DOMAIN
        : origin;

    return Object.keys(FIS_URLS).reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: `${base}${SERVICING_PATH}${FIS_URLS[curr]}`
      };
    }, {});
  } catch (e) {
    throw new Error("No redirect URI available in goto param");
  }
};
