import React from "react";
import PropTypes from "prop-types";

import "./InlineError.scss";

const InlineError = ({ message, dataTestId, hidden, id }) => (
  <div className="OBInlineText__error">
    <div className="OBInlineText__error--column" hidden={hidden} />
    <div
      aria-atomic
      aria-live="assertive"
      role="alert"
      data-testid={dataTestId}
      id={id}
      hidden={hidden}
    >
      {message}
    </div>
  </div>
);

InlineError.propTypes = {
  message: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  hidden: PropTypes.bool,
  id: PropTypes.string.isRequired
};

InlineError.defaultProps = {
  dataTestId: undefined,
  hidden: false
};

export default InlineError;
