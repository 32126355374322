import React from "react";
import { withRouter } from "react-router-dom";
import {
  FilledButton,
  OutlinedButton,
  GridWrapper,
  GridItem
} from "@jsluna/react";
import {
  BLOCKED_PAGE_DEFAULT_INFO,
  ERROR_PAGE_DEFAULT_INFO,
  FRAUD_BLOCKED_PAGE_DEFAULT_INFO
} from "../constants";
import { EXTERNAL_RESOURCE_URLS } from "../../shared/constants";
import { generateForgottenUrlSuite } from "../../services/fisUtilities";
import { decodeSBErrorLocationState } from "../../locationManagement";

import "./ErrorPage.scss";

const blockedButtons = (
  <GridWrapper matrix>
    <GridItem size={{ ss: "1/2" }}>
      <FilledButton
        element="a"
        target="_blank"
        fullWidth
        href={EXTERNAL_RESOURCE_URLS.CONTACT_US}
      >
        See our contact details
      </FilledButton>
    </GridItem>
    <GridItem size={{ ss: "1/2" }}>
      <OutlinedButton
        element="a"
        target="_blank"
        fullWidth
        href={EXTERNAL_RESOURCE_URLS.FAQS}
      >
        See our FAQs
      </OutlinedButton>
    </GridItem>
  </GridWrapper>
);

const errorPageDetail = (accountStatus, gotoParam) => {
  switch (accountStatus) {
    case "BLOCKED": {
      return {
        title: BLOCKED_PAGE_DEFAULT_INFO.title,
        description: BLOCKED_PAGE_DEFAULT_INFO.description,
        buttons: blockedButtons
      };
    }
    case "SUSPENDED": {
      const { FORGOTTEN_CREDENTIALS } = generateForgottenUrlSuite(gotoParam);
      return {
        title: "Your online banking access has been suspended",
        description:
          "To protect your account, we automatically suspend your online banking access when your log in details have been entered incorrectly 3 times.",
        callToAction:
          "To regain access to your account you will need to reset your details.",
        buttons: (
          <FilledButton element="a" href={FORGOTTEN_CREDENTIALS} fullWidth>
            Reset your details
          </FilledButton>
        )
      };
    }

    case "FRAUD_BLOCKED": {
      return {
        title: FRAUD_BLOCKED_PAGE_DEFAULT_INFO.title,
        description: FRAUD_BLOCKED_PAGE_DEFAULT_INFO.description,
        buttons: blockedButtons
      };
    }

    default: {
      return {
        title: ERROR_PAGE_DEFAULT_INFO.title,
        description: ERROR_PAGE_DEFAULT_INFO.description
      };
    }
  }
};

const ErrorPage = withRouter(({ location }) => {
  const { accountStatus, gotoParam } = decodeSBErrorLocationState(location);
  const { buttons, title, description, callToAction } = errorPageDetail(
    accountStatus,
    gotoParam
  );
  return (
    <div className="ln-o-container container sb-error-page">
      <h2>{title}</h2>
      <div>
        <p>{description}</p>
        {callToAction && <p>{callToAction}</p>}
        {buttons}
      </div>
    </div>
  );
});

export default ErrorPage;
