import { number, string } from "yup";

import {
  ACCOUNT_STATUS_ENUM,
  INETUSERSTATUSES,
  SB_CUSTOMER_ACCOUNT_STATUSES
} from "../constants";

const SBCUSTOMERSTATUS = string().oneOf(
  Object.keys(SB_CUSTOMER_ACCOUNT_STATUSES)
);

const INETUSERACCESS = number().oneOf(INETUSERSTATUSES);

/**
 *
 * @param {String}  sbCustomerStatus    One of ACTIVE, SUSPENDED, BLOCKED, DELETED, NEW
 * @param {Number} inetUserAccess       1, 2, 3
 */
export const accountStatusDecisionTree = (sbCustomerStatus, inetUserAccess) => {
  try {
    SBCUSTOMERSTATUS.validateSync(sbCustomerStatus);
    INETUSERACCESS.validateSync(inetUserAccess);

    if (sbCustomerStatus === "SUSPENDED") {
      // ACCOUNT SUSPENDED MESSAGE
      return ACCOUNT_STATUS_ENUM[sbCustomerStatus];
    }
    if (inetUserAccess === 3 || sbCustomerStatus !== "SUSPENDED") {
      return ACCOUNT_STATUS_ENUM.BLOCKED;
    }
  } catch (e) {
    throw new Error("Invalid account status");
  }
  return null;
};
