import { string, object, array } from "yup";
import {
  SCHEMA_BLACKBOX_ID,
  SCHEMA_IS_LANDLINE_ID,
  SCHEMA_JSON_KEY_VALUES_ID,
  SCHEMA_PHONE_ID,
  SCHEMA_USERNAME_ID
} from "../../shared/constants";

// KEEP ME (maybe not Lint error!)
// const VALID_PIN_CALLBACKS = ["1", "2", "3", "4", "5", "6"];

// TODO - REINTRODUCE STRICT STRING VALIDATION
// One time password is optional
export const FULL_SECURITY_CHECK_SCHEMA = object().shape({
  authId: string().required(),
  callbacks: array()
    .min(6)
    .max(6)
    .of(
      object().shape({
        output: array()
          .min(1)
          .max(2)
          .of(
            object().shape({
              value: string(),
              name: string()
            })
          )
          .required(),
        input: array()
          .min(0)
          .max(1)
          .of(object())
      })
    )
    .required()
});

export const NO_OTP_SECURITY_CHECK_SCHEMA = object().shape({
  authId: string().required(),
  callbacks: array()
    .min(5)
    .max(5)
    .of(
      object().shape({
        output: array()
          .min(1)
          .max(2)
          .of(
            object().shape({
              value: string(),
              name: string()
            })
          )
          .required(),
        input: array()
          .min(0)
          .max(1)
          .of(
            object().shape({
              value: string(),
              name: string()
            })
          )
      })
    )
    .required()
});

export const VALIDATE_SCHEMA = object().shape({
  authId: string().required(),
  callbacks: array()
    .min(5)
    .max(5)
    .of(
      object().shape({
        type: string()
          .oneOf(["NameCallback", "HiddenValueCallback"])
          .required(),
        output: array()
          .min(1)
          .max(2)
          .of(
            object().shape({
              value: string()
            })
          )
          .required(),
        input: array()
          .min(1)
          .max(1)
          .of(object())
      })
    )
    .required()
});

export const ACCOUNT_INACTIVE_OR_SUSPENDED = object().shape({
  authId: string().required(),
  callbacks: array()
    .min(2)
    .max(2)
    .of(
      object().shape({
        type: string().matches(/(TextOutputCallback)/),
        output: array()
          .min(2)
          .max(2)
          .of(
            object().shape({
              name: string().required(),
              value: string().notOneOf(["Fraud_Deny"])
            })
          )
          .required()
      })
    )
    .required()
});

export const FRAUD_DENY_SCHEMA = object().shape({
  authId: string().required(),
  callbacks: array()
    .min(1)
    .max(1)
    .of(
      object().shape({
        type: string().matches(/(TextOutputCallback)/),
        output: array()
          .min(2)
          .max(2)
          .of(
            object().shape({
              name: string().required(),
              value: string().matches(/(Fraud_Deny|0)/)
            })
          )
          .required()
      })
    )
});

export function isTokenSet(json) {
  return !!json.tokenId;
}

export function checkForOTPCallback(json) {
  return (
    !NO_OTP_SECURITY_CHECK_SCHEMA.isValidSync(json) &&
    FULL_SECURITY_CHECK_SCHEMA.isValidSync(json)
  );
}

export function isSecuritySchemaValidSync(json) {
  if (FULL_SECURITY_CHECK_SCHEMA.isValidSync(json)) {
    return FULL_SECURITY_CHECK_SCHEMA.validateSync(json);
  }
  return NO_OTP_SECURITY_CHECK_SCHEMA.validateSync(json);
}

export async function isSecuritySchemaValidAsync(json) {
  if (await FULL_SECURITY_CHECK_SCHEMA.isValid(json)) {
    return FULL_SECURITY_CHECK_SCHEMA.validate(json);
  }
  return NO_OTP_SECURITY_CHECK_SCHEMA.validate(json);
}

export function getRequestedPinDigits(json) {
  isSecuritySchemaValidSync(json);

  // PIN callbacks are at indices 1 through 3
  const pinDigits = json.callbacks
    .slice(1, 4)
    .map(value => value.output[0].value);

  return pinDigits;
}

export function generateSecurityCheckRequest(
  responseTemplate,
  pin,
  password,
  otp = null
) {
  const updateCallbackValue = (item, value) => ({
    ...item,
    input: [{ ...item.input[0], value }]
  });
  const callbacks = responseTemplate.callbacks.map(item => {
    // Return login count callback as is
    if (item.type === "TextOutputCallback") {
      return item;
    }

    const outputValue = item.output[0].value;
    const inputValue = item.input[0].name;

    if (outputValue === "Password") {
      return updateCallbackValue(item, password);
    }

    if (inputValue === "IDToken2") {
      return updateCallbackValue(item, pin[0]);
    }

    if (inputValue === "IDToken3") {
      return updateCallbackValue(item, pin[1]);
    }

    if (inputValue === "IDToken4") {
      return updateCallbackValue(item, pin[2]);
    }

    if (outputValue === "One Time Password") {
      return updateCallbackValue(item, otp.toString());
    }

    return null;
  });
  return { ...responseTemplate, callbacks };
}

export function generateValidationRequest(
  initResponseBody,
  username,
  phoneDigits,
  jsonKeyValues,
  isLandline,
  blackbox
) {
  const { authId, callbacks } = { ...initResponseBody };

  const usernameCallback = callbacks.find(cb => cb._id === SCHEMA_USERNAME_ID);
  const mobileCallback = callbacks.find(cb => cb._id === SCHEMA_PHONE_ID);
  const jsonKeyValuesCallback = callbacks.find(
    cb => cb._id === SCHEMA_JSON_KEY_VALUES_ID
  );
  const isLandlineCallback = callbacks.find(
    cb => cb._id === SCHEMA_IS_LANDLINE_ID
  );
  const blackboxCallback = callbacks.find(cb => cb._id === SCHEMA_BLACKBOX_ID);

  usernameCallback.input[0].value = username;
  mobileCallback.input[0].value = phoneDigits.join("");
  isLandlineCallback.input[0].value = isLandline.toString();
  blackboxCallback.input[0].value = JSON.stringify(blackbox);

  // Activity code key: value pair
  if (jsonKeyValues && Object.keys(jsonKeyValues).length > 0) {
    jsonKeyValuesCallback.input[0].value = JSON.stringify({ ...jsonKeyValues });
  }

  return {
    authId,
    callbacks: [
      usernameCallback,
      mobileCallback,
      jsonKeyValuesCallback,
      isLandlineCallback,
      blackboxCallback
    ]
  };
}
