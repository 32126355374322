import uuidv4 from "uuid/v4";
import {
  checkForOTPCallback,
  ACCOUNT_INACTIVE_OR_SUSPENDED,
  VALIDATE_SCHEMA,
  FRAUD_DENY_SCHEMA,
  isSecuritySchemaValidAsync,
  isSecuritySchemaValidSync,
  isTokenSet,
  getRequestedPinDigits,
  generateSecurityCheckRequest,
  generateValidationRequest
} from "./APISchema";

import { accountStatusDecisionTree } from "./accountStatusUtils";
import {
  ACCOUNT_STATUS_ENUM,
  errors,
  MAX_FAILURE_COUNT,
  OPENAPI_STANDARD_HEADERS,
  ACTIVITY_CODES
} from "../constants";

// Destructure standard headers for re-use
const {
  X_SB_CHANNEL_ID_HEADER_VALUE_WEB,
  X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
  X_SB_CLIENT_ID_HEADER_VALUE,
  X_SB_CHANNEL_VERSION_HEADER_VALUE
} = OPENAPI_STANDARD_HEADERS;

const AUTH_TREE_VALUE = process.env.REACT_APP_AUTH_TREE_VALUE;

const REQUEST_URI = process.env.REACT_APP_SB_LOGIN
  ? `authenticate?authIndexType=service&authIndexValue=${AUTH_TREE_VALUE}&ForceAuth=true`
  : "authenticate?ForceAuth=true";

const ACTIVITY_CODE = process.env.REACT_APP_SB_LOGIN
  ? ACTIVITY_CODES.WEB_AUTH_ONLINE_LOGIN
  : ACTIVITY_CODES.TPP_ONLINE_LOGIN;

class UserValidationError extends Error {
  constructor(message, retry) {
    super();
    this.message = message;
    this.retry = retry;
    this.isUserValidationError = true;
  }
}

export const setXSBChannelIDHeader = () => {
  return process.env.REACT_APP_SB_LOGIN
    ? X_SB_CHANNEL_ID_HEADER_VALUE_WEB
    : X_SB_CHANNEL_ID_HEADER_VALUE_PSD2;
};

export const handleReturnForInvalidOrSuspended = callbacks => {
  const sbCustomerStatus = callbacks.find(obj =>
    /sbCustomerStatus/.test(obj.output[0].value)
  ).output[0].value;
  const inetUserAccess = callbacks.find(obj =>
    /inetUserAccess/.test(obj.output[0].value)
  ).output[0].value;

  return {
    accountStatus: accountStatusDecisionTree(
      sbCustomerStatus.split("=")[1],
      inetUserAccess.split("=")[1]
    ),
    valid: false,
    retry: false
  };
};

export async function checkUsernameAndDigits(
  username,
  digits,
  isLandline,
  blackbox = false
) {
  let response;
  let result;

  try {
    response = await initialiseValidation();
  } catch (e) {
    const retry = e.isUserValidationError ? e.retry : true;
    const error = e.isUserValidationError
      ? e.message
      : errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE;

    return {
      valid: false,
      retry,
      error
    };
  }

  if (!blackbox) {
    return {
      valid: false,
      retry: true,
      error: errors.NO_BLACKBOX_MESSAGE
    };
  }

  const body = generateValidationRequest(
    response,
    username,
    digits,
    { activityCode: ACTIVITY_CODE },
    isLandline,
    blackbox
  );
  try {
    result = await validateUser(body);
  } catch (e) {
    const retry = e.isUserValidationError ? e.retry : true; // todo redo logic
    const error = e.isUserValidationError
      ? e.message
      : errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE;

    return {
      valid: false,
      retry,
      error
    };
  }

  if (result.isFraudDeny) {
    return {
      valid: false,
      retry: false,
      accountStatus: ACCOUNT_STATUS_ENUM.FRAUD_BLOCKED
    };
  }
  if (result.isAccountInactiveOrSuspended) {
    return handleReturnForInvalidOrSuspended(result.data.callbacks);
  }

  return {
    valid: true,
    data: result
  };
}

async function initialiseValidation() {
  // Get initial callback structure
  const response = await fetch(
    `${API_URL_PREFIX}/${REQUEST_URI}`,
    getFetchHeaders("POST")
  );

  if (response.status < 200 || response.status >= 300) {
    console.log(
      `Could not initialise authentication due to an unexpected response status from the Authentication API - status code ${response.status}`
    );
    throw Error(errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE);
  }

  const json = await response.json();

  VALIDATE_SCHEMA.validate(json).catch(e => {
    console.log(
      "Response from Authentication API had a body that does not match the expected format"
    );
    console.log(e);

    throw Error(errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE);
  });

  return json;
}

async function validateUser(requestBody) {
  const options = {
    ...getFetchHeaders("POST"),
    body: JSON.stringify(requestBody)
  };
  const response = await fetch(`${API_URL_PREFIX}/${REQUEST_URI}`, options);

  // Check for invalid credentials (4xx status) - e.g. on third invalid check
  if (response.status === 400) {
    throw new UserValidationError(errors.ACCOUNT_ERROR_MESSAGE, false);
  }

  // Handle unauthorised status - e.g. if last three digits of mobile
  // are invalid - permit user to retry
  if (response.status === 401) {
    throw new UserValidationError(
      errors.INVALID_CREDENTIALS_OR_OTP_FAILURE_MESSAGE,
      true
    );
  }

  // Check for anything status other than OK (2xx status)
  if (response.status < 200 || response.status >= 300) {
    throw new UserValidationError(
      errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE,
      true
    );
  }

  const json = await response.json();

  // Check for invalid username and mobile - e.g. on first and second attempts
  // this will return status 200 and validate_schema
  if (isInvalidUsernameOrMobile(response.status, json)) {
    throw new UserValidationError(
      errors.INVALID_CREDENTIALS_OR_OTP_FAILURE_MESSAGE,
      true
    );
  }

  if (FRAUD_DENY_SCHEMA.isValidSync(json)) {
    return {
      isFraudDeny: true,
      data: json
    };
  }

  if (await isAccountInactiveOrSuspended(response.status, json)) {
    return {
      isAccountInactiveOrSuspended: true,
      data: json
    };
  }

  if (await isAuthRequest(response.status, json)) {
    const requestedPinDigits = getRequestedPinDigits(json);
    const includeOTP = checkForOTPCallback(json);
    return {
      includeOTP,
      requestedPinDigits,
      responseTemplate: json
    };
  }

  try {
    // Make sure the response body format matches what we expect
    isSecuritySchemaValidAsync(json);
  } catch (e) {
    console.log(
      "Response from ValidateUser API had a body that does not match the expected format"
    );
    console.log(e);

    throw new UserValidationError(
      errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE,
      true
    );
  }

  return null;
}

/**
 * Check for invalid credentials (200 status) and undefined / null token
 * @param {Number} status             HTTP status code
 * @param {Object} json               JSON response
 */

function isInvalidUsernameOrMobile(status, json) {
  return status === 200 && VALIDATE_SCHEMA.isValidSync(json);
}

function isInvalidCredentials(status, json) {
  return status === 200 && isSecuritySchemaValidSync(json);
}

async function isAccountInactiveOrSuspended(status, json) {
  return status === 200 && ACCOUNT_INACTIVE_OR_SUSPENDED.isValidSync(json);
}

async function isAuthRequest(status, json) {
  return status === 200 && isSecuritySchemaValidAsync(json);
}

const API_URL_PREFIX = process.env.REACT_APP_IAM_API_HOST;

const getFetchHeaders = method => {
  return {
    method,
    ...FETCH_OPTIONS()
  };
};

const FETCH_OPTIONS = () => ({
  mode: "cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Accept-API-Version": "resource=2.1, protocol=1.0",
    "Content-Type": "application/json",
    "x-sb-channel-id": setXSBChannelIDHeader(),
    "x-sb-channel-version": X_SB_CHANNEL_VERSION_HEADER_VALUE,
    "x-sb-client-id": X_SB_CLIENT_ID_HEADER_VALUE,
    "x-fapi-interaction-id": uuidv4()
  }
});

const UNEXPECTED_ERROR_RESPONSE = {
  valid: false,
  error: errors.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE,
  retry: true
};

/**
 * Extracts failure count from failure count callback
 * @param {Array}     callbacks       IAM returned callback array
 */
export const getFailureCount = callbacks => {
  if (!callbacks.some(c => c.type === "TextOutputCallback")) {
    return undefined;
  }

  const failuresRegex = /failures=(\d+)/;

  const failureCountFromCallback = callbacks.find(c =>
    failuresRegex.test(c.output[0].value)
  );

  if (!failureCountFromCallback) {
    return undefined;
  }

  return parseInt(
    failureCountFromCallback.output[0].value.match(failuresRegex)[1],
    10
  );
};

export async function checkSecurity(
  responseTemplate,
  pin,
  password,
  otp = null
) {
  const lastFailureCount = getFailureCount(responseTemplate.callbacks);
  const requestBody = generateSecurityCheckRequest(
    responseTemplate,
    pin,
    password,
    otp
  );

  const options = {
    ...getFetchHeaders("POST"),
    body: JSON.stringify(requestBody)
  };

  const response = await fetch(`${API_URL_PREFIX}/${REQUEST_URI}`, options);

  // Check for session timeout (400 status)
  if (response.status === 400) {
    return {
      valid: false,
      error: errors.SESSION_TIMEOUT_MESSAGE,
      retry: false
    };
  }

  // Check for unauthorised security check attempts (401 status)
  // https://sb97digital.atlassian.net/wiki/spaces/IAM/pages/743506009/SB+Authentication+SPA+TPP+non-TPP+-+IAM+APIs+-+September+-+v.0.7
  if (response.status === 401) {
    /**
     * IAM can return a 401 for unauthorised attempts. For the 401
     * to be related to a suspended account, we need to capture the failureCount
     * from the previous response template.
     * This responseTemplate will include a failureCount callback with a value of 0, 1 or 2.
     * The maximum number of security check attempts permitted is three, therefore
     * if a user enters invalid details, and submits those details when the failureCount
     * is 2, the subsequent 401 is because the account has been suspended
     *
     */
    if (lastFailureCount === 2) {
      return {
        accountStatus: ACCOUNT_STATUS_ENUM.SUSPENDED,
        valid: false,
        error: errors.OB_SUSPENDED_MESSAGE,
        retry: false
      };
    }

    // Handle non-suspended 401 status codes
    return UNEXPECTED_ERROR_RESPONSE;
  }

  // Check for anything status other than OK (2xx status)
  if (response.status < 200 || response.status >= 300) {
    return UNEXPECTED_ERROR_RESPONSE;
  }

  const json = await response.json();

  // Check for valid credentials - different schema than for
  // invalid credentials, therefore isInvalidCredentials validation
  // will fail
  if (isTokenSet(json)) {
    return {
      valid: true
    };
  }

  /**
   * If response matches has 200 status, and matches the initial security json template,
   * with the exception of the failure count,
   * we know that the credentials were invalid
   */
  if (!isTokenSet(json) || isInvalidCredentials(response.status, json)) {
    const failureCount = getFailureCount(json.callbacks);

    let failed = false;
    let attemptsRemaining;

    if (failureCount && failureCount >= 1 && failureCount <= 2) {
      failed = true;
      attemptsRemaining = MAX_FAILURE_COUNT - failureCount;
    }

    return {
      valid: false,
      error: failed
        ? `One or more of your security details are incorrect. You have ${attemptsRemaining} ${
            attemptsRemaining === 1 ? "try" : "tries"
          } remaining.`
        : errors.INVALID_PIN_MESSAGE,
      retry: true,
      jsonTemplate: json
    };
  }

  return {
    valid: true
  };
}
