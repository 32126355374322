import React, { useRef } from "react";
import PropTypes from "prop-types";
import { TextInput } from "@jsluna/react";
import "./MultiDigitInput.scss";

const MultiDigitInput = ({ parts, onChange, onBlur, onEnterKey }) => {
  const refs = useRef([]);

  const onKeyDown = event => {
    const {
      key,
      target: {
        dataset: { index }
      }
    } = event;
    if (key !== "Backspace" && key !== "Enter") return;

    const last = refs.current[index - 1];
    const next = refs.current[index + 1];

    event.preventDefault();

    if (key === "Backspace") {
      if (event.target.value === "") {
        if (last) {
          onChange("", index - 1);
          last.focus();
        }
      } else {
        onChange("", index);
      }
    } else if (key === "Enter" && !next && onEnterKey) {
      onEnterKey(event);
    }
  };

  function findCursorPosition(event) {
    const cursorPos = event.target.selectionStart;
    return cursorPos;
  }

  const onValueChange = event => {
    let newValue;
    const cursorPos = findCursorPosition(event);
    const index = Number(event.target.dataset.index);
    const next = refs.current[index + 1];

    if (cursorPos === 2 && index >= 0) {
      newValue = [...event.target.value].pop();
    } else if (cursorPos && index !== 0) {
      newValue = [...event.target.value].shift();
    } else {
      newValue = [...event.target.value].shift();
    }

    event.preventDefault();

    if (/^\d$/.test(newValue)) {
      onChange(newValue, index);
      if (next) next.focus();
    }
  };

  const checkFocus = () => {
    if (!hasFocus()) {
      onBlur();
    }
  };

  const hasFocus = () => {
    return (
      refs.current.filter(input => {
        return document.activeElement === input;
      }).length > 0
    );
  };

  const isMacOS = () => {
    if (/(iPhone|iPad|iPod|like).+Mac OS/.test(navigator?.userAgent))
      return false;

    return /Mac OS/.test(navigator?.userAgent);
  };

  return (
    <>
      {parts.map(
        ({ name, label, password, classname, value, placeHolder }, index) => (
          <TextInput
            key={name}
            aria-label={label}
            // this is needed to stop VoiceOver on Mac intrupting itself and have the component still accessible on Android/iOS
            aria-hidden={isMacOS()}
            name={name}
            onChange={onValueChange}
            onKeyDown={onKeyDown}
            data-index={index}
            className={classname || "sb-multipart-input"}
            autoComplete="off"
            pattern="[0-9]*"
            value={value}
            type={password ? "password" : "text"}
            inputmode="numeric"
            onBlur={() => setTimeout(checkFocus, 0)}
            placeholder={placeHolder || ""}
            innerRef={element => {
              refs.current[index] = element;
            }}
          />
        )
      )}
    </>
  );
};

export const multiDigitInputPartType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    password: PropTypes.bool.isRequired,
    placeHolder: PropTypes.string
  })
);

MultiDigitInput.propTypes = {
  parts: multiDigitInputPartType.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func
};

MultiDigitInput.defaultProps = {
  onBlur: () => {},
  onEnterKey: () => {}
};

export default MultiDigitInput;
