import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import BlockedButtons from "./BlockedButtons";
import { FinalPage } from "../FinalPage";
import {
  BLOCKED_PAGE_DEFAULT_INFO,
  ERROR_PAGE_DEFAULT_INFO,
  ERROR_PAGE_NO_ACCOUNTS_INFO,
  TIMEOUTS,
  SUSPENDED_PAGE_DEFAULT_INFO,
  FRAUD_BLOCKED_PAGE_DEFAULT_INFO
} from "../constants";
import {
  decodeErrorLocationState,
  redirectAfterTimeout
} from "../../locationManagement";

const accountErrorMessages = accountStatus => {
  switch (accountStatus) {
    case 0: {
      return BLOCKED_PAGE_DEFAULT_INFO;
    }
    case 1: {
      return SUSPENDED_PAGE_DEFAULT_INFO;
    }
    case 2: {
      return ERROR_PAGE_NO_ACCOUNTS_INFO;
    }
    case 3: {
      return FRAUD_BLOCKED_PAGE_DEFAULT_INFO;
    }

    default: {
      return ERROR_PAGE_DEFAULT_INFO;
    }
  }
};

const ErrorPage = withRouter(({ location }) => {
  try {
    const {
      error: errorCode,
      error_description: errorDescription
    } = queryString.parse(location.hash);

    if (errorCode && errorDescription) {
      return (
        <FinalPage
          isError
          title={ERROR_PAGE_DEFAULT_INFO.title}
          description={ERROR_PAGE_DEFAULT_INFO.description}
          loading={false}
        />
      );
    }
  } catch (err) {
    console.log("no search found");
  }

  const {
    tppRedirectUri,
    accountStatus,
    customError
  } = decodeErrorLocationState(location);

  const { title, description } =
    customError || accountErrorMessages(accountStatus);

  let redirecting = false;
  if (tppRedirectUri) {
    const timeout =
      accountStatus === 1 ? TIMEOUTS.SUSPENDED_TIMEOUT : TIMEOUTS.ERROR_TIMEOUT;
    redirectAfterTimeout(tppRedirectUri, timeout);
    redirecting = true;
  }
  return (
    <FinalPage
      isError
      title={title}
      description={description}
      loading={redirecting}
    >
      {(accountStatus === 0 || accountStatus === 3) && <BlockedButtons />}
    </FinalPage>
  );
});

export default ErrorPage;
