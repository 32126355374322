import React from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import { NewWindow } from "@jsluna/icons";

import { BUTTON_VARIANTS } from "../constants";
import "./LinkButton.scss";

const LinkButton = ({
  id,
  className,
  ariaLabel,
  href,
  variant,
  isExternal,
  children,
  ...rest
}) => {
  return (
    <a
      id={id}
      className={classnames(
        "ln-c-button",
        "OBLinkButton",
        variant && `ln-c-button--${variant}`,
        className
      )}
      aria-label={ariaLabel}
      href={href}
      target={isExternal ? "_blank" : "_self"}
      rel="noreferrer"
      {...rest}
    >
      {isExternal && (
        <span>
          <NewWindow
            aria-label={ariaLabel}
            className="OBLinkButton__new-window-icon"
          />
        </span>
      )}
      {children}
    </a>
  );
};

LinkButton.propTypes = {
  id: propTypes.string.isRequired,
  className: propTypes.string,
  ariaLabel: propTypes.string,
  href: propTypes.string.isRequired,
  variant: propTypes.oneOf(
    Object.keys(BUTTON_VARIANTS).map(key => BUTTON_VARIANTS[key])
  ),
  isExternal: propTypes.bool,
  children: propTypes.node.isRequired
};

LinkButton.defaultProps = {
  className: undefined,
  ariaLabel: undefined,
  variant: undefined,
  isExternal: false
};

export default LinkButton;
