// check if inputmode data attribute is supported.
export const isInputModeValid = () => {
  const testInput = document.createElement("input");
  testInput.setAttribute("inputmode", "numeric");
  testInput.setAttribute("autocomplete", "off");
  return !!(
    testInput.inputMode &&
    testInput.autocomplete &&
    "webkitTextSecurity" in getComputedStyle(testInput)
  );
};
