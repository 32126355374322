import React, { useState, useEffect, useRef, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import useModalFocusHook from "../utils/customHooks";
import { redirectToErrorPage } from "../../locationManagement";
import { TIMEOUT_ERROR_PAGE_DEFAULT_INFO } from "../constants";
import TimeOutWarning from "../../sainsburysBank/sb-design/timeout-warning/TimeOutWarning";

import "./Timeout.scss";
import SbInactivityTImeoutModal from "./sbInactivityTImeoutModal";

const TWO_MINUTES_IN_SECONDS = 120;
const FIVE_MINUTES_TIMEOUT_IN_SECONDS = 300;
const INITIAL_TIMEOUT = 1000;
const COUNTDOWN_INCREMENT_IN_SECONDS = 1;
const MODAL_COUNTDOWN_INCREMENT_IN_SECONDS = 5;

const Timeout = withRouter(
  ({
    location,
    history,
    handleTimeout,
    isOpenBanking,
    tokenExpiryTimestamp
  }) => {
    let tppRedirectUri = null;

    if (location && location.state && location.state.tppRedirectUri) {
      const { tppRedirectUri: tempTppURI } = location.state;
      tppRedirectUri = tempTppURI;
    }

    const [
      originalTokenExpiryTimestamp,
      setOriginalTokenExpiryTimestamp
    ] = useState(null); // initial timeout set to a value to startup the interval. This value gets overridden when modal opens
    const [seconds, setSeconds] = useState(INITIAL_TIMEOUT); // initial timeout set to a value to startup the interval. This value gets overridden when modal opens
    const [openModal, setModalOpen] = useState(false);
    const [isTwoMinsRemaining, setRemainingTwoMins] = useState(false);
    const [modalClosed, setModalClosed] = useState(false);
    const [countdownIncrement, setModalCountdownIncrement] = useState(
      COUNTDOWN_INCREMENT_IN_SECONDS
    );
    const [modalToReopen, setModalToReopen] = useState();
    const focusedElBeforeOpen = useRef(document.activeElement);

    const timerRef = useRef();

    const expiryState = useCallback(() => {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      const ts = tokenExpiryTimestamp - unixTimestamp;

      /**
       * Capture original token expiry timestamp - this  is required
       * when we request a new OTP; a new responseTemplate and
       * token expiry are generated
       */
      if (!originalTokenExpiryTimestamp) {
        setOriginalTokenExpiryTimestamp(tokenExpiryTimestamp);
      }

      // Check whether the new token expiry differs from the original
      if (originalTokenExpiryTimestamp !== tokenExpiryTimestamp) {
        setOriginalTokenExpiryTimestamp(tokenExpiryTimestamp);
        setSeconds(ts);
        /**
         * Reset modal open state bools
         * The if statement below will capture whether to open the
         * any set timeout, open modal logic if new timestamp
         * has less than 2 minutes remaining
         */
        setRemainingTwoMins(false);
        setModalOpen(false);
        setModalClosed(false);
      }

      if (ts <= TWO_MINUTES_IN_SECONDS && !isTwoMinsRemaining) {
        setModalCountdownIncrement(MODAL_COUNTDOWN_INCREMENT_IN_SECONDS);
        setSeconds(ts);
        setRemainingTwoMins(true);
        setModalOpen(true);
      }
    }, [
      tokenExpiryTimestamp,
      originalTokenExpiryTimestamp,
      isTwoMinsRemaining
    ]);

    const handleCloseModal = useCallback(() => {
      setModalOpen(false);
      setModalClosed(true);
    }, [setModalOpen, setModalClosed]);

    useEffect(() => {
      expiryState();
      if (seconds <= 0) {
        if (!modalClosed) {
          handleCloseModal();
        }
        clearInterval(timerRef.current);

        if (handleTimeout) {
          handleTimeout();
        } else {
          redirectToErrorPage(
            history,
            tppRedirectUri,
            undefined,
            undefined,
            TIMEOUT_ERROR_PAGE_DEFAULT_INFO
          );
        }
      }
    }, [
      handleCloseModal,
      handleTimeout,
      history,
      modalClosed,
      seconds,
      tppRedirectUri,
      expiryState
    ]);

    useEffect(() => {
      clearInterval(timerRef.current);

      timerRef.current = setInterval(
        () => setSeconds(sec => sec - countdownIncrement),
        countdownIncrement * 1000
      );

      return () => {
        clearInterval(timerRef.current);
      };
    }, [countdownIncrement]);

    const handleCloseWrapper = () => {
      if (modalToReopen && modalToReopen.hasChildNodes()) {
        reopenModal(modalToReopen);
      }

      focusedElBeforeOpen.current.focus();
      handleCloseModal();
    };

    const onScreenTimeout = () => {
      if (isOpenBanking) {
        return (
          <div
            aria-atomic="true"
            aria-live="polite"
            role="alert"
            className="inline-select-form timeout-onscreen__openBanking ln-c-field-info--error"
          >
            <span>Session will end in {seconds} seconds</span>
          </div>
        );
      }

      return <TimeOutWarning seconds={seconds} />;
    };

    const openModals = () =>
      document.querySelectorAll(
        '.ln-c-modal.is-open:not([id="timeout-modal"])'
      );

    const closeOpenModal = modalEl => {
      modalEl.classList.remove("is-open");
      modalEl.setAttribute("hidden", true);
    };

    const reopenModal = modalEl => {
      modalEl.classList.add("is-open");
      modalEl.removeAttribute("hidden");
    };

    if (openModal) {
      openModals().forEach(modal => {
        closeOpenModal(modal);
        setModalToReopen(modal);
      });
    }

    useModalFocusHook("timeout-modal", openModal);

    if (!modalClosed) {
      return (
        <div>
          <SbInactivityTImeoutModal
            openModal={openModal}
            handleCloseWrapper={handleCloseWrapper}
            seconds={seconds}
            isOpenBanking={isOpenBanking}
          />
        </div>
      );
    }
    return onScreenTimeout();
  }
);

Timeout.propTypes = {
  handleTimeout: PropTypes.func,
  tokenExpiryTimestamp: PropTypes.number,
  isOpenBanking: PropTypes.bool
};

Timeout.defaultProps = {
  handleTimeout: null,
  tokenExpiryTimestamp: Date.now() + FIVE_MINUTES_TIMEOUT_IN_SECONDS,
  isOpenBanking: false
};

export default Timeout;
