import React from "react";
import PropTypes from "prop-types";
import { OutlinedButton } from "@jsluna/react";
import "../styles/buttons.scss";

export const CancelButton = ({ onClick, text }) => (
  <div className="sb-btn-wrapper">
    <OutlinedButton
      className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center ln-c-button--full 
      button-text"
      id="cancel-button"
      onClick={() => onClick()}
    >
      {text}
    </OutlinedButton>
  </div>
);

CancelButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

CancelButton.defaultProps = {
  text: "Cancel"
};
