import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, ProgressSpinner, Section, StatusCard } from "@jsluna/react";

import { AccountInfo } from "./AccountInfo";
import { CancelButton } from "../CancelButton";
import SubmitButton from "../SubmitButton";
import PermissionsList from "./PermissionsList";
import OBForm from "../ob-design/OBForm";
import { ERROR_PAGE_NO_ACCOUNTS_INFO } from "../constants";
import { errors } from "../../services/constants";
import {
  groupPermissionsByCategory,
  hasConfirmationOfFundsPermissions
} from "../../services/Consent/permissions";
import "./AccountSelection.scss";

let isMounted = false;

function renderError(error, id) {
  if (!error) return null;

  return (
    <StatusCard id={`${id}-error-card`} status="danger">
      {error}
    </StatusCard>
  );
}

export const PermissionsMessage = ({
  tppName,
  consented,
  accountList,
  confirmationOfFunds
}) => {
  const getAccountsDisplay = () => {
    let accountDisplay = "";

    if (accountList && accountList.length > 0) {
      for (let i = 0; i < accountList.length; i++) {
        accountDisplay += `xxxx xxxx xxxx ${accountList[i].accountDisplay}`;

        if (i === 0 && accountList.length > 1) {
          accountDisplay += " and ";
        }
      }
    }
    return accountDisplay;
  };

  if (consented && confirmationOfFunds) {
    const paragraphOneMessage = `We need to refresh the access to ${tppName} every 90 days. Please check the details below and confirm if you are happy to continue to permit response to funds availability checks on your Credit Card account ending ${getAccountsDisplay()}.`;
    const paragraphTwoMessage = `We won't share your account balance or any other personal information with ${tppName}. We'll just answer "Yes" or "No" to their requests.`;

    return (
      <div
        id="confirm-access__info"
        aria-label={`${paragraphOneMessage} ${paragraphTwoMessage}`}
      >
        <p>{paragraphOneMessage}</p>
        <p>{paragraphTwoMessage}</p>
      </div>
    );
  }

  if (consented) {
    const paragraphMessage = `We need to refresh the access to ${tppName} every 90 days. Please check the access details below and confirm if you are happy to continue to permit access to your Credit Card Account(s) ending ${getAccountsDisplay()}.`;

    return (
      <p id="confirm-access__info" aria-label={paragraphMessage}>
        {paragraphMessage}
      </p>
    );
  }

  if (confirmationOfFunds) {
    const paragraphOneMessage = `${tppName} would like us to respond to funds availability checks on your credit card account.`;
    const paragraphTwoMessage = `We won't share your account balance or any other personal information with ${tppName}. We'll just answer "Yes" or "No" to their requests.`;

    return (
      <div
        id="confirm-access__info"
        aria-label={`${paragraphOneMessage} ${paragraphOneMessage}`}
      >
        <p>{paragraphOneMessage}</p>
        <p>{paragraphTwoMessage}</p>
      </div>
    );
  }

  const paragraphMessage = `${tppName} has requested the following information about your account(s).`;
  return (
    <p id="confirm-access__info" aria-label={paragraphMessage}>
      {paragraphMessage}
    </p>
  );
};

const accountShape = PropTypes.shape({
  accountDisplay: PropTypes.string,
  ProductAccountId: PropTypes.string
});

PermissionsMessage.propTypes = {
  tppName: PropTypes.string,
  consented: PropTypes.bool,
  accountList: PropTypes.arrayOf(accountShape),
  confirmationOfFunds: PropTypes.bool
};

PermissionsMessage.defaultProps = {
  tppName: "The provider of the service you're using",
  consented: false,
  accountList: [],
  confirmationOfFunds: false
};

export class AccountSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAll: false,
      accountList: null,
      selectedAccountIds: null,
      accountsListLoading: false,
      permissionsListLoading: false,
      error: {
        fatal: null,
        accountsList: null,
        permissionsList: null,
        cancelConsent: null
      },
      formSubmissionError: null,
      permissions: [],
      fromDateTime: null,
      formLoading: false,
      consented: false,
      confirmationOfFunds: false
    };
  }

  async componentDidMount() {
    isMounted = true;

    try {
      await this.getPermissions();
      await this.getAccounts();

      const { confirmationOfFunds, accountList, consented } = this.state;

      if (confirmationOfFunds && consented && accountList.length > 1) {
        // confirmation of funds consent cannot be granted / refreshed
        // on more than one account
        this.props.onError();
      }
    } catch (e) {
      this.setState(state => ({
        error: {
          ...state.error,
          fatal: e.message
        }
      }));
    }
  }

  componentWillUnmount() {
    isMounted = false;
  }

  async getAccounts() {
    if (!isMounted) return;

    this.setState({ accountsListLoading: true });

    const result = await this.props.getAccounts();

    this.setState({ accountsListLoading: false });

    const { accountList, success, error } = result;

    if (!success) {
      this.setState(state => ({
        error: { ...state.error, accountsList: error }
      }));
      return;
    }

    if (accountList.length === 0) {
      this.props.onError(ERROR_PAGE_NO_ACCOUNTS_INFO);
      return;
    }

    const selectedAccountIds = accountList.reduce(
      (acc, cur) => ({ ...acc, [cur.accountId]: false }),
      {}
    );

    this.setState({
      accountList,
      accountsListLoading: false,
      selectedAccountIds
    });
  }

  async getPermissions() {
    this.setState({ permissionsListLoading: true });

    const result = await this.props.getIntent();

    // if accounts are returned this means that the customer has already consented previously. Documented here at Step 25c https://sb97digital.atlassian.net/wiki/spaces/IAM/pages/483196998/Authentication+Consent+API+-+SPA
    if (result.Accounts && result.Accounts.length !== 0) {
      this.setState({ consented: true });
    }

    this.setState({ permissionsListLoading: false });

    if (result.success) {
      this.setState({
        permissions: result.Permissions,
        fromDateTime: result.TransactionFromDateTime,
        toDateTime: result.TransactionToDateTime,
        confirmationOfFunds: hasConfirmationOfFundsPermissions(
          result.Permissions
        )
      });
    } else {
      this.setState(state => ({
        error: {
          ...state.error,
          permissionsList: result.error
        }
      }));
    }
  }

  accountSelected = accountId => {
    this.setState(state => {
      const { confirmationOfFunds, selectedAccountIds, accountList } = state;

      const currentSelection = selectedAccountIds;
      const accountSelected = currentSelection[accountId];
      const newIds = { ...currentSelection };

      if (confirmationOfFunds) {
        for (let i = 0; i < accountList.length; i++) {
          const account = accountList[i];
          newIds[account.ProductAccountId] =
            account.ProductAccountId === accountId;
        }
      } else {
        newIds[accountId] = !accountSelected;
      }

      return { selectedAccountIds: newIds };
    });
  };

  confirmAccount = async () => {
    try {
      const accounts = this.state.selectedAccountIds;
      this.setState({ formLoading: true });

      let chosenAccounts;

      if (accounts) {
        chosenAccounts = Object.keys(accounts).filter(key => accounts[key]);
      }

      const complete = await this.props.onSubmit(chosenAccounts);
      if (!complete) {
        this.setState({ formLoading: false });
      }
    } catch (e) {
      console.log("Couldn't confirm consent");
      console.log(e);

      this.setState({
        formLoading: false,
        formSubmissionError: errors.CONFIRM_ACCESS_FAILED
      });
    }
  };

  cancelAndRejectConsent = async () => {
    try {
      this.setState({ disableAll: true });
      const complete = await this.props.onCancel();
      if (!complete) {
        this.setState({ disableAll: false });
      }
    } catch (e) {
      console.log("Couldn't cancel consent");
      console.error(e);

      this.setState(state => ({
        disableAll: false,
        error: {
          ...state.error,
          cancelConsent: errors.UNEXPECTED_ERROR_MESSAGE
        }
      }));
    }
  };

  confirmConsentEnabled = () => {
    const { disableAll, selectedAccountIds, error } = this.state;

    // Disable if there are no accounts selected
    // or disableAll is true
    if (
      disableAll ||
      !selectedAccountIds ||
      Object.keys(selectedAccountIds).every(key => !selectedAccountIds[key])
    ) {
      return false;
    }

    // disable if any errors
    return Object.keys(error).every(key => !error[key]);
  };

  submitForm = async event => {
    event.preventDefault();
    const accounts = this.state.selectedAccountIds;
    const chosenAccounts = Object.keys(accounts).filter(key => accounts[key]);

    if (chosenAccounts.length > 0 || this.state.consented) {
      this.confirmAccount();
    } else {
      this.cancelAndRejectConsent();
    }
  };

  renderPermissionsList() {
    const {
      error,
      permissionsListLoading,
      permissions,
      fromDateTime,
      toDateTime,
      confirmationOfFunds
    } = this.state;
    if (
      permissionsListLoading ||
      error.permissionsList ||
      confirmationOfFunds
    ) {
      return null;
    }

    const categories = groupPermissionsByCategory(permissions);

    return (
      <Card id="permission-list-container">
        {categories.map(category => (
          <PermissionsList
            fromDate={fromDateTime}
            toDate={toDateTime}
            key={`permissions-list-${category.name}`}
            permissions={category.permissions}
            title={category.display}
          />
        ))}
      </Card>
    );
  }

  renderAccountsList() {
    const {
      accountList,
      disableAll,
      consented,
      confirmationOfFunds
    } = this.state;

    if (accountList && accountList.length > 0 && !consented) {
      return (
        <AccountInfo
          disabled={disableAll}
          accountList={accountList}
          onSelected={this.accountSelected}
          confirmationOfFunds={confirmationOfFunds}
        />
      );
    }
    return null;
  }

  render() {
    const {
      disableAll,
      error,
      permissionsListLoading,
      accountsListLoading,
      formLoading,
      formSubmissionError,
      consented,
      accountList,
      confirmationOfFunds
    } = this.state;

    if (error.fatal) {
      return (
        <div className="account-list-container">
          <Section>{renderError(error.fatal)}</Section>
        </div>
      );
    }

    if (permissionsListLoading || accountsListLoading) {
      return (
        <Section id="confirm-access__loading">
          <div className="ln-u-text-align-center">
            <ProgressSpinner size="large" />
            <h2>Checking accounts...</h2>
          </div>
        </Section>
      );
    }
    const submitFormEnabled = this.confirmConsentEnabled() && !formLoading;

    const cancelButton = (
      <CancelButton
        enabled={!disableAll}
        onClick={this.cancelAndRejectConsent}
      />
    );

    const submitButton = (
      <SubmitButton
        disabled={!submitFormEnabled && !consented}
        text="Confirm Access"
        progressMessage="Confirming..."
        loading={formLoading}
      />
    );

    return (
      <div id="confirm-access__container">
        <div
          id="confirm-access__accessibility-message-placeholder"
          role="status"
          aria-live="polite"
          className="ln-u-visually-hidden"
          aria-label="Navigated to account summary page"
        />

        <Section id="confirm-access__intro-section">
          <Card id="confirm-access__intro-card">
            <h2 id="confirm-access__title">
              {!consented || confirmationOfFunds ? "Summary" : "Confirm Access"}
            </h2>
            <PermissionsMessage
              tppName={this.props.tppName}
              accountList={accountList}
              consented={consented}
              confirmationOfFunds={confirmationOfFunds}
            />
            {renderError(error.permissionsList, "permission-list")}
          </Card>
          {this.renderPermissionsList()}
          <Card id="account-card">
            <div>
              <strong id="confirm-access__withdrawal-message">
                {this.props.withdrawalMessage}
              </strong>
            </div>
            {consented && (
              <div>
                <p id="account-card__margin">
                  {confirmationOfFunds
                    ? "To permit continued consent to funds availability check please 'Confirm'."
                    : "To permit continued access please confirm access."}
                </p>
              </div>
            )}
            <div>
              {renderError(error.accountsList, "account-list")}
              <OBForm
                cancelButton={cancelButton}
                submitButton={submitButton}
                error={formSubmissionError}
                onSubmit={this.submitForm}
              >
                {this.renderAccountsList()}
              </OBForm>
            </div>
          </Card>
        </Section>
      </div>
    );
  }
}

AccountSelection.propTypes = {
  // eslint-disable-next-line react/require-default-props
  getAccounts: PropTypes.func.isRequired,
  getIntent: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  withdrawalMessage: PropTypes.string.isRequired,
  tppName: PropTypes.string
};

AccountSelection.defaultProps = {
  tppName: "The provider of the service you're using"
};
