import React from "react";
import { Container, PageHeader } from "@jsluna/react";
import { SainsburysBank, withLabel } from "@jsluna/images";

import { TitleHeader } from "./TitleHeader";
import { StepIndicator } from "./StepIndicator";
import "./Header.scss";

const SBLogo = withLabel(SainsburysBank);

export default () => (
  <PageHeader>
    <Container className="container">
      <h1 aria-labelledby="OBHeader__header-description">
        <span
          className="ln-u-visually-hidden"
          id="OBHeader__header-description"
        >
          Sainsbury&apos;s Bank: Confirm Identity
        </span>
        <SBLogo label="Sainsbury's Bank logo" width="195px" height="42px" />
      </h1>
    </Container>
    <Container className="container ln-u-text-align-center">
      <TitleHeader />
      <StepIndicator />
      <div className="divider-line">
        <hr />
      </div>
    </Container>
  </PageHeader>
);
