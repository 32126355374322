import React, { Component } from "react";
import PropTypes from "prop-types";
import { ErrorCircle } from "@jsluna/icons";
import SubmitButton from "../sb-design/SubmitButton";
import PageHeading from "../sb-design/PageHeading";
import { addOrdinal } from "../../shared/utils/addOrdinal";
import SBForm from "../sb-design/SBForm";
import { REQUESTED_PIN_DIGITS_SCHEMA } from "./PinState";

import { OTP_REGEX, OTP_MAX_LENGTH } from "../../services/validationHelpers";
import { PAGE_CONSTS, FORM_VALIDATION_MESSAGES } from "../../shared/constants";
import { generateForgottenUrlSuite } from "../../services/fisUtilities";
import { errors as errorConsts } from "../../services/constants";

import Pin from "./pin/Pin";
import Password from "./password/Password";
import OneTimePasscode from "./one-time-passcode/OneTimePasscode";
import OPTRequestModal from "./modals/OPTRequestModal";

const validatePassword = password => {
  return !!password;
};

const pinValid = pin => {
  return pin.every(val => val !== "" && val != null);
};

const otpValid = otp => {
  return new RegExp(`^${OTP_REGEX}{${OTP_MAX_LENGTH}}$`).test(otp);
};

const validateSecurityCheck = (includeOTPCheck, password, pin, otp = null) => {
  const validateMandatoryFields = () =>
    validatePassword(password) && pinValid(pin);

  return includeOTPCheck
    ? otpValid(otp) && validateMandatoryFields()
    : validateMandatoryFields();
};

const validatePasswordAndGetError = password => {
  return !validatePassword(password)
    ? "Please enter your password."
    : undefined;
};

export class SecurityCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: [null, null, null],
      errorMessage: null,
      formLoading: false,
      otp: "",
      password: "",
      errors: {},
      showMaxAttemptsOTP: false,
      showOTPRequestModal: false,
      otpRequestCount: 0
    };
    this.submitRef = React.createRef();
    this.onRequestOTPHandler = this.onRequestOTPHandler.bind(this);
  }

  onPasswordChange = ({ target }) => {
    this.setState(state => {
      const newErrors = { ...state.errors };

      newErrors.password = validatePasswordAndGetError(target.value);

      return {
        password: target.value,
        errors: newErrors
      };
    });
  };

  onOTPBlur = ({ target }) => {
    const isOTPValid = new RegExp(`^${OTP_REGEX}{${OTP_MAX_LENGTH}}$`).test(
      target.value
    );
    let otpError = null;
    if (!target.value) {
      otpError = FORM_VALIDATION_MESSAGES.OTP_FIELD_EMPTY;
    } else if (!isOTPValid) {
      otpError = FORM_VALIDATION_MESSAGES.OTP_INCORRECT_LENGTH;
    }

    this.setState(state => {
      const newErrors = { ...state.errors };
      newErrors.otp = otpError;

      return { errors: newErrors };
    });
  };

  onPasswordBlur = ({ target }) => {
    this.setState(state => {
      const newErrors = { ...state.errors };

      newErrors.password = validatePasswordAndGetError(target.value);

      return { errors: newErrors };
    });
  };

  onOTPChange = ({ target }) => {
    if (!target.value) {
      this.setState(() => ({ otp: target.value }));
      return;
    }
    const isInputValid = new RegExp(
      `^${OTP_REGEX}{${target.value.length}}$`
    ).test(target.value);
    if (!isInputValid) return;
    this.setState(() => ({ otp: target.value }));
  };

  pinDigitChanged = async (value, index) => {
    let newValue = value;
    if (newValue) {
      newValue = newValue.charAt(0);
      if (isNaN(parseInt(newValue, 10))) {
        return;
      }
    }
    await this.setState(state => {
      const newPin = [...state.pin];
      newPin[index] = newValue;
      return { pin: newPin };
    });
  };

  otpTooManyAttempts = () => {
    this.setState(prevState => {
      return {
        showMaxAttemptsOTP: !prevState.showMaxAttemptsOTP
      };
    });
  };

  submitForm = async event => {
    event.preventDefault();
    try {
      this.setState({ formLoading: true });
      const [complete, error] = await this.props.onSubmit(
        this.state.pin,
        this.state.password,
        this.state.otp
      );
      if (!complete) {
        this.setState({
          formLoading: false,
          errorMessage: error,
          pin: [null, null, null],
          password: "",
          otp: ""
        });
      }
    } catch (e) {
      console.log("Couldn't request OTP");
      console.error(e);

      this.setState({
        formLoading: false,
        errorMessage: errorConsts.CREDENTIALS_UNEXPECTED_ERROR_MESSAGE,
        pin: [null, null, null],
        password: "",
        otp: ""
      });
    }
  };

  onRequestOTPHandler = e => {
    const { otpRequestCount } = this.state;
    if (otpRequestCount >= PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS) {
      this.setState({
        showMaxAttemptsOTP: true
      });
      this.props.history.replace("/max-otp-attempts");
      return;
    }
    this.requestOTP();
  };

  requestOTP = async () => {
    const { otpRequestCount } = this.state;
    if (otpRequestCount >= PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS) {
      this.setState({
        showMaxAttemptsOTP: true
      });
    } else {
      try {
        let requestCount = otpRequestCount;
        this.setState({
          showOTPRequestModal: true,
          otpRequestCount:
            requestCount >= PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS
              ? requestCount
              : (requestCount += 1)
        });
        const [complete, error] = await this.props.requestOTP();
        if (complete) {
          this.setState({
            showOTPRequestModal: false,
            errorMessage: error,
            pin: [null, null, null],
            password: "",
            otp: "",
            errors: {}
          });
        }
      } catch (e) {
        console.log("Couldn't check pin");
        console.error(e);

        this.setState({
          showOTPRequestModal: false,
          errorMessage: e.message,
          pin: [null, null, null],
          password: "",
          otp: ""
        });
      }
    }
  };

  render() {
    const {
      digitsRequired,
      gotoParam,
      includeOTPCheck,
      phoneDigits
    } = this.props;
    const {
      pin,
      formLoading,
      otp,
      password,
      errorMessage,
      otpRequestCount,
      showOTPRequestModal,
      errors
    } = this.state;

    const remainingOtpWarning = () => {
      const remainingOtps =
        PAGE_CONSTS.SECURITY_CHECK_MAX_OTP_REQUESTS - otpRequestCount;

      if (remainingOtps === 1 || remainingOtps === 2) {
        return (
          <span>
            <ErrorCircle aria-label="Warning" role="img" />
            <span className="ln-u-visually-hidden">Code sent</span>
            <span>
              {`We can only send you ${remainingOtps} more code${
                remainingOtps > 1 ? "s" : ""
              }.`}
            </span>
          </span>
        );
      }

      return <span className="ln-u-visually-hidden">Code sent</span>;
    };

    const submitFormEnabled =
      validateSecurityCheck(includeOTPCheck, password, pin, otp) &&
      !formLoading;
    const submitButton = (
      <SubmitButton
        submitRef={el => {
          this.submitRef = el;
        }}
        disabled={!submitFormEnabled}
        progressMessage="Verifying..."
        loading={formLoading}
        text="Login"
      />
    );

    const { FORGOTTEN_CREDENTIALS } = generateForgottenUrlSuite(gotoParam);
    const otpLinks = [
      {
        text: "Send another code",
        type: "button",
        onClick: this.onRequestOTPHandler
      }
    ];

    const passwordLinks = [
      {
        href: FORGOTTEN_CREDENTIALS,
        type: "link",
        text: "Forgotten your password?",
        external: true
      }
    ];

    const pinLinks = [
      {
        href: FORGOTTEN_CREDENTIALS,
        type: "link",
        text: "Forgotten your online PIN?",
        external: true
      }
    ];

    const pinDigits = digitsRequired.map((digit, index) => ({
      label: `Online pin ${addOrdinal(digit)} digit`,
      placeHolder: addOrdinal(digit),
      name: `pin-${digit}`,
      value: pin[index] || "",
      password: true
    }));

    return (
      <SBForm
        submitButton={submitButton}
        error={!formLoading && errorMessage}
        onSubmit={this.submitForm}
      >
        <OPTRequestModal
          open={showOTPRequestModal}
          handleClose={() => this.setState({ showOTPRequestModal: false })}
        />
        <PageHeading>Security Check</PageHeading>

        {includeOTPCheck && (
          <OneTimePasscode
            phoneNumber={phoneDigits.join("")}
            onChange={this.onOTPChange}
            onBlur={this.onOTPBlur}
            warning={remainingOtpWarning()}
            value={otp}
            error={errors.otp}
            links={otpLinks}
          />
        )}

        <Password
          onChange={this.onPasswordChange}
          onBlur={this.onPasswordBlur}
          value={password}
          error={errors.password}
          links={passwordLinks}
        />

        <Pin
          onChange={this.pinDigitChanged}
          digits={pinDigits}
          links={pinLinks}
          onEnterKey={event => {
            if (submitFormEnabled) this.submitForm(event);
          }}
        />
      </SBForm>
    );
  }
}

// eslint-disable-next-line consistent-return
function requestedPinDigitsValidator(props, propName) {
  try {
    REQUESTED_PIN_DIGITS_SCHEMA.validateSync(props[propName]);
  } catch (e) {
    console.log(e);
    const message = JSON.stringify(e.errors);
    return new Error(message);
  }
}

SecurityCheck.propTypes = {
  gotoParam: PropTypes.string.isRequired,
  includeOTPCheck: PropTypes.bool.isRequired,
  phoneDigits: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  digitsRequired: requestedPinDigitsValidator,
  requestOTP: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
};

SecurityCheck.defaultProps = {
  requestOTP: undefined
};
