import React, { Component } from "react";
import PropTypes from "prop-types";
import { CheckboxField, RadioButtonField } from "@jsluna/react";

import "./AccountInfo.scss";

export class AccountInfo extends Component {
  onChange = e => {
    const { onSelected } = this.props;
    onSelected(e.target.value);
  };

  render() {
    const { accountList, disabled, confirmationOfFunds } = this.props;
    const options = accountList.map(account => {
      return {
        label: `Credit Card - xxxx xxxx xxxx ${account.accountDisplay}`,
        value: account.ProductAccountId,
        "aria-label": `Credit Card - Ending in ${account.accountDisplay
          .split("")
          .join(" ")}`
      };
    });

    return confirmationOfFunds ? (
      <RadioButtonField
        className="account-list-selector"
        disabled={disabled}
        fullWidth
        onChange={e => this.onChange(e)}
        options={options}
        name="account-list-selector"
        label="To proceed, please select the account you want to allow us to respond to funds availability checks and 'Confirm':"
      />
    ) : (
      <CheckboxField
        className="account-list-selector"
        disabled={disabled}
        fullWidth
        onChange={e => this.onChange(e)}
        options={options}
        name="account-list-selector"
        label="To proceed, please select the account(s) you want to share and 'Confirm access':"
      />
    );
  }
}

AccountInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  confirmationOfFunds: PropTypes.bool.isRequired
};

AccountInfo.defaultProps = {
  disabled: false
};
