import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label } from "@jsluna/react";
import ToolTip from "../../sb-design/tooltip/Tooltip";
import MultiDigitInput, {
  multiDigitInputPartType
} from "../../sb-design/multi-digit-input/MultiDigitInput";
import LinkList, { linkListType } from "../../sb-design/link-list/LinkList";
import "./Pin.scss";

const Pin = ({ onChange, digits, links, onEnterKey }) => {
  const tooltip =
    "This 6 digit code was created by you as part of the registration process. If you have forgotten this PIN you can follow the forgotten pin process to reset it.";

  return (
    <FormGroup name="pin" className="sb-pin" aria-label="Online PIN">
      {inputProps => (
        <>
          <ToolTip message={tooltip}>
            <Label htmlFor={inputProps.name}>Online PIN</Label>
          </ToolTip>

          <div>
            Please enter the {digits[0].placeHolder}, {digits[1].placeHolder}{" "}
            and {digits[2].placeHolder} digits of your online PIN.
          </div>

          <MultiDigitInput
            parts={digits}
            onChange={onChange}
            onEnterKey={onEnterKey}
          />
          <LinkList links={links} />
        </>
      )}
    </FormGroup>
  );
};

Pin.propTypes = {
  onChange: PropTypes.func.isRequired,
  digits: multiDigitInputPartType.isRequired,
  links: linkListType.isRequired,
  onEnterKey: PropTypes.func
};

Pin.defaultProps = {
  onEnterKey: () => {}
};

export default Pin;
