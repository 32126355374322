import React from "react";

import ContactUsButton from "../ob-design/ContactUsButton";
import FAQButton from "../ob-design/FAQButton";

import "./BlockedButtons.scss";

const BlockedButtons = () => {
  return (
    <div className="BlockedButtons">
      <ContactUsButton ariaLabel="See our contact details (opens in new window)" />
      <FAQButton />
    </div>
  );
};

export default BlockedButtons;
