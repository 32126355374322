import queryString from "query-string";
import {
  extractGoto,
  getRedirectURIs,
  setPromptStatus
} from "./services/Authentication/iamUtils";
import { parseConsentJwtString } from "./services/Consent/Token";
import { SB_CUSTOMER_ACCOUNT_STATUSES } from "./services/constants";

// FIS journey
export const redirectToSBAuthenticatePage = (
  history,
  iamRedirectUri,
  phoneDigits,
  requestedPinDigits,
  responseTemplate,
  gotoParam,
  username
) =>
  history.push("/authenticate", {
    iamRedirectUri,
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    gotoParam,
    username
  });

// For use with SB Login journey
export const decodeSBAuthenticationState = location => {
  const {
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    iamRedirectUri,
    tokenExpiryTimestamp,
    gotoParam,
    username
  } = location.state;
  if (
    !phoneDigits ||
    !requestedPinDigits ||
    !responseTemplate ||
    !iamRedirectUri ||
    !gotoParam ||
    !username
  ) {
    throw new Error("Missing required location state for /authenticate");
  }
  return {
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    iamRedirectUri,
    tokenExpiryTimestamp,
    gotoParam,
    username
  };
};

// Re-created login url, including the original goto param
export const redirectToLoginPage = (history, gotoParam) => {
  const loginBasePath = "/login";
  const login = gotoParam
    ? `${loginBasePath}?goto=${encodeURIComponent(gotoParam)}`
    : loginBasePath;
  history.push(login);
};

// Open Banking
export const redirectToPinPage = (
  history,
  tppRedirectUri,
  iamRedirectUri,
  phoneDigits,
  requestedPinDigits,
  responseTemplate,
  username
) =>
  history.push("/authenticate", {
    tppRedirectUri,
    iamRedirectUri,
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    username
  });

// For use with OB Login journey
export const decodePinLocationState = location => {
  const {
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    tppRedirectUri,
    iamRedirectUri,
    username
  } = location.state;
  if (
    !phoneDigits ||
    !requestedPinDigits ||
    !responseTemplate ||
    !tppRedirectUri ||
    !iamRedirectUri ||
    !username
  ) {
    throw new Error("Missing required location state for /authenticate");
  }
  return {
    phoneDigits,
    requestedPinDigits,
    responseTemplate,
    tppRedirectUri,
    iamRedirectUri,
    username
  };
};

export const redirectToErrorPage = (
  history,
  tppRedirectUri = undefined,
  gotoParam = undefined,
  accountStatus = undefined,
  customError = undefined
) =>
  history.replace("/error", {
    tppRedirectUri,
    gotoParam,
    accountStatus,
    customError
  });

export const redirectToSBBlockedPage = history =>
  history.push("/error", {
    accountStatus: SB_CUSTOMER_ACCOUNT_STATUSES.BLOCKED
  });

export const redirectToSBSuspendedPage = (history, gotoParam) =>
  history.push("/error", {
    gotoParam,
    accountStatus: SB_CUSTOMER_ACCOUNT_STATUSES.SUSPENDED
  });

export const redirectToSBBlockedFraudPage = history =>
  history.push("/error", {
    accountStatus: SB_CUSTOMER_ACCOUNT_STATUSES.FRAUD_BLOCKED
  });

export const redirectToSBMaxOPTAttemptsPage = history =>
  history.push("/max-otp-attempts");

export const decodeSBErrorLocationState = location => {
  if (!location || !location.state) {
    return {
      accountStatus: undefined,
      gotoParam: undefined
    };
  }
  const { gotoParam, accountStatus } = location.state;
  if (!gotoParam && accountStatus === SB_CUSTOMER_ACCOUNT_STATUSES.SUSPENDED) {
    throw new Error("no request query string parameter found on goto URL");
  }
  return {
    gotoParam,
    accountStatus
  };
};

export const decodeErrorLocationState = location => {
  const {
    tppRedirectUri,
    gotoParam,
    accountStatus,
    customError
  } = location.state;
  return {
    tppRedirectUri,
    gotoParam,
    accountStatus,
    customError
  };
};

export const redirectToCancelPage = (history, tppRedirectUri) =>
  history.push("/cancel", { tppRedirectUri });

export const redirectToCancelPageWithRedirectInfo = (
  history,
  redirectUrl,
  redirectToken
) =>
  history.push("/cancel", {
    consentRedirectUrl: redirectUrl,
    consentRedirectToken: redirectToken
  });

export const decodeCancelLocationState = location => {
  const {
    tppRedirectUri,
    title,
    description,
    consentRedirectUrl,
    consentRedirectToken
  } = location.state;
  const redirecting = consentRedirectUrl || consentRedirectToken;
  if (redirecting && (!consentRedirectUrl || !consentRedirectToken)) {
    throw new Error(
      "Missing required location state for /cancel with consent redirect"
    );
  }
  return {
    title,
    description,
    tppRedirectUri,
    consentRedirect: redirecting,
    redirectUrl: consentRedirectUrl,
    redirectToken: consentRedirectToken
  };
};

export const redirectToConfirmPage = (history, redirectUrl, redirectToken) =>
  history.push("/confirm", {
    consentRedirectUrl: redirectUrl,
    consentRedirectToken: redirectToken
  });

export const decodeConfirmLocationState = location => {
  const {
    title,
    description,
    consentRedirectUrl,
    consentRedirectToken
  } = location.state;
  const redirecting = consentRedirectUrl !== "" || consentRedirectToken !== "";
  if (redirecting && (!consentRedirectUrl || !consentRedirectToken)) {
    throw new Error(
      "Missing required location state for /confirm with redirect"
    );
  }
  return {
    redirecting,
    title,
    description,
    redirectUrl: consentRedirectUrl,
    redirectToken: consentRedirectToken
  };
};

export const getIamRedirectUrl = location => extractGoto(location.search);

export const validateGotoParam = (history, gotoParam) => {
  const checkGoto = gotoParam
    ? gotoParam.includes(process.env.REACT_APP_IAM_API_HOSTNAME)
    : false;

  if (!checkGoto) {
    redirectToErrorPage(history);
  }
};

export const getValidateUrlParams = location =>
  getRedirectURIs(location.search);

/**
 * Step 9b - Handles redirection to IAM
 * Ref 1: https://sb97digital.atlassian.net/wiki/spaces/IAM/pages/704119347/SB+Web+Authentication+SPA+Integration+-+Online+Banking+Login+flow
 * @param {String}          iamRedirectUri        IAM redirect URI extracted from goto param
 */
export const redirectToIam = iamRedirectUri =>
  window.location.replace(setPromptStatus(iamRedirectUri));

export const getConfirmationUrlParams = location => {
  const consentRequest = queryString.parse(location.search).consent_request;
  const token = parseConsentJwtString(consentRequest);
  return { token };
};

export const redirectAfterTimeout = (tppRedirectUri, timeout) => {
  setTimeout(() => window.location.replace(tppRedirectUri), timeout);
};

export const handleStatusRedirection = (
  accountStatus,
  history,
  redirectUri = undefined,
  title = null,
  description = null
) => {
  switch (accountStatus) {
    case 0: {
      return redirectToSBBlockedPage(history);
    }
    case 1: {
      return redirectToSBSuspendedPage(history, redirectUri);
    }
    case 3: {
      return redirectToSBBlockedFraudPage(history);
    }
    default: {
      return redirectToErrorPage(history, redirectUri);
    }
  }
};
