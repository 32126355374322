import React from "react";
import { withRouter } from "react-router-dom";
import { FinalPage } from "../FinalPage";
import { CONFIRMATION_PAGE_DEFAULT_INFO, TIMEOUTS } from "../constants";
import { CompleteConsentAndRedirect } from "../CompleteConsentAndRedirect";
import { decodeConfirmLocationState } from "../../locationManagement";

const ConfirmationPage = withRouter(({ location }) => {
  const {
    redirecting,
    title,
    description,
    redirectUrl,
    redirectToken
  } = decodeConfirmLocationState(location);
  return (
    <FinalPage
      title={title || CONFIRMATION_PAGE_DEFAULT_INFO.title}
      description={description || CONFIRMATION_PAGE_DEFAULT_INFO.description}
      loading={redirecting}
    >
      {// Submit form if user successfully consenting account
      redirecting && (
        <CompleteConsentAndRedirect
          url={redirectUrl}
          token={redirectToken}
          delayInMilliseconds={TIMEOUTS.CONFIRMATION_TIMEOUT}
        />
      )}
    </FinalPage>
  );
});

export default ConfirmationPage;
