import React from "react";
import PropTypes from "prop-types";
import { ErrorCircle, NewWindow } from "@jsluna/icons";
import {
  Modal,
  ModalHeading,
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper
} from "@jsluna/react";
import { EXTERNAL_RESOURCE_URLS } from "../../../shared/constants";

const SBMobileLandlineModal = ({
  isOpen,
  handleClose,
  clickButton,
  title,
  body,
  buttonText
}) => {
  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <ModalHeading element="h3">
        <ErrorCircle className="error-circle" />
        {title}
      </ModalHeading>
      <p className="register-body-0">{body}</p>
      <ButtonGroupWrapper className="sb-modal-buttons">
        <FilledButton
          fullwidth
          element="a"
          onClick={() => clickButton()}
          target="_blank"
          className="sb-modal-buttons__exit"
        >
          {buttonText}
        </FilledButton>
        <p className="register-body-1">Any other questions ?</p>
        <OutlinedButton
          fullwidth
          element="a"
          href={EXTERNAL_RESOURCE_URLS.FAQS_OTP}
          target="_blank"
          className="sb-modal-buttons__contact_us"
        >
          FAQs
          <NewWindow />
        </OutlinedButton>
      </ButtonGroupWrapper>
    </Modal>
  );
};

SBMobileLandlineModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  clickButton: PropTypes.func,
  isOpen: PropTypes.bool
};

SBMobileLandlineModal.defaultProps = {
  handleClose: null,
  clickButton: null,
  isOpen: false
};

export default SBMobileLandlineModal;
