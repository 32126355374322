import React from "react";
import propTypes from "prop-types";

import LinkButton from "./LinkButton";
import { BUTTON_VARIANTS } from "../constants";

const FilledLinkButton = ({
  id,
  ariaLabel,
  href,
  isExternal,
  children,
  ...rest
}) => {
  return (
    <LinkButton
      id={id}
      ariaLabel={ariaLabel}
      href={href}
      isExternal={isExternal}
      variant={BUTTON_VARIANTS.FILLED}
      {...rest}
    >
      {children}
    </LinkButton>
  );
};

FilledLinkButton.propTypes = {
  id: propTypes.string.isRequired,
  ariaLabel: propTypes.string,
  href: propTypes.string.isRequired,
  isExternal: propTypes.bool,
  children: propTypes.node.isRequired
};

FilledLinkButton.defaultProps = {
  ariaLabel: undefined,
  isExternal: false
};

export default FilledLinkButton;
