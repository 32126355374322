import React from "react";
import "./Banner.scss";
import TooltipIcon from "../ob-design/TooltipIcon";

const Banner = () => (
  <aside
    aria-label="Information about why we need you to confirm your identity"
    className="Banner"
  >
    <div className="Banner__column">
      <TooltipIcon tabIndex={-1} type="info" label="Information icon" />
    </div>
    <div className="Banner__column">
      We need to confirm your identity to allow access to your credit card
      account.
    </div>
  </aside>
);

export default Banner;
