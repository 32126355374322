export const PAGE_CONSTS = {
  SECURITY_CHECK_MAX_OTP_REQUESTS: 3,
  LS_REMEMBER_ME_STATUS: "true",
  LS_REMEMBER_ME_KEY: "rememberMe",
  LS_USERNAME_KEY: "username",
  LS_IS_LANDLINE_KEY: "isLandline"
};

export const TIMEOUT_ERROR_PAGE_DEFAULT_INFO = {
  title:
    "Sorry, we’re unable to complete your request because your request timed out.",
  description: "Thank you, your session with Sainsbury's Bank has ended."
};

export const PHONE_NUMBER_TOOLTIP_TEXT =
  "To allow access to your credit card account you may need to enter a one time passcode (OTP) so we need you to enter your number so we can check your details.";

export const LANDLINE_DETAILS = {
  title: "Don’t have a mobile or having signal problems?",
  body:
    "If you don’t own a mobile or are having problems with your mobile signal you can enter the last three digits of your home number instead. And if we need to send you a one time passcode, this will be sent to your home number.",
  buttonText: "Enter mobile number",
  buttonLinkText: "Enter your mobile number instead?",
  digitTitle: "Home number",
  digitDescription:
    "Please enter the last 3 digits of your home number as we may need to send you a one time passcode."
};

export const MOBILE_DETAILS = {
  title: "Don't have a mobile or having signal problems?",
  body:
    " If you don't own a mobile or are having problems with your mobile signal you can enter the last three digits of your home number instead. And if we need to send you a one time passcode, this will be sent to your home number.",
  buttonText: "Enter home number",
  buttonLinkText: "Don't have a mobile or having signal problems?",
  digitTitle: "Mobile number",
  digitDescription:
    "Please enter the last 3 digits of your mobile number as we may need to send you a one time passcode."
};

export const FORM_VALIDATION_MESSAGES = {
  OTP_FIELD_EMPTY: "Please enter your one time passcode",
  OTP_INCORRECT_LENGTH: "The one time passcode must be 6 numbers"
};

export const EXTERNAL_RESOURCE_URLS = {
  CONTACT_US:
    "https://www.sainsburysbank.co.uk/contact/contact-getintouch-phone",
  FAQS:
    "https://www.sainsburysbank.co.uk/credit_cards/support/support_creditcards_faqs",
  FAQS_OTP: "https://www.sainsburysbank.co.uk/security/online-transactions",
  FSCS: "https://www.sainsburysbank.co.uk/savings/sav_fscp",
  ONLINE_SECURITY:
    "https://www.sainsburysbank.co.uk/security/security_protect_yourself",
  PRIVACY_POLICY:
    "https://www.sainsburysbank.co.uk/legals/leg-reg-privacy-policy",
  PWS_LANDING_PAGE: "https://www.sainsburysbank.co.uk/",
  LOGIN_GUIDE:
    "https://www.sainsburysbank.co.uk/onlinesupport/online-banking-login-guide"
};

export const SCHEMA_USERNAME_ID = 0;
export const SCHEMA_PHONE_ID = 1;
export const SCHEMA_JSON_KEY_VALUES_ID = 2;
export const SCHEMA_IS_LANDLINE_ID = 3;
export const SCHEMA_BLACKBOX_ID = 4;
