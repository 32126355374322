import React from "react";
import { withRouter } from "react-router-dom";
import { FinalPage } from "../FinalPage";
import { TIMEOUTS, CANCELLATION_PAGE_DEFAULT_INFO } from "../constants";
import { CompleteConsentAndRedirect } from "../CompleteConsentAndRedirect";
import {
  decodeCancelLocationState,
  redirectAfterTimeout
} from "../../locationManagement";

const CancellationPage = withRouter(({ location }) => {
  const {
    title,
    description,
    tppRedirectUri,
    consentRedirect,
    redirectUrl,
    redirectToken
  } = decodeCancelLocationState(location);
  // Redirect if tppRedirectUri set but not showing consent form to redirect.
  let redirectingAfterTimeout = false;
  if (!consentRedirect && tppRedirectUri) {
    redirectAfterTimeout(tppRedirectUri, TIMEOUTS.CANCELLATION_TIMEOUT);
    redirectingAfterTimeout = true;
  }
  return (
    <FinalPage
      title={title || CANCELLATION_PAGE_DEFAULT_INFO.title}
      description={description || CANCELLATION_PAGE_DEFAULT_INFO.description}
      loading={redirectingAfterTimeout || !!consentRedirect}
    >
      {// Submit form if user successfully consenting account
      !!consentRedirect && (
        <CompleteConsentAndRedirect
          url={redirectUrl}
          token={redirectToken}
          delayInMilliseconds={TIMEOUTS.CANCELLATION_TIMEOUT}
        />
      )}
    </FinalPage>
  );
});

export default CancellationPage;
