import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";

import { SecurityCheck } from "./SecurityCheck";

import {
  redirectToIam,
  redirectToCancelPage,
  redirectToSBSuspendedPage,
  decodeSBAuthenticationState,
  handleStatusRedirection
} from "../../locationManagement";
import { decodeAuthIDFromJWT } from "../../services/Authentication/gotoJWT";
import {
  checkSecurity,
  checkUsernameAndDigits
} from "../../services/Authentication/AuthenticationAPI";
import { checkForOTPCallback } from "../../services/Authentication/APISchema";
// Context
import { TimeoutConsumer } from "../../context/TimeoutContext";

import { PAGE_CONSTS } from "../../shared/constants";
import { ACCOUNT_STATUS_ENUM } from "../../services/constants";
import { BLOCKED_PAGE_DEFAULT_INFO } from "../../openBanking/constants";

const AuthenticatePage = withRouter(({ location, history }) => {
  const {
    phoneDigits,
    iamRedirectUri,
    gotoParam,
    responseTemplate: responseTemplateFromLocation,
    requestedPinDigits: requestedPinDigitsFromLocation,
    username
  } = decodeSBAuthenticationState(location);

  const decodedTimestamp = decodeAuthIDFromJWT(responseTemplateFromLocation);

  const [requestedPinDigits, setPinDigits] = useState(
    requestedPinDigitsFromLocation
  );
  const [responseTemplate, setResponseTemplate] = useState(
    responseTemplateFromLocation
  );
  const [tokenExpiryTimestamp, setTokenExpiryTimestamp] = useState(
    decodedTimestamp
  );

  const onRequestOTP = useCallback(async () => {
    const isLandline = localStorage.getItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);
    let complete = false;
    const result = await checkUsernameAndDigits(
      username,
      phoneDigits,
      isLandline
    );

    if (
      result &&
      Object.values(ACCOUNT_STATUS_ENUM).indexOf(result.accountStatus) > -1
    ) {
      handleStatusRedirection(
        result.accountStatus,
        history,
        gotoParam,
        BLOCKED_PAGE_DEFAULT_INFO.title
      );
      complete = true;
    } else if (result.valid) {
      const {
        responseTemplate: newResponseTemplate,
        requestedPinDigits: newPinDigits
      } = result.data;

      setPinDigits(newPinDigits);
      setResponseTemplate(newResponseTemplate);

      const newTokenExpiryTimestamp = decodeAuthIDFromJWT(responseTemplate);
      setTokenExpiryTimestamp(newTokenExpiryTimestamp);
    }
    complete = true;
    return [complete, result.error || null];
  }, [username, phoneDigits, responseTemplate, gotoParam, history]);

  const onSubmit = async (pin, password, otp = null) => {
    const {
      valid = false,
      retry = false,
      error = undefined,
      jsonTemplate = undefined
    } = await checkSecurity(responseTemplate, pin, password, otp);

    let complete = false;
    if (valid) {
      localStorage.removeItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);

      redirectToIam(iamRedirectUri);
      complete = true;
    } else if (!retry) {
      localStorage.removeItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);

      redirectToSBSuspendedPage(history, gotoParam);
      complete = true;
    }

    /**
     * Update response template if retry permitted and jsonTemplate returned
     * jsonTemplate only returned for invalid credentials check, so this should
     * not impact 401 / unexpected error journeys
     */
    if (retry && jsonTemplate) {
      setResponseTemplate(jsonTemplate);
    }
    return [complete, error];
  };

  const includeOTPCheck = checkForOTPCallback(responseTemplate);

  return (
    <>
      <TimeoutConsumer {...{ history, gotoParam, tokenExpiryTimestamp }}>
        {() => (
          <SecurityCheck
            gotoParam={gotoParam}
            digitsRequired={requestedPinDigits}
            includeOTPCheck={includeOTPCheck}
            phoneDigits={phoneDigits}
            onSubmit={onSubmit}
            onCancel={() => redirectToCancelPage(history, iamRedirectUri)}
            requestOTP={onRequestOTP}
            history={history}
          />
        )}
      </TimeoutConsumer>
    </>
  );
});

export default AuthenticatePage;
