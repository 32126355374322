import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertIcon } from "@jsluna/alert";
import { ErrorCircle } from "@jsluna/icons";
import "@jsluna/alert/css/main.css";
import "./SBForm.scss";

const SBForm = ({ submitButton, children, error, className, ...rest }) => (
  <form {...rest} className={`${className} SBForm`} id="SB-auth-OB-form">
    {error && (
      <Alert
        variant="error"
        aria-atomic="true"
        aria-live="assertive"
        role="alert"
      >
        <AlertIcon>
          <ErrorCircle role="img" />
        </AlertIcon>
        {error}
      </Alert>
    )}
    <div className="SBForm__fields">{children}</div>
    <div className="SBForm__submit">{submitButton}</div>
  </form>
);

SBForm.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.node,
  submitButton: PropTypes.node.isRequired,
  className: PropTypes.string
};

SBForm.defaultProps = {
  error: undefined,
  className: null
};

export default SBForm;
