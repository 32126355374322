import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertIcon } from "@jsluna/alert";
import { InfoCircle } from "@jsluna/icons";
import "@jsluna/alert/css/main.css";
import "./TimeOutWarning.scss";

const TimeOutWarning = ({ seconds }) => (
  <Alert
    variant="warning"
    aria-atomic="true"
    aria-live="polite"
    role="alert"
    className="sb-timeout-warning"
  >
    <AlertIcon>
      <InfoCircle role="img" />
    </AlertIcon>
    Session will end in {seconds} seconds
  </Alert>
);

TimeOutWarning.propTypes = {
  seconds: PropTypes.number.isRequired
};

export default TimeOutWarning;
