import React from "react";
import PropTypes from "prop-types";

/**
 * Higher order component to enable page transitions to be announced to screen reader
 * For example, navigate to /accounts - 'Navigated to accounts page'
 * @param {Component} WrappedComponent      Component being wrapped
 * @param {String} pageTransitionMessage    Accessibility message for assistive technology to read
 */
function withPageTransitionWrapper(WrappedComponent, pageTransitionMessage) {
  function createHOC() {
    return (
      <AccessibilityMessage pageTransitionMessage={pageTransitionMessage}>
        <WrappedComponent />
      </AccessibilityMessage>
    );
  }
  return createHOC;
}

const AccessibilityMessage = ({ children, pageTransitionMessage }) => {
  return (
    <>
      <div
        aria-atomic
        role="alert"
        aria-live="assertive"
        className="ln-u-visually-hidden"
        aria-label={pageTransitionMessage}
      >
        {" "}
      </div>
      {children}
    </>
  );
};

AccessibilityMessage.propTypes = {
  children: PropTypes.element.isRequired,
  pageTransitionMessage: PropTypes.string.isRequired
};

export default withPageTransitionWrapper;
