import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";

import { SecurityCheck } from "./SecurityCheck";
import { TimeoutConsumer } from "../../context/TimeoutContext";
import {
  PAGE_CONSTS,
  TIMEOUT_ERROR_PAGE_DEFAULT_INFO
} from "../../shared/constants";
import { ACCOUNT_STATUS_ENUM } from "../../services/constants";

import {
  checkSecurity,
  checkUsernameAndDigits
} from "../../services/Authentication/AuthenticationAPI";
import { checkForOTPCallback } from "../../services/Authentication/APISchema";

import {
  redirectToIam,
  redirectToErrorPage,
  redirectToCancelPage,
  decodePinLocationState
} from "../../locationManagement";
import { decodeAuthIDFromJWT } from "../../services/Authentication/gotoJWT";

const AuthenticatePage = withRouter(({ location, history }) => {
  const {
    phoneDigits,
    tppRedirectUri,
    iamRedirectUri,
    responseTemplate: responseTemplateFromLocation,
    requestedPinDigits: requestedPinDigitsFromLocation,
    username
  } = decodePinLocationState(location);
  const decodedTimestamp = decodeAuthIDFromJWT(responseTemplateFromLocation);
  const [requestedPinDigits, setPinDigits] = useState(
    requestedPinDigitsFromLocation
  );
  const [responseTemplate, setResponseTemplate] = useState(
    responseTemplateFromLocation
  );
  const [tokenExpiryTimestamp, setTokenExpiryTimestamp] = useState(
    decodedTimestamp
  );

  const onRequestOTP = useCallback(async () => {
    const isLandline = localStorage.getItem(PAGE_CONSTS.LS_IS_LANDLINE_KEY);
    let complete = false;

    const result = await checkUsernameAndDigits(
      username,
      phoneDigits,
      isLandline
    );

    if (
      result &&
      Object.values(ACCOUNT_STATUS_ENUM).indexOf(result.accountStatus) > -1
    ) {
      redirectToErrorPage(
        history,
        tppRedirectUri,
        undefined,
        result.accountStatus
      );
      complete = true;
    } else if (result.valid && result.data) {
      const {
        responseTemplate: newResponseTemplate,
        requestedPinDigits: newPinDigits
      } = result.data;

      setPinDigits(newPinDigits);
      setResponseTemplate(newResponseTemplate);

      const newTokenExpiryTimestamp = decodeAuthIDFromJWT(responseTemplate);
      setTokenExpiryTimestamp(newTokenExpiryTimestamp);
    }
    complete = true;
    return [complete, result.error || null];
  }, [username, phoneDigits, responseTemplate, history, tppRedirectUri]);

  const onSubmit = async (pin, password, otp = null) => {
    const {
      accountStatus = undefined,
      valid = false,
      retry = false,
      error = undefined,
      jsonTemplate = undefined
    } = await checkSecurity(responseTemplate, pin, password, otp);
    let complete = false;
    if (valid) {
      redirectToIam(iamRedirectUri);
      complete = true;
    } else if (!retry) {
      redirectToErrorPage(history, tppRedirectUri, undefined, accountStatus);
      complete = true;
    }

    /**
     * Update response template if retry permitted and jsonTemplate returned
     * jsonTemplate only returned for invalid credentials check, so this should
     * not impact 401 / unexpected error journeys
     */
    if (retry && jsonTemplate) {
      setResponseTemplate(jsonTemplate);
    }
    return [complete, error];
  };

  const includeOTPCheck = checkForOTPCallback(responseTemplate);

  return (
    <>
      <TimeoutConsumer
        handleTimeout={() =>
          redirectToErrorPage(
            history,
            tppRedirectUri,
            undefined,
            undefined,
            TIMEOUT_ERROR_PAGE_DEFAULT_INFO
          )
        }
        tokenExpiryTimestamp={tokenExpiryTimestamp}
      >
        <SecurityCheck
          digitsRequired={requestedPinDigits}
          includeOTPCheck={includeOTPCheck}
          phoneDigits={phoneDigits}
          onSubmit={onSubmit}
          onCancel={() => redirectToCancelPage(history, tppRedirectUri)}
          requestOTP={onRequestOTP}
        />
      </TimeoutConsumer>
    </>
  );
});

export default AuthenticatePage;
