import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import "./StepIndicator.scss";

const NUMBER_OF_STEPS = 4;

const StepCircle = ({ active, label, title }) => {
  const circleClass = isActive => (isActive ? "circle active" : "circle");
  const ARIA_STEP_INDICATOR = `Step ${label} of ${NUMBER_OF_STEPS} - ${title}`;

  return (
    <div
      aria-current={active}
      className={circleClass(active)}
      id={active ? "StepIndicator__wrapper" : null}
    >
      <span
        aria-atomic
        role="status"
        aria-live={active ? "polite" : "off"}
        className="ln-u-visually-hidden"
      >
        {active ? `Currently on ${ARIA_STEP_INDICATOR}` : ARIA_STEP_INDICATOR}
      </span>
      <span className="label">{label}</span>
      <span className="title">{title}</span>
    </div>
  );
};

StepCircle.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export const StepIndicator = withRouter(props => {
  const isActive = path => props.location.pathname === path;

  return (
    <div
      role="status"
      aria-atomic
      aria-live="polite"
      aria-label={`Progress indicator - this is a ${NUMBER_OF_STEPS} step process`}
      aria-describedby="StepIndicator__wrapper"
      className="progress"
    >
      <StepCircle
        title="Your login details"
        label="1"
        active={isActive("/login")}
      />

      <span className="bar" />

      <StepCircle
        title="Security check"
        label="2"
        active={isActive("/authenticate")}
      />

      <span className="bar" />

      <StepCircle title="Summary" label="3" active={isActive("/accounts")} />

      <span className="bar" />

      <StepCircle
        title="Confirmation"
        label="4"
        active={isActive("/confirm")}
      />
    </div>
  );
});
