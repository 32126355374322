import React from "react";
import { withRouter } from "react-router-dom";

import { ValidateUserPage } from "./AuthenticationPage";
import { TimeoutConsumer } from "../../context/TimeoutContext";

import {
  redirectToPinPage,
  redirectToErrorPage,
  redirectToCancelPage,
  getValidateUrlParams
} from "../../locationManagement";
import { checkUsernameAndDigits } from "../../services/Authentication/AuthenticationAPI";
import { ACCOUNT_STATUS_ENUM } from "../../services/constants";
import { TIMEOUT_ERROR_PAGE_DEFAULT_INFO } from "../../shared/constants";
import { getBlackbox, removeBlackBox } from "../../shared/utils/callsign";

const ValidatePage = withRouter(({ history, location }) => {
  const { tpp, iam, tokenExpiryTimestamp } = getValidateUrlParams(location);

  const onSubmit = async (username, phoneDigits, isLandline) => {
    const result = await checkUsernameAndDigits(
      username,
      phoneDigits,
      isLandline,
      getBlackbox()
    );
    removeBlackBox();
    let complete = false;

    if (
      result &&
      Object.values(ACCOUNT_STATUS_ENUM).indexOf(result.accountStatus) > -1
    ) {
      redirectToErrorPage(history, tpp, undefined, result.accountStatus);
      complete = true;
    } else if (result && result.valid) {
      redirectToPinPage(
        history,
        tpp,
        iam,
        phoneDigits,
        result.data.requestedPinDigits,
        result.data.responseTemplate,
        username
      );
      complete = true;
    } else if (result && !result.retry) {
      redirectToErrorPage(history, tpp);
      complete = true;
    }
    return [complete, result.error];
  };

  return (
    <>
      <TimeoutConsumer
        tokenExpiryTimestamp={tokenExpiryTimestamp}
        handleTimeout={() =>
          redirectToErrorPage(
            history,
            tpp,
            undefined,
            undefined,
            TIMEOUT_ERROR_PAGE_DEFAULT_INFO
          )
        }
      >
        <ValidateUserPage
          onSubmit={onSubmit}
          onCancel={() => redirectToCancelPage(history, tpp)}
        />
      </TimeoutConsumer>
    </>
  );
});
export default ValidatePage;
