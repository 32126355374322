import React from "react";
import { Link } from "@jsluna/react";
import { NewWindow } from "@jsluna/icons";
import logo from "./fscs-logo.svg";
import { EXTERNAL_RESOURCE_URLS } from "../../../shared/constants";

import "./FscsBanner.scss";

const FscsBanner = () => (
  <aside
    aria-label="Find out more information about the Financial Services Compensation Scheme"
    className="FscsBanner"
  >
    <img src={logo} alt="FSCS logo" className="FscsBanner__image" />
    <div>
      <div className="FscsBanner__text">
        <span>
          All of our savings products are covered by the Financial Services
          Compensation Scheme.
        </span>
      </div>

      <Link
        label="Find out more about the Financial Services Compensation Scheme here (opens in new window)"
        color="alpha"
        target="_blank"
        href={EXTERNAL_RESOURCE_URLS.FSCS}
        className="FscsBanner__link"
      >
        Find out more
        <NewWindow />
      </Link>
    </div>
  </aside>
);

export default FscsBanner;
