import uuidv4 from "uuid/v4";

import {
  ACCOUNT_LIST_RESPONSE_SCHEMA,
  getAccountListFromResponse
} from "./AccountsAPISchema";
import { validateResponseStatus } from "../apiUtilities";
import { errors, OPENAPI_STANDARD_HEADERS } from "../constants";

const API_URL_PREFIX = process.env.REACT_APP_SB_API_HOST;

const {
  X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
  X_SB_CLIENT_ID_HEADER_VALUE
} = OPENAPI_STANDARD_HEADERS;

const FETCH_OPTIONS = () => ({
  method: "GET",
  cache: "no-cache",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "x-sb-channel-id": X_SB_CHANNEL_ID_HEADER_VALUE_PSD2,
    "x-sb-client-id": X_SB_CLIENT_ID_HEADER_VALUE,
    "x-fapi-interaction-id": uuidv4()
  }
});

export async function getAccounts() {
  try {
    const response = await fetch(
      `${API_URL_PREFIX}/account-list`,
      FETCH_OPTIONS()
    );

    validateResponseStatus(response);

    const accountList = await getAccountsFromResponse(response);

    return {
      success: true,
      accountList
    };
  } catch (e) {
    console.log("Could not get account due to error");
    console.log(e);

    return {
      success: false,
      error: errors.GET_ACCOUNTS_FAILED
    };
  }
}

async function getAccountsFromResponse(response) {
  const json = await response.json();

  await ACCOUNT_LIST_RESPONSE_SCHEMA.validate(json);

  return getAccountListFromResponse(json);
}
