/**
 * Adds ordinal suffix to the specified number.
 * e.g. "3" becomes "3rd"
 */
export function addOrdinal(number) {
  const last = number % 10;
  const teens = number % 100;
  if (last === 1 && teens !== 11) {
    return `${number}st`;
  }
  if (last === 2 && teens !== 12) {
    return `${number}nd`;
  }
  if (last === 3 && teens !== 13) {
    return `${number}rd`;
  }
  return `${number}th`;
}
