import React, { Component } from "react";
import { TextInput, CheckboxField, TextButton, FormGroup } from "@jsluna/react";
import PropTypes from "prop-types";

import OBForm from "../ob-design/OBForm";
import SubmitButton from "../SubmitButton";
import { CancelButton } from "../CancelButton";
import Tooltip from "../ob-design/Tooltip";
import TooltipMessage from "../ob-design/TooltipMessage";
import TooltipIcon from "../ob-design/TooltipIcon";
import InputLabel from "../ob-design/InputLabel";
import { addOrdinal } from "../../shared/utils/addOrdinal";
import Banner from "./Banner";
import OBFSCSBanner from "./FscsBanner";
import InlineError from "../ob-design/InlineError";

import MobileLandlineModal from "./modals/mobileLandlineModal";
import RegisterModal from "./modals/registerModal";

import {
  PAGE_CONSTS,
  PHONE_NUMBER_TOOLTIP_TEXT,
  LANDLINE_DETAILS,
  MOBILE_DETAILS
} from "../../shared/constants";

import "./AuthenticationPage.scss";
import "../securityCheck/SecurityCheck.scss";
import { startWebSDK } from "../../shared/utils/callsign";

const SURROUNDING_WHITESPACE_REGEXP = /^\s|\s$/;
const MOBILE_DIGITS_REQUIRED = ["9", "10", "11"];
const LANDLINE_REQUIRED = ["3rd from last", "2nd from last", "last"];

const {
  LS_REMEMBER_ME_STATUS,
  LS_REMEMBER_ME_KEY,
  LS_USERNAME_KEY,
  LS_IS_LANDLINE_KEY
} = PAGE_CONSTS;

const FIRST_NUMBER_INPUT = "digit-0";
const LAST_NUMBER_INPUT_ID = "digit-2";

function removeValuesFromLocalStorage() {
  [LS_REMEMBER_ME_KEY, LS_USERNAME_KEY].forEach(key =>
    localStorage.removeItem(key)
  );
}

function validateUsername(username) {
  // Check set
  if (!username) return false;

  // Check length
  if (username.length < 8) return false;

  // Check username does NOT contain whitespace at beginning or end
  return !SURROUNDING_WHITESPACE_REGEXP.test(username);
}

function validateDigits(digits) {
  return digits.every(val => val != null && val !== "");
}

const validateDigitsAndGetError = digits => {
  return !validateDigits(digits) ? "Please enter your number." : undefined;
};

const validateUsernameAndGetError = username => {
  if (!username) return "Please enter your username.";

  return !validateUsername(username)
    ? "Please enter a username in a valid format."
    : undefined;
};

const validCredentials = (username, mobile) => {
  return validateUsername(username) && validateDigits(mobile);
};

export class ValidateUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      usernameError: undefined,
      digits: [null, null, null],
      digitError: undefined,
      loginError: null,
      formLoading: false,
      rememberMe: false,
      openModal: false,
      openRegisterModal: false,
      showLandline: false,
      numberFilled: false,
      inputNumberClicked: false
    };
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    const rememberMe =
      localStorage.getItem(LS_REMEMBER_ME_KEY) === LS_REMEMBER_ME_STATUS;
    const username = rememberMe ? localStorage.getItem(LS_USERNAME_KEY) : "";
    this.setState({ username, rememberMe });

    window.addEventListener("click", this.validateNumbers);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.validateNumbers);
  }

  validateNumbers = ({ target }) => {
    const fieldName = target.name || target.id;
    const { inputNumberClicked } = this.state;

    if (!/digit-/.test(fieldName) && inputNumberClicked) {
      return this.setDigitErrorState();
    }

    return null;
  };

  setDigitErrorState = () => {
    const { digits } = this.state;
    return this.setState({
      digitError: validateDigitsAndGetError(digits)
    });
  };

  onBlur = ({ target }) => {
    const { digits, numberFilled } = this.state;
    const fieldName = target.name || target.id;

    if (fieldName === "username") {
      return this.setState({
        usernameError: validateUsernameAndGetError(target.value)
      });
    }

    if (digits.some(val => val != null && val !== "")) {
      this.setState({
        numberFilled: true
      });
    }

    if (
      (numberFilled && !/digit-/.test(fieldName)) ||
      fieldName === LAST_NUMBER_INPUT_ID
    ) {
      return this.setDigitErrorState();
    }

    return null;
  };

  onUsernameChanged = ({ target }) => {
    this.setState(state => ({
      username: target.value,
      usernameError: state.usernameError
        ? validateUsernameAndGetError(target.value)
        : undefined
    }));
  };

  keyPressed = e => {
    if (e.key === "Enter") {
      this.submitForm(e);
    }
  };

  submitForm = async event => {
    event.preventDefault();
    const { username, digits, rememberMe, showLandline } = this.state;

    const usernameError = validateUsernameAndGetError(username);
    if (usernameError) {
      this.setState({ usernameError }, this.scrollToError);
      return;
    }

    try {
      this.setState({ formLoading: true, loginError: null });
      const [complete, error] = await this.props.onSubmit(
        username,
        digits,
        showLandline
      );

      if (!complete) {
        this.setState({
          formLoading: false,
          loginError: error
        });
      }
    } catch (e) {
      console.log("Could not submit form");
      console.error(e);

      this.setState({
        loginError: "An error occurred when submitting the form",
        formLoading: false
      });
    }
    this.updateLocalStorage(rememberMe, username, showLandline);
  };

  updateLocalStorage = (rememberMe, username, showLandline) => {
    if (rememberMe) {
      localStorage.setItem(LS_REMEMBER_ME_KEY, LS_REMEMBER_ME_STATUS);
      localStorage.setItem(LS_USERNAME_KEY, rememberMe ? username : "");
    } else {
      removeValuesFromLocalStorage();
    }

    localStorage.setItem(LS_IS_LANDLINE_KEY, showLandline);
  };

  scrollToError = () => {
    const firstError = document.querySelector(".ln-c-form-group.has-error");
    if (firstError) {
      window.scrollTo(0, firstError.offsetTop);
      firstError.querySelector("input").focus();
    }
  };

  mobileDigitChanged = async (value, index) => {
    let newValue = value;
    if (newValue) {
      newValue = newValue.charAt(0);
      if (isNaN(parseInt(newValue, 10))) {
        return;
      }
    }
    await this.setState(state => {
      const newDigits = [...state.digits];
      newDigits[index] = newValue;
      return { digits: newDigits };
    });

    const digitsRequired = this.state.showLandline
      ? LANDLINE_REQUIRED
      : MOBILE_DIGITS_REQUIRED;
    // Jump to next input
    if (
      index < digitsRequired.length - 1 &&
      document.getElementById(`digit-${index}`) &&
      newValue
    ) {
      document.getElementById(`digit-${index}`).blur();
      document.getElementById(`digit-${index + 1}`).focus();
    }
  };

  onChange = e => {
    this.setState({ rememberMe: !!e.target.checked });
  };

  onClickMobileOrLandline = () => {
    this.setState({ openModal: true });
  };

  onClickLandlineMobile = () => {
    this.setState(prevState => {
      return {
        openModal: false,
        showLandline: !prevState.showLandline,
        digits: [null, null, null],
        numberFilled: false,
        digitError: undefined,
        inputNumberClicked: false
      };
    });
  };

  closeRegisterModal = () => {
    this.setState({ openRegisterModal: false });
  };

  registerNumberModal = () => {
    return (
      <RegisterModal
        isOpen={this.state.openRegisterModal}
        handleClose={() => this.closeRegisterModal()}
      />
    );
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  onClickNumber = () => {
    if (this.state.inputNumberClicked) return;

    this.setState({ inputNumberClicked: true });
  };

  keyDownValidation = e => {
    const fieldName = e.target.name || e.target.id;
    if (fieldName === FIRST_NUMBER_INPUT) {
      return this.setDigitErrorState();
    }

    return null;
  };

  mobileOrLandlineModal = ({ title, body, buttonText }) => {
    return (
      <MobileLandlineModal
        title={title}
        body={body}
        buttonText={buttonText}
        isOpen={this.state.openModal}
        handleClose={() => this.closeModal()}
        clickButton={() => this.onClickLandlineMobile()}
      />
    );
  };

  generateDigitInputs = () => {
    const { showLandline, digits, digitError } = this.state;
    const digitsRequired = showLandline
      ? LANDLINE_REQUIRED
      : MOBILE_DIGITS_REQUIRED;

    return digitsRequired.map((digit, index) => {
      const id = `digit-${index}`;
      const ordinal = showLandline ? digit : addOrdinal(digit);
      const accessibilityMessage = `Please enter the ${ordinal} digit of your ${
        showLandline ? "home" : "mobile"
      } number - ${index + 1} of ${digitsRequired.length}`;
      return (
        <div className="digit" key={digit}>
          <InputLabel className="ln-u-visually-hidden" htmlFor={id}>
            <span className="ln-u-visually-hidden">
              {ordinal} digit of your {showLandline ? "home" : "mobile"} number
            </span>
          </InputLabel>
          <TextInput
            className={`digit-input cs-phone-${id}`}
            onChange={({ target }) =>
              this.mobileDigitChanged(target.value, index)
            }
            onBlur={this.onBlur}
            onClick={this.onClickNumber}
            onKeyDown={e => {
              if (e.keyCode === 9 && e.shiftKey) {
                this.keyDownValidation(e);
              }
            }}
            id={id}
            name={id}
            data-testid={id}
            aria-label={accessibilityMessage}
            aria-invalid={!!digitError}
            aria-errormessage="digitError"
            value={digits[index] || ""}
            required
            type="tel"
          />
        </div>
      );
    });
  };

  render() {
    const {
      usernameError,
      digitError,
      loginError,
      digits,
      username,
      formLoading,
      rememberMe,
      showLandline
    } = this.state;

    const landlineObj = showLandline ? LANDLINE_DETAILS : MOBILE_DETAILS;

    const submitFormEnabled =
      validCredentials(username, digits) && !formLoading;

    const cancelButton = <CancelButton onClick={this.props.onCancel} />;
    const submitButton = (
      <SubmitButton
        submitRef={el => {
          this.submitRef = el;
        }}
        disabled={!submitFormEnabled}
        progressMessage="Logging in..."
        loading={formLoading}
      />
    );

    if (!formLoading && loginError) {
      // reinitialise Callsign SDK if login error
      startWebSDK();
    }

    return (
      <div className="OBAuthenticationPage">
        <div className="OBAuthenticationPage__wrapper">
          <Banner />
          <div className="inline-select-form ln-c-form-group login-title-text">
            <h2 className="">Your login details</h2>
          </div>
          {this.registerNumberModal()}
          {this.mobileOrLandlineModal(landlineObj)}
          <OBForm
            className="inline-select-form form-username-mobile"
            cancelButton={cancelButton}
            submitButton={submitButton}
            error={loginError}
            onSubmit={this.submitForm}
          >
            <FormGroup
              name="username"
              className="sb-username"
              error={usernameError}
            >
              <TextInput
                name="username"
                id="username"
                label="Username"
                onBlur={this.onBlur}
                onChange={this.onUsernameChanged}
                onKeyPress={this.keyPressed}
                error={usernameError}
                maxLength={20}
                required
                value={username || ""}
                autoCapitalize="none"
                className="OBAuthenticationPage__wrapper__input cs-username"
              />
            </FormGroup>
            <div className="label-spacing">
              <strong className="ln-c-label">Forgotten your details?</strong>
              <Tooltip
                tooltipId="forgottenTooltip"
                label="Find out what to do when you have forgotten your details, help icon collapsed"
              >
                <TooltipIcon
                  tooltipId="forgottenTooltip"
                  type="question"
                  className="tooltip-margin-top tooltip-margin-left"
                />
                <TooltipMessage>
                  If you have forgotten any of your details then please go to
                  your online banking and follow the instructions.
                </TooltipMessage>
              </Tooltip>
            </div>
            <div className="checkBoxWrapper">
              <CheckboxField
                id="remember-me"
                onChange={e => this.onChange(e)}
                name="remember-me-check"
                options={[
                  {
                    value: LS_REMEMBER_ME_KEY,
                    label: "Remember Me",
                    checked: rememberMe
                  }
                ]}
              />
              <Tooltip
                tooltipId="rememberMeTooltip"
                className="RememberMe--tooltip"
                label="See remember me security tip, help icon collapsed"
              >
                <TooltipIcon type="question" className="tooltip-margin-top" />
                <TooltipMessage>
                  We don’t recommend using this for a shared computer.
                </TooltipMessage>
              </Tooltip>
            </div>
            <div id="digitSection">
              <strong className="ln-c-label" id="digitSectionTitle">
                {landlineObj.digitTitle}
              </strong>
              <Tooltip
                tooltipId="phoneTooltip"
                label="Find out why we need your phone number, help icon collapsed"
              >
                <TooltipIcon
                  type="question"
                  className="tooltip-margin-top tooltip-margin-left"
                />
                <TooltipMessage>{PHONE_NUMBER_TOOLTIP_TEXT}</TooltipMessage>
              </Tooltip>
              <fieldset>
                <legend
                  id="digitDescription"
                  aria-label={landlineObj.digitDescription}
                >
                  {landlineObj.digitDescription}
                </legend>
                <div className="inline-select-form-digit-wrapper">
                  <div className="inline-select-form-digit-wrapper--digits">
                    {this.generateDigitInputs()}
                  </div>
                </div>
                <InlineError
                  message={digitError}
                  dataTestId="digitError"
                  hidden={!digitError}
                  id="digitError"
                />
              </fieldset>
              <div className="OBAuthenticationPage__modal-helpers">
                <TextButton
                  id="textButtonLink"
                  className="ln-c-label links"
                  onClick={() => this.onClickMobileOrLandline()}
                  color="dark"
                  tabIndex={0}
                  aria-label={landlineObj.buttonLinkText}
                >
                  {landlineObj.buttonLinkText}
                </TextButton>
                <TextButton
                  id="registerNumber"
                  className="ln-c-label links"
                  onClick={() => this.setState({ openRegisterModal: true })}
                  color="dark"
                  tabIndex={0}
                  aria-label="Need to register your number?"
                >
                  Need to register your number?
                </TextButton>
              </div>
            </div>
          </OBForm>
          <OBFSCSBanner />
        </div>
      </div>
    );
  }
}

ValidateUserPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
