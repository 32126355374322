import Url from "url-parse";
import { string, object } from "yup";

export const CLIENT_JWT_SCHEMA = object().shape({
  aud: string().required(),
  iss: string().required(),
  scopes: object().required(),
  claims: object()
    .required()
    .shape({
      id_token: object()
        .required()
        .shape({
          openbanking_intent_id: object()
            .required()
            .shape({
              value: string().required()
            })
        })
    }),
  consentApprovalRedirectUri: string().required()
});

export function getConsentId(token) {
  return token.claims.id_token.openbanking_intent_id.value;
}

export function getTppName(token) {
  return token.client_name;
}

export function getConsentApprovalRedirectUri(token) {
  return setConsentApprovalRedirectUriDomainName(
    token.consentApprovalRedirectUri
  );
}

// RegExp to check for ob.domain in the URI generated by IAM
const IAM_GENERATED_DOMAIN_REGEXP = /https:\/\/ob\./;

// RegExp test
export const domainCheck = uri => IAM_GENERATED_DOMAIN_REGEXP.test(uri);

/**
 * Replaces the hostname of the uri in the consent token.
 * consentApprovalRedirectURI is generated by AM, according to the OAuth spec.
 * Because it's part of a signed token it can't be edited
 * @param {String} consentApprovalRedirectUri
 */
export function setConsentApprovalRedirectUriDomainName(
  consentApprovalRedirectUri
) {
  // Return the consentApprovalRedirectUri as is
  // to enable continued local developmnent against iam-mock
  if (process.env.REACT_APP_DISABLE_CONSENT_REDIRECT_URL_CHANGE) {
    return consentApprovalRedirectUri;
  }

  // Validate that IAM defined uri begins ob.
  if (!domainCheck(consentApprovalRedirectUri)) {
    console.warn("Consent uri does not match valid format");
    return consentApprovalRedirectUri;
  }

  // Parse uri into Url
  const consentUrl = new Url(consentApprovalRedirectUri);
  const { hostname } = consentUrl;

  // Replace ob. with iam.
  const newUri = hostname.replace(/ob\./, "iam.");
  consentUrl.set("hostname", newUri);
  return consentUrl.toString();
}
