// TODO: Placeholder models awaiting designs
import React from "react";
import {
  ButtonGroupWrapper,
  OutlinedButton,
  FilledButton,
  List,
  ListItem
} from "@jsluna/react";
import { Dislike, Tick } from "@jsluna/icons";
import "./MaxAttemptsOTPModal.scss";
import { EXTERNAL_RESOURCE_URLS } from "../../shared/constants";

const SBMaxOTPAttemptsPage = () => {
  return (
    <div>
      <h3 className="ln-u-color-orange">
        We can&#39;t send any more codes just now
        <div className="icon-wrapper">
          <Dislike className="dislike-icon" />
        </div>
      </h3>
      <p>Sorry, we can’t send any more codes just now</p>
      <p>Please try again later, remembering to:</p>
      <List className="ln-u-margin-bottom" type="bare">
        <div className="check-list">
          <Tick className="ln-u-color-orange ln-u-font-size-display-3" />
          <ListItem className="bus-list__item">
            Wait a minute for the code to arrive before trying again.
          </ListItem>
        </div>
        <div className="check-list">
          <Tick className="ln-u-color-orange ln-u-font-size-display-3" />
          <ListItem className="bus-list__item">
            Make sure you are using the most recent code.
          </ListItem>
        </div>
      </List>
      <p>If you are still not able progress, just give us a call.</p>
      <ButtonGroupWrapper className="sb-modal-buttons-max-otp-attempts">
        <FilledButton
          className="sb-modal-buttons__exit"
          id="sb-modal-buttons__exit"
          onClick={() =>
            window.location.replace(EXTERNAL_RESOURCE_URLS.PWS_LANDING_PAGE)
          }
        >
          Exit
        </FilledButton>
        <OutlinedButton
          className="sb-modal-buttons__contact_us"
          id="sb-modal-buttons__contact_us"
          onClick={() =>
            window.location.replace(EXTERNAL_RESOURCE_URLS.CONTACT_US)
          }
        >
          Contact us
        </OutlinedButton>
      </ButtonGroupWrapper>
    </div>
  );
};

export default SBMaxOTPAttemptsPage;
