import React from "react";
import PropTypes from "prop-types";
import { TextInput, FormGroup } from "@jsluna/react";
import RememberMe from "../remember-me/RememberMe";
import LinkList, { linkListType } from "../../sb-design/link-list/LinkList";

import "./Username.scss";

const Username = ({
  links,
  error,
  value,
  onChange,
  onBlur,
  onRememberMeChange,
  rememberMe
}) => {
  return (
    <FormGroup name="username" className="sb-username" error={error}>
      <TextInput
        autoFocus
        name="username"
        id="username"
        value={value}
        maxLength={20}
        required
        label="Username"
        placeholder="Enter username"
        onChange={onChange}
        onBlur={onBlur}
        className="cs-username"
      />
      <RememberMe onChange={onRememberMeChange} checked={rememberMe} />
      <LinkList links={links} />
    </FormGroup>
  );
};

Username.propTypes = {
  links: linkListType.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onRememberMeChange: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired
};

Username.defaultProps = {
  error: null,
  onBlur: null
};

export default Username;
