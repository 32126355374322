/* eslint-disable no-bitwise */
// eslint-disable-next-line func-names
export function startWebSDK() {
  const tenantId = process.env.REACT_APP_CALLSIGN_TENANT_ID;
  function createUUID() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const config = {
    essx: "https://app.s01.callsign.com",
    esto: tenantId,
    ggow: createUUID(),
    mosc: createUUID(),
    mwel: "PrimaryLogin",
    mwelsub: "Username",
    mwelseq: 1,
    sanf: JSON.stringify({ "#SB-auth-OB-form": "submit" }),
    time: Date.now(),
    loco: true,
    upfr: true,
    reed: false,
    ewps: true
  };
  sessionStorage.setItem("cx", JSON.stringify(config));
}

export function getBlackbox() {
  try {
    const cx = sessionStorage.getItem("cx");
    const blackboxData = JSON.parse(cx);
    if (!blackboxData?.ggow || !blackboxData?.mosc) return false;
    return {
      transactionId: blackboxData?.ggow,
      sessionId: blackboxData?.mosc
    };
  } catch {
    return false;
  }
}

export function removeBlackBox() {
  sessionStorage.removeItem("cx");
}
