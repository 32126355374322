import React, { useState } from "react";
import PropTypes from "prop-types";

import InlineError from "./InlineError";
import { isInputModeValid } from "../../shared/utils/isInputModeValid";
import "./TextInput.scss";

const TextInput = ({
  error,
  type,
  toggleVisibility,
  className,
  dataTestId,
  hideErrorMessage,
  id,
  ...rest
}) => {
  const [shown, setShown] = useState(false);
  let updatedRestProps = rest;

  let inputType =
    type === "password" && shown && toggleVisibility ? "text" : type;

  if (type === "pin") {
    if (isInputModeValid()) {
      updatedRestProps = {
        ...rest,
        inputMode: "decimal",
        pattern: `${type !== "password" ? "[0-9]*" : ""}`,
        style: { WebkitTextSecurity: "disc" }
      };

      inputType = "text";
    } else {
      inputType = "password";
    }
  }

  const errorId = `OBInlineText__error-${id}`;

  return (
    <>
      <div className="OBTextInput">
        <input
          aria-errormessage={errorId}
          aria-invalid={!!error}
          type={inputType}
          id={id}
          {...updatedRestProps}
          className={`OBTextInput__input ${
            error ? "OBTextInput__input--has-error" : ""
          } ${
            toggleVisibility ? "OBTextInput__input--show-button" : ""
          } ${className}`}
          autoCapitalize="none"
          data-testid={dataTestId}
        />
        {type === "password" && toggleVisibility && (
          <button
            onClick={() => setShown(state => !state)}
            type="button"
            className="OBTextInput__show-password"
          >
            {shown ? "Hide" : "Show"}
          </button>
        )}
      </div>
      <InlineError
        message={error}
        dataTestId={`inlineError-${dataTestId}`}
        hidden={hideErrorMessage || !error}
        id={errorId}
      />
    </>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  toggleVisibility: PropTypes.bool,
  dataTestId: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
  id: PropTypes.string.isRequired
};

TextInput.defaultProps = {
  error: undefined,
  type: "text",
  toggleVisibility: false,
  className: undefined,
  dataTestId: undefined,
  hideErrorMessage: false
};

export default TextInput;
