import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Tooltip, TooltipIcon, TooltipMessage } from "@jsluna/labs";
import "@jsluna/labs/dist/tooltip/scss/main.scss";
import "./Tooltip.scss";

const ToolTip = ({ message, children, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      open={isOpen}
      tooltipId={id || `tooltip-${uuidv4()}`}
      position="right"
    >
      {children}
      <TooltipIcon
        onClick={event => {
          event.preventDefault();
          setIsOpen(!isOpen);
        }}
        onBlur={event => {
          event.preventDefault();
          setIsOpen(false);
        }}
      />
      <TooltipMessage>{message}</TooltipMessage>
    </Tooltip>
  );
};

ToolTip.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  id: PropTypes.string
};

ToolTip.defaultProps = {
  children: null,
  id: null
};

export default ToolTip;
