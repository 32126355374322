import React from "react";
import PropTypes from "prop-types";

const PageHeading = props => <h2>{props.children}</h2>;

PageHeading.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageHeading;
