import { addMethod, mixed, string, object, array } from "yup";

/**
 * Creates a custom validation method defined()
 * Which ensures that the property is defined, but allows for empty arrays.
 * (required() will fail empty arrays)
 */
// eslint-disable-next-line func-names
addMethod(mixed, "defined", function() {
  return this.test(
    "defined",
    // eslint-disable-next-line no-template-curly-in-string
    "${path} must be defined",
    value => value !== undefined
  );
});

/*
Based on agreed return shape for June 2019, as documented here:
https://sb97digital.atlassian.net/wiki/spaces/IAM/pages/440860805/Account+List+Service
*/
export const ACCOUNT_LIST_RESPONSE_SCHEMA = object().shape({
  accountList: array()
    .of(
      object().shape({
        accountDisplay: string()
          .matches(/\d{4}/)
          .required(),
        ProductAccountId: string().required()
      })
    )
    .defined()
});

export function getAccountListFromResponse(accountList) {
  return accountList.accountList;
}
