import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import { CLIENT_JWT_SCHEMA } from "./TokenSchema";

// Key generated via `openssl genrsa 2048 | pem-jwk`
// Supplement with alg and (random) kid
const PRIVATE_KEY = {
  kty: "RSA",
  n:
    "u9oD_sPwu_Dc-gnTAczy2KOrx1TmfbLL4HtWZYUJ_pbNvEKlJG20IU1Q9LjIiZ1OnYQ84dkPWdLLa96_50uXZd6OAUreWk531oG-dWjcr2_vRGn80FjjGVA2PAeeWhcxOqXiffVXckSZkW95ZMQaskQmou04rnB58Z2NGOC5eat0pL-TaWcmxDIfOcjlSjnFVsScSNykq5DfCkFyDtuJdcmximTfxrVRfpey4bjCudIXmJu6Z1BdWCk7mksH3-Ji0oMJJ6i6CcY-BSyrq74R_OHY0TwasCWbIk-zvPioTQ6YaUztbvoVXKmx955NJNUNZSy6qd-RA7pNWFzYr6OepQ",
  e: "AQAB",
  d:
    "useUj10t4QEmBut7xd-NW0CqDWFqaHM96i9YgUqd3Gj_mX--ihEWOMKSizGsG8CwqUgR7RIRBGPm5ZOuxgDQQXbfRYO2wJ4i48NPp5gjTcEDa8fimxq2e7XYpymAFt3WkhTcthca4sA43XW0gy_Fx5ihvgAXCsNFTKs13xWa9dCDRj19SK2gwKfyEHhlTDbRJf0BPxhiVuDPOzlbwVUbKX_4-Of9W7rAwbCbiLdxzIpuiapP6OhO_H6-8JuTLgwGYIrIl7Re69Yo7khBs2ldp4mnKV0mFhm5vOTrq3ZPuobFnZn5xz63m_4ELwzJqnssfR6n1SDKFnwv8SxScinKYQ",
  p:
    "549C-VgCa1QVtJ7t4Z_j_-rDHF1fyyLNxl_meGiDPKqmcQzwX_F0-CRghpTAZFhbrry16yePowL8vlGDaWKXGWpLLogXZ2DCm9sqx7T9cDVLZsq5JbQjbKO9Q52IjJU2tTuhJ1PWEr69ziY18_57nO51-8PPwwMSPUo6BuLTCi0",
  q:
    "z63Dticlg-XnWI8lfmqTwmDD-AhlVtgUhC_8FmqRV2kAINd2rr_kHXqI5idwtTP66NZEwgRA0RF_JkmrZpY4Znb9Nwy_cDniolDJNGNDq6QNXJdDjifvXz_9mLEqdQsnxm9q-lD9-lkqFUJg-rJ1vrx3O2ewDUWRMU447mX_iVk",
  dp:
    "s7LGy8_T2MzhJ1Fq0_yMKK9cCOghjfgNbtQ_e9PHfM4F6pea9ynfly5LxkUr5PN4-uqx1rLV0jBgSaXicGc1UWDexVaRqwU5IVB9K4w2XdzODhBmjG72SrnrB8z6CfdGBHEpB-cUq2lgzNl0Z0xnS4r2_pGf6vu4LN4sl-lw02U",
  dq:
    "UGT58FezJB4pspSndQqY7JQ9CJVndXjsaEeTFLJoVSgmjpAaN4e9LNQ0jrQR43Od2-imcVzoIobda9r06R-Z8cB6QYdDzJP78c3INel_VsCJE6DS3AMFnR_Ocf-Taf_Jg_25sfEMgI9nxRcPJ71R62ZV2b9H3btie23GiDMf-vE",
  qi:
    "H0uYlS12BacQlJuVsk6UNza7fQ3W-KTGKXEVTG3z2cnHpNF5PjGzPrk5EirOVZL5PcQA0P7sJ1i7lSjgBnQUl2huA9Didlz-CvUKR-TfCuZxDutn0Ae--q1EGjatmhFvqGum-2ske1UVWmPIlJ_KyLRrvceyPvwuJAZJLSJRWd0",
  alg: "RS256",
  kid: "0f8f1382-b8c3-4962-a082-0c120e6e569c"
};

export function parseConsentJwtString(jwtString) {
  const token = jwt.decode(jwtString);

  CLIENT_JWT_SCHEMA.validateSync(token);

  return token;
}

export function convertClientTokenToConsentResponseToken(
  token,
  consentConfirmed
) {
  return {
    ...token,
    scopes: Object.keys(token.scopes),
    iss: token.aud,
    aud: token.iss,
    decision: consentConfirmed
  };
}

export function signToken(claims) {
  // Convert private key from JWK to PEM format
  const privatePemKey = jwkToPem(PRIVATE_KEY, {
    private: true
  });

  const publicPemKey = jwkToPem(PRIVATE_KEY, {
    private: false
  });

  // Sign key, and convert callback to promise
  return new Promise((resolve, reject) => {
    jwt.sign(
      claims,
      privatePemKey,
      {
        algorithm: PRIVATE_KEY.alg,
        keyid: PRIVATE_KEY.kid
      },
      (err, token) => {
        if (err) {
          console.log("Failed to sign token");
          console.log(err);

          reject(err);
        } else {
          try {
            jwt.verify(token, publicPemKey);
          } catch (error) {
            console.log("Signed token failed validation");
            console.log(error);
          }

          resolve(token);
        }
      }
    );
  });
}
