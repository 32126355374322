import React from "react";
import PropTypes from "prop-types";
import { ErrorCircle, NewWindow } from "@jsluna/icons";
import {
  Modal,
  ModalHeading,
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper
} from "@jsluna/react";
import "./sbRegisterModal.scss";
import { EXTERNAL_RESOURCE_URLS } from "../../../shared/constants";

const SbRegisterModal = ({ isOpen, handleClose }) => {
  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <ModalHeading element="h3">
        <ErrorCircle className="error-circle" />
        Need to register your mobile number with us?
      </ModalHeading>
      <p className="register-body-0">
        The easiest way to give us your mobile number, or update it, is by
        calling us.
      </p>
      <ButtonGroupWrapper className="sb-modal-buttons">
        <FilledButton
          element="a"
          href={`${EXTERNAL_RESOURCE_URLS.CONTACT_US}?action=checkSavings-somethingElseSavings`}
          target="_blank"
          className="sb-modal-buttons__exit"
        >
          Contact us
        </FilledButton>
        <p className="register-body-1">Any other questions ?</p>
        <OutlinedButton
          element="a"
          href={EXTERNAL_RESOURCE_URLS.FAQS_OTP}
          target="_blank"
          className="sb-modal-buttons__contact_us"
        >
          FAQs
          <NewWindow />
        </OutlinedButton>
      </ButtonGroupWrapper>
    </Modal>
  );
};

SbRegisterModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool
};

SbRegisterModal.defaultProps = {
  handleClose: null,
  isOpen: false
};

export default SbRegisterModal;
