import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Manager, Reference, Popper } from "react-popper";

import TooltipIcon from "./TooltipIcon";
import TooltipMessage from "./TooltipMessage";
import { isMobile } from "../../services/mobileDetection";
import "./OBTooltip.scss";

const childMatchesType = (child, type, checkDisplayName = false) => {
  let matches = false;

  if (!child || !child.type) {
    return matches;
  }

  matches = Array.isArray(type)
    ? type.includes(child.type)
    : child.type === type;

  if (checkDisplayName && !matches) {
    const name = child.type.displayName || child.type.name;
    matches = type.displayName === name;
  }

  return matches;
};

const Tooltip = ({
  children,
  className,
  tooltipId,
  priority,
  boundariesElement,
  escapeWithReference,
  popper,
  label,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);

  const tooltipRef = useRef(null);

  const toolTipPosition = !isMobile() ? "right" : "bottom";

  const triggerEvents = !isMobile()
    ? {
        onMouseOver: () => setOpen(true),
        onMouseOut: () => setOpen(false),
        onClick: e => {
          setOpen(!isOpen);
          e.preventDefault();
        },
        onBlur: e => {
          setOpen(false);
        }
      }
    : null;

  const modifiers = {
    offset: { offset: "0, 10px" },
    preventOverflow: {
      enabled: true,
      escapeWithReference,
      boundariesElement,
      priority
    },
    ...popper.modifiers
  };

  let message;
  let element;

  React.Children.forEach(children, (child, index) => {
    if (childMatchesType(child, TooltipMessage)) {
      message = child;
    } else if (childMatchesType(child, TooltipIcon)) {
      element = React.cloneElement(child, {
        ...triggerEvents,
        tooltipId,
        label,
        key: (child && child.props && child.props.id) || index
      });
    } else {
      element = child;
    }
  });

  const focusingOut = event => {
    if (!isOpen) {
      setOpen(tooltipRef.current.contains(event.target));
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile()) {
      document.addEventListener("touchmove", focusingOut, true);
      document.addEventListener("touchstart", focusingOut, true);
      return () => {
        document.removeEventListener("touchmove", focusingOut, true);
        document.removeEventListener("touchstart", focusingOut, true);
      };
    }
    return undefined;
  });

  return (
    <div
      {...rest}
      className={classnames(className, "OBln-c-tooltip", isOpen && "is-open")}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={tooltipRef}>
              <div ref={ref}>{element}</div>
            </div>
          )}
        </Reference>
        <div
          aria-live="polite"
          aria-atomic="true"
          id={tooltipId}
          role="tooltip"
        >
          {isOpen && (
            <Popper
              {...popper}
              placement={toolTipPosition}
              modifiers={modifiers}
            >
              {({ ref, style, placement, arrowProps }) =>
                React.cloneElement(message, {
                  innerRef: ref,
                  style,
                  arrow: (
                    <span
                      ref={arrowProps.ref}
                      style={arrowProps.style}
                      className={classnames(
                        "OBln-c-tooltip__arrow",
                        placement && `OBln-c-tooltip__arrow--${placement}`
                      )}
                    />
                  )
                })
              }
            </Popper>
          )}
        </div>
      </Manager>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Show/Hide the Tooltip Message part of the component */
  open: PropTypes.bool,
  /** A unique identifier to enable screenreaders to pair the aria-describedby attribute and the tooltip message id */
  tooltipId: PropTypes.string,
  /** Provide a boundry for the position against, can be `scrollParent`, `window`, `viewport` or any DOM element */
  boundariesElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** If set to true this allows the tooltip message to overflow over any set boundries */
  escapeWithReference: PropTypes.bool,
  /** Popper JS overrides */
  popper: PropTypes.shape({ modifiers: PropTypes.string }),
  /** Set the edge detection priority with popper */
  priority: PropTypes.arrayOf(PropTypes.string),
  /** Label to decribe the trigger element, primarly used by screenreaders. */
  label: PropTypes.string
};

Tooltip.defaultProps = {
  children: undefined,
  className: undefined,
  open: false,
  tooltipId: undefined,
  boundariesElement: "scrollParent",
  escapeWithReference: false,
  priority: ["top", "bottom", "left", "right"],
  popper: {},
  label: undefined
};

export default Tooltip;
